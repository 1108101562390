import React from 'react'
import Helmet from 'react-helmet'

// Compilers hreflang links and uses Helmet to inject them into the page
const HrefLang = ({ hasTranslation, translations }) => {
  if (!hasTranslation) {
    return null
  }

  const location = window.location
  const { protocol, host } = location
  let links = []

  Object.keys(translations).forEach((lang) => {
    links.push(
      <link
        key={`hrefLang_${lang}`}
        rel="alternate"
        hrefLang={lang}
        href={`${protocol}//${host}${translations[lang]}`}
      />
    )
  })

  return <Helmet>{links}</Helmet>
}

export default HrefLang
