import React from 'react'
import Tracker from 'utils/tracking'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import InlineSVG from 'components/_ui/InlineSVG'
import LinkRouter from 'components/_utility/LinkRouter'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'

import 'components/_ui/Button/Drakkar/drakkar-button.css'

const ASSETS_PATH = process.env.ASSETS_PATH

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const DrakkarButton = (props) => {
  let button
  let target
  let to = props.href

  // Check if there's a tracking callback attached inside options
  let trackingCallback
  if (props.options && props.options.gtm_id) {
    trackingCallback = Tracker(props.options.gtm_id)
  }

  const cls = classnames({
    btn: true,
    'btn--drakkar': true,
    'btn--hover': props.showHoverState,
    'btn--transparent': props.transparent,
    'btn--arrow-left': props.arrowLeft,
    'btn--full': props.full,
    [`${props.className}`]: props.className
  })

  if (
    props.target === '_blank' ||
    (props.options && (props.options['isExternal'] || props.options['type'] === 'file'))
  ) {
    target = 'external'
    if (props.options && props.options['type'] === 'file') {
      to = ASSETS_PATH + props.href
    } else {
      to = props.href
    }
  }

  return (
    <ThemeConsumer>
      {(theme) => {
        // Allow props to override primary/secondary color
        // otherwise - use page section theme colors
        const primaryColor = props.primaryColor || (theme && theme.headingsColor)
        const secondaryColor =
          props.secondaryColor || (theme && theme.highlightColor) || primaryColor

        if (props.href) {
          let options = {
            to: to,
            class: cls,
            target: target,
            onClick: trackingCallback
          }

          if (props.href.indexOf('#') > -1) {
            options.href = props.href.substr(props.href.indexOf('#'))
          }

          button = (
            <LinkRouter {...options} color={primaryColor} highlight={secondaryColor}>
              <span className="btn__label">{props.children}</span>
              <InlineSVG icon="arrow-right" />
            </LinkRouter>
          )
        } else {
          const options = {
            className: cls,
            onClick: () => {
              trackingCallback && trackingCallback()
              props.onClick && props.onClick()
              props.handler && props.handler()
            }
          }

          button = (
            <button {...options} style={{ color: primaryColor, borderColor: secondaryColor }}>
              <span className="btn__label">{props.children}</span>
              <InlineSVG icon={props.icon} />
            </button>
          )
        }

        return button
      }}
    </ThemeConsumer>
  )
}

DrakkarButton.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  showHoverState: PropTypes.bool,
  icon: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string
}

DrakkarButton.defaultProps = {
  icon: 'arrow-right'
}

export default DrakkarButton
