import React from 'react'
import InlineSVG from 'components/_ui/InlineSVG'
import LinkRouter from 'components/_utility/LinkRouter'
import styled, { css } from 'styled-components'
import { withOAuth } from 'components/HOC/OAuth'
import { Strings } from 'components/_utility/Strings'
import { getLang } from 'utils'

const toggle = css`
  position: relative;
  display: inline-flex;
  height: 42px;
  margin-top: -1em;
  margin-bottom: -1em;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;

  svg {
    font-size: 1.7em;
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 100%;
  }

  span:last-child {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }
`

const Toggle = styled.div`
  ${toggle} opacity: 0.8;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }
`

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 0.7em;
  left: 50%;
  width: 240px;
  background-color: #e6ebeb;
  color: #546067;
  transform: translate(-50%, -0.4em);
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;

  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 0.5em solid transparent;
    border-bottom-color: #ecefef;
  }

  ul {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    flex-direction: column;
  }

  .dropdown-link {
    position: relative;
    padding: 0.2em 3em;
    font-size: 14px;
    transition: color 0.2s ease;
    cursor: pointer;
    opacity: 1;

    &::after {
      opacity: 0;
    }

    span:last-child {
      font-size: 1em;
    }

    .icon {
      position: absolute;
      top: 1.1em;
      left: 1.8em;
      font-size: 0.8em;
      color: #05b8cf;
      transform: translate(-0.5em, -50%);
      opacity: 0;
      transition: all 0.3s ease;
    }

    &:hover {
      color: #05b8cf;

      .icon {
        opacity: 1;
        transform: translate(0, -50%);
      }
    }
  }

  footer {
    border-top: 1px solid rgba(102, 102, 102, 0.2);
  }

  .sign-out {
    display: block;
    padding: 1.4em 3em;
    font-size: 14px;
    transition: color 0.3s ease;
    cursor: pointer;

    &:hover {
      color: #05b8cf;
    }
  }
`

const Wrap = styled.div`
  position: relative;
  margin-left: 4.3em;

  &:hover ${Dropdown} {
    transform: translate(-50%, 0);
    opacity: 1;
    visibility: visible;
  }
`

type PropsType = {
  oauth: any
  menu?: {
    url_str: string
    name: string
  }[]
}

const IntranetMenu = (props: PropsType) => {
  const { menu, oauth } = props
  const lang = getLang()

  const items =
    menu &&
    menu.map((item, idx) => {
      return (
        <li key={idx}>
          <LinkRouter class="dropdown-link" to={item.url_str}>
            <InlineSVG icon="arrow-right" />
            <span>{item.name}</span>
          </LinkRouter>
        </li>
      )
    })

  // If token has expired / doesn't exist, render nothing
  if (oauth.isExpired()) {
    return null
  }

  return (
    <Wrap>
      <Toggle>
        <InlineSVG icon="intranet-user" />
        <span>{Strings.intranet.menu_title[lang]}</span>
      </Toggle>
      <Dropdown>
        {items && <ul>{items}</ul>}
        <footer>
          <span className="sign-out" onClick={oauth.logout}>
            {Strings.intranet.sign_out[lang]}
          </span>
        </footer>
      </Dropdown>
    </Wrap>
  )
}

export default withOAuth(IntranetMenu)
