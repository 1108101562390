import React, { Component } from 'react'
import Button, { CMS_BUTTON_TYPE } from 'components/_ui/Button'
import { Strings } from 'components/_utility/Strings'
import { getLang } from 'utils'
import Theme4 from 'components/_sections/Article/ArticleGrid/Theme4'
import { ArticleGridContextConsumer } from 'components/_sections/Article/ArticleGrid/context'
import { PageContextConsumer } from 'components/_sections/Page/Wrapper/context'

class LoadMore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showingArticles: 0
    }

    this.showMore = this.showMore.bind(this)
  }

  showMore() {
    this.setState((state) => {
      return {
        showingArticles: state.showingArticles + 8
      }
    })
  }

  render() {
    const { articles } = this.props
    const { showingArticles } = this.state
    const lang = getLang()

    if (!articles) {
      return null
    }

    return (
      <PageContextConsumer>
        {({ pageButtonStyle }) => (
          <ArticleGridContextConsumer>
            {({ loadMoreText, style }) => (
              <div className="article-grid__more">
                {showingArticles > 0 && <Theme4 articles={articles.slice(0, showingArticles)} />}

                {showingArticles < articles.length && (
                  <div className="text--center">
                    <Button
                      type="submit"
                      onClick={this.showMore}
                      primaryColor={
                        pageButtonStyle === CMS_BUTTON_TYPE.drakkar
                          ? style.headingsColor
                          : style.highlightColor
                      }
                      secondaryColor={style.highlightColor}
                      buttonStyle={pageButtonStyle}
                    >
                      {loadMoreText || Strings.search.loadMore[lang]}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </ArticleGridContextConsumer>
        )}
      </PageContextConsumer>
    )
  }
}

export default LoadMore
