import React, { Component } from 'react'
import t from 'typy'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'
import classnames from 'classnames'
import styled from 'styled-components'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import Button from 'components/_ui/Button'
import mq from 'constants/mediaQueries'

import 'components/_paragraphs/ImageWithTitle/image-with-title.css'

// When the component goes into mobile carousel mode
const layoutSwitch = mq.breakpoint.tablet

const ImageTextWrapper = styled.div`
  position: relative;
`
const ImageWrapper = styled.img`
  width: 100%;
`

const StyledContent = styled.div`
  position: ${(props) => (props.backgroundImage ? 'absolute' : 'relative')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  padding: 2em;
  max-width: 1000px;
  transition: all 0.3s ease;
  text-align: center;
  @media (min-width: 1450px) {
    padding: 4em;
  }
  @media (min-width: 1100px) {
    padding: 3em;
  }
  ${(props) =>
    props.position &&
    `
    text-align: ${props.position};
  `} p {
    font-size: 1.1875em;
    line-height: 1.4;
    ${(props) =>
      props.theme.paragraphColor &&
      `
      color: ${props.theme.paragraphColor};
    `};
  }

  & > div {
    width: 100%;
  }
`

class ImageWithTitle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: window.matchMedia(`(max-width: ${layoutSwitch}px)`).matches
    }
    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    this.setState({
      isMobile: window.matchMedia(`(max-width: ${layoutSwitch}px)`).matches
    })
  }

  render() {
    const { content, buttonStyle } = this.props
    const { isMobile } = this.state
    const backgroundImage = t(content, 'image.url').safeObject ? (
      <ImageWrapper src={t(content, 'image.url').safeObject} />
    ) : null

    const mobileBackgroundImage = t(content, 'imageMobile.url').safeObject ? (
      <ImageWrapper src={t(content, 'imageMobile.url').safeObject} />
    ) : (
      backgroundImage
    )

    return (
      <ThemeConsumer>
        {(theme) => {
          const isFullWidth = content.theme === 'full_width'
          const cls = classnames({
            'margin-wrapper': !isFullWidth
          })
          return (
            <ImageTextWrapper className={cls}>
              <div>{isMobile ? mobileBackgroundImage : backgroundImage}</div>
              <StyledContent
                position={content.position}
                theme={theme}
                backgroundImage={isMobile ? mobileBackgroundImage : backgroundImage}
              >
                <div>
                  <HtmlParser string={content.field_wysiwyg} theme={theme} />
                  {content.cta && Object.keys(content.cta).length ? (
                    <Button
                      href={content.cta.uri}
                      options={content.cta.options}
                      primaryColor={theme.headingsColor}
                      secondaryColor={theme.highlightColor}
                      buttonStyle={buttonStyle}
                    >
                      {content.cta.title}
                    </Button>
                  ) : null}
                </div>
              </StyledContent>
            </ImageTextWrapper>
          )
        }}
      </ThemeConsumer>
    )
  }
}

export default ImageWithTitle
