import styled from 'styled-components'
import { ColorNamesOf, TopNavColors } from 'ts/DTO/content.colorpalette'
type WrapProps = {
  sticky: boolean
  useLoadBackground: boolean
}

/*******************************************************
 * STYLE
 *******************************************************/
export const Wrap = styled.div<ColorNamesOf<TopNavColors> & WrapProps>`
  transition: all 0.3s ease;

  ${(props) =>
    props.loadBackground &&
    props.useLoadBackground &&
    `
    background-color: ${props.loadBackground};
  `}

  ${(props) =>
    props.sticky &&
    props.stickyBackground &&
    `
    background-color: ${props.stickyBackground};
  `}

  ${(props) =>
    props.loadFontColorDefault &&
    `
    color: ${props.loadFontColorDefault};
  `}

  ${(props) =>
    props.sticky &&
    props.stickyFontColorDefault &&
    `
    color: ${props.stickyFontColorDefault};
  `}

  ${(props) =>
    props.loadFontColorActive &&
    `
    a:hover,
    .header__search-toggle:hover .icon,
    .toggler:hover {
      color: ${props.loadFontColorActive};
    }
  `}

  ${(props) =>
    props.sticky &&
    props.stickyFontColorActive &&
    `
    a:hover,
    .header__search-toggle:hover .icon,
    .toggler:hover {
      color: ${props.stickyFontColorActive};
    }
  `}

  ${(props) =>
    props.activeSearchBackground &&
    `
    .header__search {
      background-color: ${props.activeSearchBackground};

      input {
        background-color: transparent;
      }
    }
  `}

  ${(props) =>
    props.activeSearchFontColor &&
    `
    input,
    input::placeholder,
    .toggler {
      color: ${props.activeSearchFontColor};
    }
  `}

  input {
    font-size: 1.2em;
    text-align: center;

    ::placeholder {
      opacity: 1;
    }
  }
`

export const UtilityMenuItem = styled.li``

export const UtilityMenu = styled.ul`
  margin-right: 20px;
`
