import React from 'react'
import Tracker from 'utils/tracking'
import t from 'typy'
import classnames from 'classnames'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import InlineSVG from 'components/_ui/InlineSVG'
import LinkRouter from 'components/_utility/LinkRouter'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'

import 'components/_ui/Button/DrakkarDigital/drakkar-digital.css'

const ASSETS_PATH = process.env.ASSETS_PATH

const StyledButton = styled.button`
  ${(props) =>
    props.borderColor &&
    `
    border-color: ${props.borderColor};
  `}

  ${(props) =>
    props.hoverColor &&
    `
    &:hover {
      color: ${props.hoverColor};
    }
  `}

  ${(props) =>
    props.hoverBackgroundColor &&
    `
    &:hover {
      background-color: ${props.hoverBackgroundColor};
    }
  `}
`

const DrakkarDigital = (props) => {
  let button
  let target
  let to = props.href

  // Check if there's a tracking callback attached inside options
  let trackingCallback
  if (props.options && props.options.gtm_id) {
    trackingCallback = Tracker(props.options.gtm_id)
  }

  const cls = classnames({
    btn: true,
    'btn--drakkar-digital': true,
    'btn--full': props.full,
    [`${props.className}`]: props.className
  })

  if (
    props.target === '_blank' ||
    (props.options && (props.options['isExternal'] || props.options['type'] === 'file'))
  ) {
    target = 'external'
    if (props.options && props.options['type'] === 'file') {
      to = ASSETS_PATH + props.href
    } else {
      to = props.href
    }
  }

  return (
    <ThemeConsumer>
      {(theme) => {
        // Button specific configuration
        const granularStyles = t(props, 'granularStyles["DRAKKAR Digital"]').safeObject || {}

        // Allow props to override primary/secondary color
        // otherwise - use page section theme colors
        const primaryColor = props.primaryColor || (theme && theme.headingsColor)
        const secondaryColor =
          props.secondaryColor || (theme && theme.highlightColor) || primaryColor
        const hoverColor = theme && theme.activeColor

        // Final button style properties
        const styles = {
          color: secondaryColor,
          borderColor: secondaryColor,
          hoverColor: hoverColor,
          hoverBackgroundColor: secondaryColor,
          ...granularStyles // Override with granularStyles props
        }

        if (props.href) {
          let options = {
            to: to,
            target: target,
            onClick: trackingCallback
          }

          if (props.href.indexOf('#') > -1) {
            options.href = props.href.substr(props.href.indexOf('#'))
          }

          button = (
            <LinkRouter
              {...options}
              style={{ textDecoration: 'none' }}
              color={styles.color}
              highlight={styles.color}
            >
              <StyledButton
                as={'div'}
                className={cls}
                hoverColor={styles.hoverColor}
                hoverBackgroundColor={styles.color}
              >
                <span className="btn__label">{props.children}</span>
              </StyledButton>
            </LinkRouter>
          )
        } else {
          const options = {
            className: cls,
            onClick: () => {
              trackingCallback && trackingCallback()
              props.onClick && props.onClick()
              props.handler && props.handler()
            }
          }

          button = (
            <StyledButton {...options} {...styles}>
              <span className="btn__label">{props.children}</span>
            </StyledButton>
          )
        }

        return button
      }}
    </ThemeConsumer>
  )
}

DrakkarDigital.propTypes = {
  href: PropTypes.string,
  target: PropTypes.string,
  showHoverState: PropTypes.bool,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string
}

export default DrakkarDigital
