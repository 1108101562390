import React, { useEffect, useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Select, { OptionTypeBase } from 'react-select'
import axios from 'axios'
import { Strings } from 'components/_utility/Strings'
import useConfig from 'components/hooks/useConfig'
import { getLang } from 'utils'
import Tracker from 'utils/tracking'
import Button from 'components/_ui/Button'
import { ButtonStyle as ButtonStyleType } from 'ts/DTO/content.config'
import Loader from 'components/_ui/Loader'
import 'components/_integrations/ATS/Form/form.css'

const history = createBrowserHistory()
const queryData = {
  baseURL: 'https://portal.dynamicsats.com',
  ID: {
    en: 'c661517b-0cf1-4c65-9f24-ee42b08eb98d',
    fr: 'c0f5c902-7098-4906-bdc7-b6447b237672'
  },
  fields: {
    jobtitle: {
      en: 'drak_jobtitleen',
      fr: 'dcrs_jobtitle'
    },
    jobdescription: {
      en: 'drak_descriptionen',
      fr: 'dcrs_jobdescription'
    },
    type: {
      en: 'drak_typeen',
      fr: 'dcrs_type'
    },
    category: {
      en: 'drak_categoryen',
      fr: 'dcrs_category'
    },
    location: {
      en: 'dcrs_location',
      fr: 'jov_cityregion'
    }
  },
  endpoint: 'JobListing'
}

const initialState = {
  searchString: '',
  navigate: false,
  newLocation: '',
  endpoint: ''
}

type StateType = {
  loaded?: boolean
  data?: any
  response?: number
  searchString?: string
  searchLocation?: OptionTypeBase
  navigate?: boolean
  newLocation?: string
  endpoint?: string
}

type LangType = 'en' | 'fr'
interface Props {
  placeholder?: string
  buttonStyle?: ButtonStyleType
}
interface IProps extends Props, RouteComponentProps {}

const ATSForm = (props: IProps) => {
  const { getConfig } = useConfig()
  const config = getConfig()
  const [state, setState] = useState<StateType>(initialState)
  const lang = getLang() as LangType
  const remoteQuery = `${queryData.baseURL}/${queryData.endpoint}/${queryData.ID[lang]}`
  const fields = queryData.fields

  const fetchEndpoint = (endpoint: string) => {
    // console.log(endpoint)
    axios
      .get(endpoint)
      .then((response) => {
        setState({
          ...state,
          loaded: true,
          data: response.data,
          response: 200
        })
      })
      .catch((error) => {
        if (error.response) {
          setState({
            ...state,
            loaded: true,
            data: error.response.data,
            response: error.response.status
          })
        } else {
          setState({
            ...state,
            loaded: true,
            data: {},
            response: 500
          })
        }
      })
  }

  useEffect(() => {
    if (!state.loaded) {
      const endpoint = `${config.api_url}/get/cors/${lang}/joblocations`
      fetchEndpoint(endpoint)
    }
  }, [state.loaded])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const str = state.searchString
    const loc = state.searchLocation?.value

    e.preventDefault()

    const base = lang === 'en' ? '/en/job-listing' : '/fr/liste-emplois'
    history.push(base)

    // Track the term/location
    if (str && loc) {
      Tracker('UCS011')(str, loc)
    }

    const locParam = loc ? `&location=${loc}` : ''
    const url = `${base}?search=${str}${locParam}`
    props.history.push(url)
  }

  // TODO: Figure out when/where this is used (if at all)
  const onSubmitRemote = (e: any) => {
    const str = state.searchString
    const loc = state?.searchLocation?.value
    let location
    let filters: any[] = []

    e.preventDefault()

    if (str) {
      filters = [
        {
          name: fields.jobtitle[lang],
          op: 'contains',
          value: str
        },
        {
          name: fields.jobdescription[lang],
          op: 'contains',
          value: str
        },
        {
          name: fields.type[lang],
          op: 'contains',
          value: str
        },
        {
          name: fields.category[lang],
          op: 'contains',
          value: str
        }
      ]
    }

    // Track the term/location
    if (str && loc) {
      Tracker('UCS011')(str, loc)
    }

    if (typeof loc !== 'undefined' && loc !== '' && loc !== null) {
      location = {
        name: fields.location[lang],
        op: 'equals',
        value: loc
      }
      filters.push(location)
    }
    // console.log(filters)
    let query
    if (filters && filters.length) {
      query = [
        {
          logic: 'or',
          filters: filters
        }
      ]
    }
    const url = remoteQuery + (query ? '?filter=' + encodeURIComponent(JSON.stringify(query)) : '')
    // console.log(url)
    let win = window.open(url, '_blank')
    win && win.focus()
  }

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const onChangeSelect = (e: any) => {
    setState({
      ...state,
      searchLocation: e
    })
  }

  // Loading state
  if (!state.loaded) {
    return (
      <div className="loading header__logo mini">
        <Loader />
      </div>
    )
  }

  let options = [
    {
      value: '',
      label: Strings.ATS.all[lang]
    }
  ]

  const { data } = state
  if (data.ATS['locations']) {
    let locations = data.ATS['locations']
    for (let item of locations) {
      let option = {
        value: item,
        label: item
      }
      options.push(option)
    }
  }

  return (
    <form className="wrapper--flex-center ats-form" onSubmit={onSubmit}>
      <input
        placeholder={props.placeholder || Strings.ATS.enterKeys[lang]}
        type="searchString"
        name="searchString"
        className="input"
        autoComplete="off"
        value={state.searchString}
        onChange={onChangeInput}
      />
      <Select
        className="select"
        classNamePrefix="select"
        name="searchLocation"
        placeholder={props.placeholder || Strings.ATS.enterLocation[lang]}
        value={state.searchLocation}
        onChange={onChangeSelect}
        options={options}
      />
      <Button buttonStyle={props.buttonStyle}>{Strings.utility.search[lang]}</Button>
    </form>
  )
}

export default withRouter(ATSForm)
