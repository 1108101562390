// @react
import React, { useContext } from 'react'
// @design
import classnames from 'classnames'
// @common
import LinkRouter from 'components/_utility/LinkRouter/index'
import InlineSVG from 'components/_ui/InlineSVG'
// @components
import * as SC from './styles'
import SearchForm from 'components/_sections/Search/Form/index'
import { HeaderContext } from 'components/_global/Header/context'
import IntranetMenu from './Intranet/index'
import SocialIcons from './SocialIcons'
import LanguagePicker from './LanguagePicker'
// @type
import { ColorNamesOf, TopNavColors } from 'ts/DTO/content.colorpalette'
import { MenuSectionType } from '../../../../ts/DTO/menu'
import { Locale } from 'ts/DTO/meta'

const noLinkStyle = { style: { pointerEvents: 'none' } }

/*******************************************************
 * TYPE
 *******************************************************/
export type FixMeLater = any

export type TopBarColors = ColorNamesOf<TopNavColors> | {}

export interface IProps {
  hideTopNavigation?: boolean
  locale: Locale
  hideLanguageSwitcher: boolean
  colors: TopBarColors
  sticky: boolean
  menu?: MenuSectionType[]
  intranet?: FixMeLater
  toggleSearch?: FixMeLater
  search?: FixMeLater
  searchData?: FixMeLater
  showSearch?: FixMeLater
}

/**
 *
 * @param props
 * @constructor
 */
const TopBar = (props: IProps) => {
  /*******************************************************
   * CONTEXT
   *******************************************************/
  const header = useContext(HeaderContext)

  // Don't render anything if menu, lang switcher and intranet menu are missing
  if (
    !props.menu &&
    !props.locale &&
    !props.intranet &&
    !header.showTopBarSocial &&
    !header.socialMedia
  ) {
    return null
  }

  const navList =
    props.menu &&
    !props.hideTopNavigation &&
    props.menu.map((link, idx) => {
      const style = link.url_str === 'nolink' ? noLinkStyle : null

      return (
        //@ts-ignore
        <SC.UtilityMenuItem key={idx} {...style}>
          <LinkRouter to={link.url_str}>{link.name}</LinkRouter>
        </SC.UtilityMenuItem>
      )
    })

  return (
    <SC.Wrap
      className="header__top"
      sticky={props.sticky}
      useLoadBackground={header.loadMenuStyle === 'opaque'}
      {...props.colors}
    >
      <div className="wrapper">
        <div className="header__row">
          {props.menu && <SC.UtilityMenu>{navList}</SC.UtilityMenu>}

          <SocialIcons />

          {props.showSearch && (
            <div className="header__search-toggle hide--mobile" onClick={props.toggleSearch}>
              <InlineSVG icon="search" />
            </div>
          )}

          {!props.hideLanguageSwitcher && <LanguagePicker locale={props.locale} />}

          <IntranetMenu lang={props.locale.link_lang} menu={props.intranet} />
        </div>
      </div>
      <div
        className={classnames({
          header__search: true,
          'header__search--show': props.search,
          'hide--mobile': true
        })}
      >
        <div className="wrapper">
          <div className="header__search-wrapper">
            <SearchForm
              data={props.searchData}
              navigate
              lang={
                props.searchData?.meta?.locale ? props.searchData?.meta?.locale?.current_lang : null
              }
            />
            <span className="toggler" onClick={props.toggleSearch}>
              <InlineSVG icon="close" />
            </span>
          </div>
        </div>
      </div>
    </SC.Wrap>
  )
}

export default TopBar
