import React from 'react'
import SmoothScroll from 'smooth-scroll'

const AnchorWrapper = (InnerComponent) => {
  return class extends React.Component {
    toLoad = 0

    constructor(props) {
      super(props)
      this.getRef = this.getRef.bind(this)
      this.scrollToElement = this.scrollToElement.bind(this)
      this.onHashChange = this.onHashChange.bind(this)
    }

    componentDidMount() {
      // Try to scroll to element if necessary
      this.scrollToElement()
      // Add window hashchange event handler
      window.addEventListener('hashchange', this.onHashChange, false)
    }

    componentWillUnmount() {
      // Event cleanup
      window.removeEventListener('hashchange', this.onHashChange)
    }

    onHashChange(e) {
      e.preventDefault()
      // Try to scroll to element if necessary
      this.scrollToElement()
    }

    // @todo
    // useLayoutEffect can probably be used instead of manually tracking all the images
    // refactor to that when this is moved from a CC to a hook
    scrollToElement() {
      const anchor = this.props.anchor
      const hash = window.location.hash

      if (anchor && hash && anchor === hash.substr(1)) {
        const id = this.ref.getAttribute('id')
        const target = document.getElementById(id)
        if (target) {
          this.checkImages(target)
        }
      }
    }

    checkImages(target) {
      const images = document.querySelectorAll('img')
      for (let i = 0; i < images.length; i++) {
        if (!images[i].complete) {
          this.toLoad++
          images[i].onload = () => this.onload(target)
          images[i].onerror = () => this.onload(target)
        }
      }
    }

    onload() {
      this.toLoad--
      if (this.toLoad === 0) {
        const scroll = new SmoothScroll()
        scroll.animateScroll(this.ref, false, {
          updateURL: false,
          popstate: false,
          offset: () => {
            return window.matchMedia('(min-width: 1100px)').matches ? 97 : 102
          }
        })
      } else {
        console.log('Waiting on ' + this.toLoad + ' images')
      }
    }

    getRef(node) {
      this.ref = node
    }

    render() {
      return <InnerComponent {...this.props} setAnchorRef={this.getRef} />
    }
  }
}

export default AnchorWrapper
