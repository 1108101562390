import styled from 'styled-components'

export const Wrap = styled.div<{ active?: boolean }>`
  position: relative;
  margin-left: 30px;
  border: 1px solid currentColor;
  border-radius: 0.3em;
  color: currentColor;
  transition: all 0.3s ease;
  z-index: 1;
  user-select: none;

  ${(props) =>
    props.active &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #000;
  `}
`

export const Language = styled.a`
  position: relative;
  top: -0.05em;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  text-transform: uppercase;
  text-align: center;
  color: currentColor;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;

  && {
    padding: 0.3em 0.6em;
  }

  .icon {
    margin-right: -4px;
    margin-top: -3px;
    font-size: 8px;
    vertical-align: middle;
  }
`

export const Dropdown = styled.div<{ show?: boolean }>`
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  transform: translateY(-1em);
  border: inherit;
  background-color: inherit;
  border-bottom-left-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
  z-index: -1;

  ${(props) =>
    props.show &&
    `
    transform: none;
    opacity: 1;
    visibility: visible;
  `}
`
