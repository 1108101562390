import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { Strings } from 'components/_utility/Strings'
import { getLang } from 'utils'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'

import 'components/_sections/Search/Form/form.css'

const Input = styled.input`
  ${(props) =>
    props.styles.hoverColor &&
    `
    &:hover,
    &:focus {
      border-color: ${props.styles.hoverColor};
    }
  `};
`

class Form extends Component {
  constructor(props) {
    super()
    this.lang = getLang()
    this.state = {
      searchString: '',
      navigate: false,
      endpoint: ''
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onSubmit(e) {
    e.preventDefault()

    if (this.props.navigate) {
      switch (this.props.lang || this.lang) {
        case 'en':
          this.newLocation = '/en/search/' + this.state.searchString
          break
        default:
        case 'fr':
          this.newLocation = '/fr/recherche/' + this.state.searchString
          break
      }

      this.setState({ navigate: true })
      this.props.history.push({ pathname: this.newLocation })
    } else {
      this.props.handler(this.state.searchString)
    }
  }

  onChange(e) {
    // update state
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    const { searchString } = this.state
    return (
      <ThemeConsumer>
        {(theme) => (
          <form onSubmit={this.onSubmit}>
            <Input
              placeholder={
                this.props.placeholder || Strings.search.yourSearch[this.props.lang || this.lang]
              }
              type="searchString"
              name="searchString"
              value={searchString}
              autoComplete="off"
              onChange={this.onChange}
              styles={{
                hoverColor: theme && theme.highlightColor
              }}
            />
          </form>
        )}
      </ThemeConsumer>
    )
  }
}

export default withRouter(Form)
