import React from 'react'
import Article from 'components/_sections/Article/ArticleGrid/Article'

const Theme1 = ({ articles }) => (
  <div className="article-grid article-grid--theme_1">
    {articles.map((article, idx) => (
      <div key={idx} className="article-grid__col">
        <Article {...article.content} size="big" />
      </div>
    ))}
  </div>
)

export default Theme1
