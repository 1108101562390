import React from 'react'

const Context = React.createContext({
  style: {},
  border: false,
  wrapAlignment: 'left',
  tileBackgroundColor: '',
  loadMoreText: '',
  sponsoredLabel: '',
  leftBlockPalette: {},
  toggleShare: () => {}
})

export const ArticleGridContextProvider = Context.Provider
export const ArticleGridContextConsumer = Context.Consumer
