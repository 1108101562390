import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import classnames from 'classnames'
import Button from 'components/_ui/Button'
import { themeSanitizer } from 'components/_sections/Page/Section/ThemeContext'
import t from 'typy'
import { removeParagraphFromString, generateGradient } from 'utils'
import mq from 'constants/mediaQueries'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import Video from 'components/_paragraphs/Hero/Video'

import 'sass/vendor/slick.css'
import 'sass/vendor/slick-overrides.css'

const Wrap = styled.div`
  && .slider__slides .slick-list {
    height: 400px;

    ${mq.from(mq.breakpoint.tablet)} {
      height: 600px;
    }

    ${mq.from(mq.breakpoint.desktop)} {
      height: 100vh;
    }
  }

  & .slider__slide {
    justify-content: center;
  }

  && .slick-dots {
    ${mq.to(mq.breakpoint.desktop)} {
      position: absolute;
      background-color: transparent;
    }
  }
`

const SubtitleButton = styled.div``

const StyledSlide = styled.div`
  ${(props) =>
    props.imageSingle &&
    `
    && {
      background-image: url(${props.imageSingle});
    }
  `} ${(props) =>
    props.imageMobile &&
    `
    && {
      @media (max-width: 640px) {
        background-image: url(${props.imageMobile});
      }
    }
  `}

  && .slider__title {
    margin-bottom: 0;
    font-size: 1em;
    font-weight: 400;
  }
`

const StyledButton = styled(Button)`
  &&.btn {
    margin-top: 10px;

    &:last-child {
      margin-top: 1.5em;
    }
  }
`

const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 3em;

  &:last-child {
    margin-bottom: 0;
  }

  > * {
    font-size: 1em;
    line-height: 1;
  }

  ${mq.from(mq.breakpoint.tablet)} {
    font-size: 4em;
  }

  ${mq.from(mq.breakpoint.desktop)} {
    font-size: 4.25em;
  }

  ${mq.from(1300)} {
    font-size: 5em;
  }

  ${mq.from(mq.breakpoint.desktopLg)} {
    font-size: 5.9375em;
  }
`

const Subtitle = styled.span`
  display: inline-block;
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: 80%;
  font-size: 18px;
  line-height: 1.22;

  ${mq.from(mq.breakpoint.tablet)} {
    font-size: 22px;
  }

  ${mq.from(mq.breakpoint.desktop)} {
    font-size: 27px;
  }
`

const Slide = (props) => (
  <StyledSlide
    className={props.cls}
    imageSingle={props.imageSingle}
    imageMobile={props.imageMobile}
  >
    {props.children}
  </StyledSlide>
)

Slide.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  imageSingle: PropTypes.string.isRequired,
  imageMobile: PropTypes.string,
  cls: PropTypes.string.isRequired
}

class Medium extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: 0
    }

    this.onSliderNavClick = this.onSliderNavClick.bind(this)
  }

  onSliderNavClick(idx) {
    this.setState({
      active: idx
    })
  }

  render() {
    let slides = []

    for (let i = 0; i < this.props.data.length; i++) {
      const item = this.props.data[i]
      const { slide } = item
      const { videos } = slide
      const idx = i
      const style = slide.cta ? themeSanitizer(t(item, 'colorPalette.styleColors').safeObject) : {}
      const { alignment } = item.style
      const gradient = item.colorPalette.styleColors.gradient
      const pageGradient = this.props.pagePalette.gradient
      const pageHighlightColor = this.props.pagePalette.highlightColor

      const slideCls = classnames({
        slider__slide: true,
        [`slider__slide--${alignment}`]: alignment,
        'slider__slide--active': this.state.active === idx
      })

      let roundedGradientButton

      if (this.props.buttonStyle === 'Rounded Corners' && gradient) {
        roundedGradientButton = generateGradient(gradient)
      } else {
        roundedGradientButton = ''
      }

      slides.push(
        <Slide
          key={idx}
          style={{ background: gradient ? generateGradient(gradient) : '' }}
          cls={slideCls}
          imageSingle={t(slide, 'imageSingle.url').safeObject}
          imageMobile={t(slide, 'imageMobile.url').safeObject}
        >
          <Video data={videos} />
          <span
            className="bg-accent"
            style={{
              background:
                generateGradient(gradient) ||
                style.highlightColor ||
                generateGradient(pageGradient) ||
                pageHighlightColor
            }}
          />
          <div className="wrapper">
            <div className="slider__title">
              {slide.title && (
                <Title>
                  <HtmlParser string={removeParagraphFromString(slide.title)} />
                </Title>
              )}
              {(slide.subtitle || slide.cta) && (
                <SubtitleButton>
                  {slide.subtitle && (
                    <div>
                      <Subtitle>
                        <HtmlParser string={slide.subtitle} />
                      </Subtitle>
                    </div>
                  )}
                  {slide.cta && (
                    <div
                      onMouseEnter={() => {
                        this.slider.slickPause()
                      }}
                      onMouseLeave={() => {
                        this.slider.slickPlay()
                      }}
                    >
                      <StyledButton
                        href={slide.cta.uri}
                        options={slide.cta.options}
                        primaryColor={style.highlightColor || this.props.pagePalette.highlightColor}
                        buttonStyle={this.props.buttonStyle}
                        style={{
                          background:
                            roundedGradientButton ||
                            style.highlightColor ||
                            this.props.pagePalette.highlightColor
                        }}
                      >
                        {slide.cta.title}
                      </StyledButton>
                    </div>
                  )}
                </SubtitleButton>
              )}
            </div>
          </div>
        </Slide>
      )
    }

    const settings = {
      autoplay: false,
      autoplaySpeed: 3000,
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      focusOnSelect: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
      beforeChange: this.onSliderSlideChange
    }

    return (
      <Wrap className="slider slider--medium slider--large-button">
        <Slider className="slider__slides" ref={(slider) => (this.slider = slider)} {...settings}>
          {slides}
        </Slider>
      </Wrap>
    )
  }
}

Medium.propTypes = {
  data: PropTypes.array.isRequired
}

export default Medium
