import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import t from 'typy'
import SmoothScroll from 'smooth-scroll'

const scrollTo = (hash, e) => {
  e.preventDefault()
  const node = document.getElementById(hash)
  if (node) {
    const scroll = new SmoothScroll()
    scroll.animateScroll(node, false, {
      updateURL: false,
      popstate: false,
      offset: () => {
        return window.matchMedia('(min-width: 1100px)').matches ? 97 : 102
      }
    })
  }
}

/**
 * Link that also works for external URLs
 */
const LinkRouter = (props) => {
  if (!props.to) {
    return null
  }

  const pathArray = props.to.split('#')
  const linkPathname = pathArray[0]
  const hash = pathArray[1]

  let target = hash && window.location.pathname === linkPathname ? 'same-page-hash' : props.target

  if (props.to.startsWith('http')) {
    target = 'external'
  }

  switch (target) {
    case 'internal':
      return (
        <NavLink
          className={props.class}
          style={{
            ...props.style,
            color: t(props, 'style.color').safeObject || props.color,
            borderColor: props.highlight
          }}
          onClick={props.onClick}
          to={props.to}
        >
          {props.name}
          {props.children}
        </NavLink>
      )
    case 'same-page-hash':
      return (
        <a
          className={props.class}
          style={{
            ...props.style,
            color: t(props, 'style.color').safeObject || props.color,
            borderColor: props.highlight
          }}
          onClick={(e) => {
            scrollTo(hash, e)
            if (props.onClick) {
              props.onClick()
            }
          }}
          href={'#' + hash}
        >
          {props.name}
          {props.children}
        </a>
      )
    case 'external':
      return (
        <a
          className={props.class}
          style={{
            ...props.style,
            color: t(props, 'style.color').safeObject || props.color,
            borderColor: props.highlight
          }}
          onClick={props.onClick}
          href={props.to}
          rel="noreferrer noopener"
          target="_blank"
        >
          {props.name}
          {props.children}
        </a>
      )
    default:
      return (
        <Link
          className={props.class}
          style={{
            ...props.style,
            color: t(props, 'style.color').safeObject || props.color,
            borderColor: props.highlight
          }}
          onClick={props.onClick}
          to={props.to}
        >
          {props.name}
          {props.children}
        </Link>
      )
  }
}

LinkRouter.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  name: PropTypes.string,
  color: PropTypes.string,
  target: PropTypes.string,
  class: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any
}

export default LinkRouter
