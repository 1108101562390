import React from 'react'
import classnames from 'classnames'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'
import Button from 'components/_ui/Button'
import { ButtonStyle as ButtonStyleType } from 'ts/DTO/content.config'
import 'components/_paragraphs/Wysiwyg/Section/section.css'

type FixMeLater = any

type ColumnType = FixMeLater

type OneColSectionType = {
  title?: string
  data: {
    title: string
    text: string
  }
}

const OneColSection = (props: OneColSectionType) => (
  <ThemeConsumer>
    {(theme) => (
      <section className="section section--one-col wrapper wysiwyg">
        <div className="wrapper">
          <h4 className="section__title" style={{ color: theme.headingsColor }}>
            <HtmlParser string={props.data.title} />
          </h4>
          <div className="section__text" style={{ color: theme.paragraphColor }}>
            <HtmlParser string={props.data.text} />
          </div>
        </div>
      </section>
    )}
  </ThemeConsumer>
)

type TwoColSectionType = {
  data: any[] // todo: fix
  buttonStyle: ButtonStyleType
  subtitle?: string
  padding?: string
  alignment?: string
  invertMobileColumns?: boolean
  highlightColor?: string
}

const TwoColSection = (props: TwoColSectionType) => {
  const cols = props.data.map((col, idx) => {
    const colContent = col.map((item: FixMeLater, idxx: number) => {
      let el = null
      switch (item.type) {
        case 'image':
          if (item.content.image.url) {
            return <img key={idxx} src={item.content.image.url} alt="" />
          }
          return el
        case 'text_block':
          return (
            <div key={idxx} className="text-block" style={{ margin: 0, padding: 0 }}>
              <HtmlParser string={item.content.text} />
            </div>
          )
        case 'button':
          return (
            <div key={idxx} className={`text-align-${item.content.position}`}>
              <Button
                href={item.content.cta.uri}
                buttonStyle={props.buttonStyle}
                highlightColor={props.highlightColor}
              >
                {item.content.cta.title}
              </Button>
            </div>
          )
        default:
          return <div key={idxx}> {el} </div>
      }
    })

    return (
      <div key={idx} className="section__col">
        {colContent}
      </div>
    )
  })

  return (
    <ThemeConsumer>
      {(theme) => (
        <section
          className={classnames({
            'section section--two-col wrapper wysiwyg': true,
            'section--padded': props.padding === 'large'
          })}
        >
          <div className="wrapper">
            {props.subtitle && (
              <div className="h4 section__subtitle" style={{ color: theme.headingsColor }}>
                <HtmlParser string={props.subtitle} />
              </div>
            )}
            <div
              className={classnames({
                section__row: true,
                [`section__row--align-${props.alignment}`]: props.alignment,
                'section__row--mobile-reverse': props.invertMobileColumns
              })}
              style={{ color: theme.paragraphColor }}
            >
              {cols}
            </div>
          </div>
        </section>
      )}
    </ThemeConsumer>
  )
}

type SectionType = {
  columns: ColumnType[]
  title?: string
  subtitle?: string
  padding?: string
  alignment?: string
  invertMobileColumns?: boolean
  buttonStyle: ButtonStyleType
  highlightColor?: string
}

const Section = (props: SectionType) => {
  let section

  if (props.columns.length === 2) {
    section = (
      <TwoColSection
        subtitle={props.subtitle}
        padding={props.padding}
        alignment={props.alignment}
        data={props.columns}
        buttonStyle={props.buttonStyle}
        highlightColor={props.highlightColor}
        invertMobileColumns={props.invertMobileColumns}
      />
    )
  } else {
    section = <OneColSection title={props.title} data={props.columns[0]} />
  }

  return section
}

export default Section
