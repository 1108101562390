import React, { Component } from 'react'
import classnames from 'classnames'
import Button, { CMS_BUTTON_TYPE } from 'components/_ui/Button/index'
import InlineSVG from 'components/_ui/InlineSVG'
import styled from 'styled-components'

import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'
import { IProps } from 'components/_paragraphs/CTAImageText/index'

import './centered.css'
import { FixMeLater } from 'ts/types'

export interface IState {
  hover: boolean
  transform: string
}

export interface StyledImagePropType {
  className: string
  imageDesktop: string
  imageMobile: string
  style: {
    backgroundColor: string
  }
}
const StyledImage = styled.div`
  ${(props: StyledImagePropType) =>
    props.imageDesktop &&
    `
    && {
      background-image: url(${props.imageDesktop});
    }
  `} ${(props: StyledImagePropType) =>
    props.imageMobile &&
    `
    && {
      @media (max-width: 640px) {
        background-image: url(${props.imageMobile});
      }
    }
  `};
`

class Centered extends React.Component<IProps, IState> {
  inner: FixMeLater
  title: FixMeLater
  constructor(props: IProps) {
    super(props)
    this.state = {
      hover: false,
      transform: ''
    }

    // Create title/inner refs
    // this.titleRef = React.createRef()
    // this.innerRef = React.createRef()
    this.updateStyles = this.updateStyles.bind(this)
  }

  private titleRef = React.createRef<HTMLDivElement>()
  private innerRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    // Add title/inner nodes references
    this.title = this.titleRef.current
    this.inner = this.innerRef.current

    // Add negative vertical translate for the title el (based on inner height)
    const innerHeight = this.inner.getBoundingClientRect().height / 2
    const transform = `translateY(${innerHeight - 30}px)`
    this.title.style.transform = transform
    this.setState({ transform })
  }

  // Update title element transform on hover
  updateStyles(hover: boolean) {
    this.setState({ hover })

    if (hover) {
      this.title.style.transform = ''
    } else {
      this.title.style.transform = this.state.transform
    }
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const cls = classnames({
      'image-text': true,
      'image-text--centered': true,
      'image-text--hover': this.state.hover
    })

    const { activeColor, highlightColor } = this.props.pagePalette
    const buttonStyle = this.props.buttonStyle

    return (
      <ThemeConsumer>
        {(theme: FixMeLater) => (
          <div
            className={cls}
            onClick={() => {
              if (!this.state.hover) {
                this.updateStyles(true)
              }
            }}
            onMouseEnter={() => {
              if (!this.state.hover) {
                this.updateStyles(true)
              }
            }}
            onMouseLeave={() => {
              if (this.state.hover) {
                this.updateStyles(false)
              }
            }}
          >
            <StyledImage
              className="image-text__image"
              imageDesktop={this.props.image}
              imageMobile={this.props.imageMobile}
              style={{
                backgroundColor: highlightColor
              }}
            />
            <div
              className="image-text__inner"
              style={{
                background: theme.gradient || highlightColor
              }}
              ref={this.innerRef}
            >
              <div className="image-text__title" style={{ color: activeColor }} ref={this.titleRef}>
                <h3>
                  <span>
                    <span
                      style={{
                        color: highlightColor,
                        background: theme.gradient || highlightColor
                      }}
                    />
                    {this.props.title}
                  </span>
                </h3>
                <InlineSVG icon="close" className="show--to-desktop" />
              </div>
              <div className="image-text__text" style={{ color: theme.headingsColor }}>
                <InlineSVG
                  onClick={() => this.updateStyles(false)}
                  icon="close"
                  className="show--to-desktop"
                />
                <p className="image-text__subtitle">{this.props.subtitle}</p>
                <div className="text--center">
                  <Button
                    href={this.props.button.uri}
                    options={this.props.button.options}
                    primaryColor={
                      buttonStyle === CMS_BUTTON_TYPE.drakkar ? theme.headingsColor : highlightColor
                    }
                    secondaryColor={activeColor}
                    granularStyles={{
                      [CMS_BUTTON_TYPE.drakkarDigital]: {
                        color: activeColor,
                        borderColor: activeColor,
                        hoverColor: highlightColor
                      }
                    }}
                  >
                    {this.props.button.title}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </ThemeConsumer>
    )
  }
}

export default Centered
