import React from 'react'
import Hero from 'components/_paragraphs/Hero/Small'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'

const SimpleMessage = (props) => {
  return (
    <div>
      <Hero title={props.pageTitle} theme={'theme_2'} />
      <div>
        <section className="section wrapper wysiwyg">
          <div className="wrapper">
            <h2>
              <HtmlParser string={props.body} />
            </h2>
          </div>
        </section>
      </div>
    </div>
  )
}

export default SimpleMessage
