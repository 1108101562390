import React from 'react'
import classnames from 'classnames'
import { SiteLogo as SiteLogoType } from 'ts/DTO/content.config'

/*******************************************************
 * TYPE
 *******************************************************/
type PropsType = SiteLogoType & {
  className: string
}

/**
 *  Component takes in API data, one of the keys (desktop or mobile) from siteLogos
 * @param type
 * @param markup
 * @param filepath
 * @param className
 * @constructor
 */
const SiteLogo = ({ type, markup, filepath, className }: PropsType) => {
  // Early exit
  if (!type) {
    return null
  }

  // Convert SVG markup to JSX because ReactHtmlParser is screwing up viewBox and
  // other SVG attributes and not converting them to JSX properly since May 2018
  if (type === 'svg') {
    return (
      <span
        className={classnames({
          'icon icon--logo icon--svg': true,
          [className]: className
        })}
        dangerouslySetInnerHTML={{
          __html: markup
        }}
      />
    )
  }

  return (
    <span
      className={classnames({
        'icon icon--logo icon--img': true,
        [className]: className
      })}
    >
      <img src={filepath} alt="Logo" />
    </span>
  )
}

export default SiteLogo
