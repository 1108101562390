import styled from 'styled-components'
import mq from '../../../../constants/mediaQueries'

export const Wrap = styled.div<{ bgColor: string; textColor: string }>`
  ${(props) =>
    props.bgColor &&
    `
    .slider__nav-wrapper {
      background-color: transparent;

      ${mq.to(mq.breakpoint.tablet)} {
        background-color: ${props.bgColor};
      }
    }

    .slider__nav {
      background-color: inherit;
    }
  `}

  ${(props) =>
    props.textColor &&
    `
    .slider__nav-item {
      ${mq.to(mq.breakpoint.tablet)} {
        color: ${props.textColor};
      }
    }
  `}
`

export const StyledSlide = styled.div<{ imageSingle: string; imageMobile: string }>`
  ${(props) =>
    props.imageSingle &&
    `
    && {
      background-image: url(${props.imageSingle});
    }
  `} ${(props) =>
    props.imageMobile &&
    `
    && {
      ${mq.to(640)} {
        background-image: url(${props.imageMobile});
      }
    }
  `};
`

export const StyledNavLink = styled.a<{
  style: {
    active: boolean
    hoverColor: string
  }
}>`
  ${(props) =>
    props.style.active &&
    `
    && {
      ${mq.from(mq.breakpoint.tablet)} {
        color: ${props.style.hoverColor};
      }
    }
  `};
`
