// @react
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
// @common
import classnames from 'classnames'
import { getLang } from 'utils'
import styled from 'styled-components'
// @components
import InlineSVG from 'components/_ui/InlineSVG'
import LinkRouter from 'components/_utility/LinkRouter'
import { withOAuth } from 'components/HOC/OAuth'
import { Strings } from 'components/_utility/Strings'
import Group from 'components/_global/Header/Offcanvas/Group'
import 'components/_global/Header/Offcanvas/offcanvas.css'
import { ColorNamesOf, MenuColors, TopNavColors } from 'ts/DTO/content.colorpalette'
import { MainMenuColors } from 'components/_global/Header/MainMenu'
import { TopBarColors } from 'components/_global/Header/TopBar'
import SocialIcons from 'components/_global/Header/TopBar/SocialIcons'

const Header = styled.form``

const SearchToggle = styled.button``

const CloseToggle = styled.span``

const StyledSocialIcons = styled(SocialIcons)`
  margin: 1em 1.3rem;

  svg {
    font-size: 1.2em;
  }
`

const StyledOffcanvas = styled.div<
  ColorNamesOf<MenuColors> & ColorNamesOf<TopNavColors> & { showingSearchForm: boolean }
>`
  ${(props) =>
    props.dropdownFontColor &&
    `
    color: ${props.dropdownFontColor};

    ${Header} {
      &::before {
        background-color: ${props.dropdownFontColor};
      }
    }
  `}

  ${(props) =>
    props.stickyBackgroundColor &&
    `
    background-color: ${props.stickyBackgroundColor};

    .offcanvas__group-links::before,
    .offcanvas__group--active .offcanvas__arrow::before {
      background-color: ${props.dropdownBackgroundColor};
    }
  `};

  ${(props) =>
    props.activeSearchBackground &&
    `
    ${Header} {
      input {
        background-color: ${props.activeSearchBackground};
      }
    }
  `}

  ${(props) =>
    props.activeSearchFontColor &&
    `
    ${Header} {
      input,
      input::placeholder {
        color: ${props.activeSearchFontColor};
        opacity: 1;
      }
    }
  `}

  ${(props) =>
    props.activeSearchFontColor &&
    props.showingSearchForm &&
    `
    ${SearchToggle},
    ${CloseToggle} {
      color: ${props.activeSearchFontColor};
    }
  `}
`

type PropsType = {
  oauth?: any
  toggleOffcanvas: () => void
  locale: {
    link_lang: string
    link: string
  }
  hideSearch: boolean
  contact: boolean
  show: boolean
  homeLink: string
  lang: string

  intranet: any
  menu: any
  colors: MainMenuColors
  topNavColors: TopBarColors
  logo: any
}

type StateType = {
  search: boolean
  searchTerm: string
  searchRedirect?: boolean | string
}

const Offcanvas = (props: ColorNamesOf<MenuColors> & ColorNamesOf<TopNavColors> & PropsType) => {
  const [state, setState] = useState<StateType>({
    search: false,
    searchTerm: ''
  })

  const onCloseClick = () => {
    if (state.search) {
      setState({
        ...state,
        searchRedirect: false,
        search: false,
        searchTerm: ''
      })
    } else {
      props.toggleOffcanvas()
    }
  }

  const toggleSearchForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!state.search) {
      e.preventDefault()
      setState({ ...state, search: true })
    }
  }

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, searchTerm: e.target.value })
  }

  const onSearchFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const lang = getLang()
    const { searchTerm } = state
    props.toggleOffcanvas()
    setState({
      searchRedirect: lang === 'en' ? `/en/search/${searchTerm}` : `/fr/recherche/${searchTerm}`,
      searchTerm: '',
      search: false
    })
  }

  const { oauth, intranet, menu, hideSearch } = props
  const { search, searchTerm, searchRedirect } = state
  const expiredToken = oauth.isExpired()
  const lang = getLang()

  const offcanvasIntranetMenu = !expiredToken
    ? [
        {
          name: Strings.intranet.menu_title[lang],
          target: 'internal',
          url_str: '/',
          children: intranet
        }
      ]
    : []

  const [mainMenu, topMenu] = menu
  const menuItems = []
  if (offcanvasIntranetMenu && offcanvasIntranetMenu.length) {
    menuItems.push(...offcanvasIntranetMenu)
  }
  if (mainMenu && mainMenu.length) {
    menuItems.push(...mainMenu)
  }
  if (topMenu && topMenu.length) {
    menuItems.push(...topMenu)
  }

  const offcanvasMenu = menuItems.map((group, idx) => (
    <Group
      key={idx}
      {...group}
      toggleOffcanvas={props.toggleOffcanvas}
      tagColor={props.colors.dropdownFontColorTag}
    />
  ))

  return (
    <StyledOffcanvas
      className={classnames({
        offcanvas: true,
        'offcanvas--no-contact': !mainMenu || !props.contact,
        'offcanvas--no-logo': !mainMenu,
        'offcanvas--show': props.show,
        'offcanvas--search': search
      })}
      showingSearchForm={search}
      {...props.colors}
      {...props.topNavColors}
    >
      <Header action="/" method="get" className="offcanvas__header" onSubmit={onSearchFormSubmit}>
        {!hideSearch && (
          <SearchToggle type="submit" onClick={toggleSearchForm}>
            <InlineSVG icon="search" />
          </SearchToggle>
        )}
        {mainMenu && (
          <div>
            <LinkRouter onClick={props.toggleOffcanvas} to={props.homeLink}>
              {props.logo}
            </LinkRouter>
          </div>
        )}
        {!hideSearch && (
          <input
            type="text"
            placeholder={Strings.search.yourSearch[lang] || 'Search'}
            value={searchTerm}
            onChange={onSearchInputChange}
          />
        )}
        {searchRedirect && <Redirect to={String(searchRedirect)} push />}
        <CloseToggle onClick={onCloseClick}>
          <InlineSVG icon="close" />
        </CloseToggle>
      </Header>
      <div className="offcanvas__body">
        {offcanvasMenu}
        {props.locale && (
          <div className="offcanvas__group">
            <h4
              onClick={() => {
                document.cookie = `drakkar_language_pref=${props.locale.link_lang}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`
              }}
            >
              <LinkRouter
                to={props.locale.link}
                onClick={props.toggleOffcanvas}
                class="offcanvas__group-title"
              >
                {props.locale.link_lang === 'fr' && 'Français'}
                {props.locale.link_lang === 'en' && 'English'}
              </LinkRouter>
            </h4>
          </div>
        )}
        {!expiredToken && (
          <div className="offcanvas__group">
            <h4>
              <LinkRouter
                to="/"
                onClick={() => {
                  oauth.logout()
                  props.toggleOffcanvas()
                }}
                class="offcanvas__group-title"
              >
                {Strings.intranet.sign_out[lang]}
              </LinkRouter>
            </h4>
          </div>
        )}

        <StyledSocialIcons />
      </div>
    </StyledOffcanvas>
  )
}

export default withOAuth(Offcanvas)
