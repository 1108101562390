import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import Hero from 'components/_paragraphs/Hero/Small/index'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser/index'
import { Strings } from 'components/_utility/Strings'
import { getLang } from 'utils'

import './article-intro.css'
const Wrap = styled.div`
  ${(props: any) =>
    props.style.highlightColor &&
    `
      .hero {
        background-color: ${props.style.highlightColor};
      }
  `};
`

/*******************************************************
 * TYPE
 *******************************************************/
type FixMeLater = any
interface IProps {
  date?: string | false
  type?: string
  title: string
  image?: string
  highlightColor: FixMeLater
  buttonStyle: string
}

const ArticleIntro: React.FunctionComponent<IProps> = ({
  title,
  image,
  type,
  date,
  highlightColor,
  buttonStyle
}) => {
  const lang = getLang()
  return (
    <Wrap
      className={classnames({
        'article-intro-wrap': true,
        'article-intro-wrap--date': date,
        [`article-intro-wrap--${type}`]: type
      })}
      // @ts-ignore
      style={{ highlightColor: highlightColor }}
    >
      {type === 'article' && (
        //@ts-ignore //@todo Fix- Its instance type 'Small' is not a valid JSX element.
        <Hero
          title={title}
          cta={date && <p className="article-intro__date">{date}</p>}
          theme={'theme_1'}
          buttonStyle={buttonStyle}
        />
      )}
      {type !== 'article' && (
        <React.Fragment>
          {/*@ts-ignore*/}
          <Hero title={Strings.text.tips[lang]} theme={'theme_2'} />
          <div className="article-intro">
            <div className="wrapper">
              <h2 className="article-intro__title">
                <HtmlParser string={title} />
              </h2>
              <div className="wrapper">
                <img src={image} alt={title} />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </Wrap>
  )
}

// ArticleIntro.propTypes = {
//   type: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   image: PropTypes.string
// }

export default ArticleIntro
