import React, { Component } from 'react'
import t from 'typy'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import InlineSVG from 'components/_ui/InlineSVG'
import Lightbox from 'react-images'
import { getLang } from 'utils'

import 'sass/vendor/slick.css'
import 'sass/vendor/slick-overrides.css'
import 'components/_paragraphs/Gallery/gallery.css'

const SliderPrevArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <InlineSVG icon="caret-left" />
    </div>
  )
}

SliderPrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
}

const SliderNextArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <InlineSVG icon="caret-right" />
    </div>
  )
}

SliderNextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
}

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentImage: 0,
      lightboxIsOpen: false
    }

    this.imagesLoaded = 0

    this.gotoPrevious = this.gotoPrevious.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoImage = this.gotoImage.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.closeLightbox = this.closeLightbox.bind(this)
    this.onImgLoad = this.onImgLoad.bind(this)
  }

  onImgLoad() {
    this.imagesLoaded += 1

    // NOTE: Trigger resize when final image is loaded
    if (this.imagesLoaded === this.props.images.length) {
      window.dispatchEvent(new window.Event('resize'))
    }
  }

  openLightbox(index, event) {
    event.preventDefault()
    this.setState({
      currentImage: index,
      lightboxIsOpen: true
    })
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    })
  }

  gotoPrevious() {
    this.setState((state) => ({
      currentImage: state.currentImage - 1
    }))
  }

  gotoNext() {
    this.setState((state) => ({
      currentImage: state.currentImage + 1
    }))
  }

  gotoImage(index) {
    this.setState({
      currentImage: index
    })
  }

  render() {
    if (!this.props.images) {
      return null
    }

    const lightBoxImages = []

    const slides = this.props.images.map((image, idx) => {
      lightBoxImages.push({ src: t(image, 'originalUrl').safeObject })
      return (
        <figure key={idx} className="gallery__slide yes">
          <a
            href={t(image, 'url').safeObject}
            className="gallery__slide-wrapper"
            onClick={(e) => this.openLightbox(idx, e)}
          >
            <picture>
              <img
                src={t(image, 'url').safeObject}
                onLoad={this.onImgLoad}
                alt={t(image, 'alt').safeObject}
              />
            </picture>
            <InlineSVG icon="close" />
          </a>
        </figure>
      )
    })

    const settings = {
      dots: false,
      speed: 500,
      infinite: true,
      variableWidth: true,
      centerMode: true,
      slidesToShow: Math.min(this.props.images.length - 1, 6),
      slidesToScroll: 1,
      prevArrow: <SliderPrevArrow />,
      nextArrow: <SliderNextArrow />,
      responsive: [
        {
          breakpoint: 1180,
          settings: {
            slidesToShow: Math.min(this.props.images.length - 1, 4)
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: Math.min(this.props.images.length - 1, 3)
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: Math.min(this.props.images.length - 1, 2)
          }
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    }

    return (
      <div className="gallery">
        <Slider className="gallery__slides" {...settings}>
          {slides}
        </Slider>

        <Lightbox
          images={lightBoxImages}
          isOpen={this.state.lightboxIsOpen}
          currentImage={this.state.currentImage}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          onClose={this.closeLightbox}
          imageCountSeparator={getLang() === 'fr' ? ' de ' : ' of '}
        />
      </div>
    )
  }
}

Gallery.propTypes = {
  images: PropTypes.array.isRequired
}

export default Gallery
