// @react
import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Waypoint } from 'react-waypoint'
import { debounce } from 'lodash'
import classnames from 'classnames'
import t from 'typy'
import mq from 'constants/mediaQueries'
import useConfig from 'components/hooks/useConfig'
import { themeSanitizer } from 'components/_sections/Page/Section/ThemeContext'
import InlineSVG from 'components/_ui/InlineSVG'
import SiteLogo from 'components/_global/SiteLogo'
import TopBar, { TopBarColors } from './TopBar'
import MainMenu, { MainMenuColors, MainMenuButtonColors } from './MainMenu'
import ContactUs from './ContactUs'
import Offcanvas from './Offcanvas'
import { HeaderContextProvider } from './context'
import './header.css'
import { ApiResponse } from '../../../ts/DTO'
import * as SC from './style'

// When the header switches to offcanvas menu
const menuSwitch = mq.breakpoint.desktop

export type FixMeLater = any

type PropsType = {
  data: ApiResponse
  homeLink: string
}

type StateType = {
  sticky: boolean
  offcanvas: boolean
  search: boolean
  headerOffset: number
  isMobile: boolean
}

const Header = (props: PropsType) => {
  const [state, setState] = useState<StateType>({
    sticky: false,
    offcanvas: false,
    search: false,
    headerOffset: 0,
    isMobile: window.matchMedia(`(max-width: ${menuSwitch - 1}px)`).matches
  })

  // On component mount, set spacer height to account for (opaque) fixed header
  useEffect(() => {
    if (wrapRef.current !== null) {
      const height = wrapRef.current.getBoundingClientRect().height
      if (spacerRef.current !== null) {
        spacerRef.current.style.height = `${height}px`
      }
      setState({ ...state, headerOffset: height })
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const wrapRef = useRef<HTMLDivElement>(null)
  const spacerRef = useRef<HTMLDivElement>(null)

  const { getConfig } = useConfig()
  const config = getConfig()

  const palleteMenuColors = props.data.content.colorPalette.menuColors
  const palleteTopNavColors = props.data.content.colorPalette.topNavColors
  // @ts-ignore
  const menuColors: MainMenuColors = themeSanitizer(palleteMenuColors)
  const menuButtonColors: MainMenuButtonColors = themeSanitizer(palleteMenuColors.buttonColor)
  const topNavColors: TopBarColors = themeSanitizer(palleteTopNavColors)

  const toggleSearch = () => {
    setState({ ...state, search: !state.search })
  }

  const toggleOffcanvas = () => {
    const offcanvas = !state.offcanvas
    document.querySelector('body')!.style.overflow = offcanvas ? 'hidden' : 'visible'
    setState({ ...state, offcanvas })
  }

  const toggleSticky = (current: { currentPosition: string }) => {
    let sticky = false
    if (current.currentPosition === 'above') {
      sticky = true
    }
    setState({ ...state, sticky })
  }

  const handleResize = debounce(() => {
    // Set fresh header offset on viewport resize so spacer is always accurate
    let height = 0
    if (wrapRef.current !== null) {
      height = wrapRef.current.getBoundingClientRect().height
      if (spacerRef.current !== null) {
        spacerRef.current.style.height = `${height}px`
      }
    }

    setState({
      ...state,
      isMobile: window.matchMedia(`(max-width: ${menuSwitch - 1}px)`).matches,
      headerOffset: height
    })
  }, 200)

  const { sticky, isMobile } = state
  const customPageTypes = props.data.meta.classes
  const layoutOptions = props.data.content.layoutOptions
  const searchSettings = props.data.content.searchSettings
  const { hideLanguageSwitcher, hideMenu, hideTopNavigation } = layoutOptions || {}
  const { showSearchInNavigation, showSearchInPreFooter } = searchSettings || {}
  const hideHeader = hideLanguageSwitcher && hideMenu && hideTopNavigation
  if (hideHeader) {
    return null
  }

  const headerConfig = {
    showTopBarSocial: t(props, 'data.content.searchSettings.displaySocialMediaInTopNavigation')
      .safeObject,
    socialMedia: t(props, 'data.content.socialMedia').safeObject,
    showTopSeparator: t(props, 'data.content.searchSettings.displayTopNavigationBottomSeparator')
      .safeObject,
    forceMegaMenu: t(props, 'data.content.searchSettings.forceMegaMenu').safeObject,
    showTopOnScroll: t(props, 'data.content.layoutOptions.showTopNavOnScroll').safeObject,
    loadMenuStyle: t(props, 'data.content.searchSettings.loadMenuStyle').safeObject,
    mainMenuAlign: t(props, 'data.content.searchSettings.fieldPosition').safeObject,
    buttonStyle: t(props, 'data.content.searchSettings.rightSideButtonStyle').safeObject
  }

  // Get intranet menu
  const { mobile, desktop, stickyLogo, stickyLogoMobile, openLogoMobile } =
    t(props, 'data.content.siteLogos').safeObject || false

  const logoDesktop = desktop || {}
  const logoMobile = mobile || false
  const logoDesktopSticky = stickyLogo || false
  const logoMobileSticky = stickyLogoMobile || logoMobile || false
  const logoMobileOpen = openLogoMobile || mobile || stickyLogoMobile || logoDesktop

  const formattedClasses =
    customPageTypes &&
    customPageTypes.reduce((total: string, amount: string) => {
      return total.concat(' node-type-' + amount)
    }, '')

  const headerClasses = classnames({
    header: true,
    'header--sticky': sticky,
    'header--no-menu': hideMenu,
    'header--no-top-menu': hideTopNavigation,
    'header--no-switcher': hideLanguageSwitcher
  })

  const icons = (
    <div>
      <SiteLogo
        {...logoDesktop}
        className={classnames({
          'logo--desktop': true,
          'logo--show-on-mobile': !logoMobile,
          'logo--hide-on-sticky': logoDesktopSticky,
          'logo--hide-on-sticky-mobile': logoMobileSticky
        })}
      />
      {logoDesktopSticky && (
        <SiteLogo {...logoDesktopSticky} className="logo--desktop-sticky logo--show-on-sticky" />
      )}
      {logoMobile && (
        <SiteLogo
          {...logoMobile}
          className={classnames({
            'logo--mobile': true,
            'logo--hide-on-sticky': logoMobileSticky
          })}
        />
      )}
      {logoMobileSticky && (
        <SiteLogo {...logoMobileSticky} className="logo--mobile-sticky logo--show-on-sticky" />
      )}
    </div>
  )

  // For portals that override header logo URL through multisite config
  const logoURL = config.logo_url
  const Logo = logoURL ? (
    <a href={logoURL} className="header__logo">
      {icons}
    </a>
  ) : (
    <Link to={props.homeLink} className="header__logo">
      {icons}
    </Link>
  )

  // Only showing language picker - super minimal header
  if (hideMenu && hideTopNavigation && !hideLanguageSwitcher) {
    return (
      <SC.HeaderWrapper
        offsetHeight={state.headerOffset}
        className={`header-wrapper header-wrapper--minimal ${formattedClasses}`}
      >
        <Waypoint
          //@ts-ignore
          onPositionChange={toggleSticky}
          bottomOffset="-1px"
        />
        <SC.StyledHeader
          className={headerClasses}
          sticky={sticky}
          isMobile={isMobile}
          hideMenu={hideMenu}
          {...menuColors}
          hasTopMenu={true}
          useLoadBackground={headerConfig.loadMenuStyle === 'opaque'}
          displayBlackGradient={headerConfig.loadMenuStyle === 'black_gradient'}
          ref={wrapRef}
        >
          <TopBar
            hideLanguageSwitcher={hideLanguageSwitcher}
            locale={props.data.meta.locale}
            colors={topNavColors}
            sticky={sticky}
          />
        </SC.StyledHeader>
        {headerConfig.loadMenuStyle === 'opaque' && (
          <SC.OpaqueHeaderSpacer ref={spacerRef} {...menuColors} />
        )}
      </SC.HeaderWrapper>
    )
  }

  const showingTopMenu =
    props.data.content.menu['top-nav'] ||
    (headerConfig.showTopBarSocial && headerConfig.socialMedia) ||
    (!hideLanguageSwitcher && props.data.meta.locale) ||
    props.data.content.menu?.intranet

  return (
    <HeaderContextProvider value={headerConfig}>
      <SC.HeaderWrapper
        offsetHeight={state.headerOffset}
        isOpaque={headerConfig.loadMenuStyle === 'opaque'}
        className={`header-wrapper ${formattedClasses}`}
      >
        <Waypoint
          //@ts-ignore
          onPositionChange={toggleSticky}
          bottomOffset="-1px"
        />
        <SC.StyledHeader
          className={headerClasses}
          sticky={sticky}
          isMobile={isMobile}
          hideMenu={hideMenu}
          showTopOnScroll={headerConfig.showTopOnScroll}
          {...menuColors}
          hasTopMenu={showingTopMenu}
          useLoadBackground={headerConfig.loadMenuStyle === 'opaque'}
          displayBlackGradient={headerConfig.loadMenuStyle === 'black_gradient'}
          ref={wrapRef}
        >
          {!hideMenu && (
            <ContactUs
              {...t(props, 'data.content.menu.contact[0]').safeObject}
              {...topNavColors}
              sticky={sticky}
              type="link"
            />
          )}

          {!hideTopNavigation && (
            <TopBar
              hideTopNavigation={hideTopNavigation}
              menu={props.data.content.menu['top-nav']}
              locale={props.data.meta.locale}
              hideLanguageSwitcher={hideLanguageSwitcher}
              intranet={props.data.content.menu?.intranet}
              toggleSearch={toggleSearch}
              search={state.search}
              searchData={props.data}
              showSearch={showSearchInNavigation}
              colors={topNavColors}
              sticky={sticky}
            />
          )}

          <div className="wrapper">
            {!hideMenu && Logo}

            <span className="offcanvas-toggle" onClick={toggleOffcanvas}>
              <InlineSVG icon="hamburger" />
            </span>

            {!hideMenu && props.data.content.menu['main'].length && (
              <MainMenu
                menu={props.data.content.menu['main']}
                contact={t(props, 'data.content.menu.contact[0]').safeObject}
                colors={menuColors}
                buttonColors={menuButtonColors}
              />
            )}
          </div>
        </SC.StyledHeader>

        {headerConfig.loadMenuStyle === 'opaque' && (
          <SC.OpaqueHeaderSpacer ref={spacerRef} {...menuColors} />
        )}

        <Offcanvas
          menu={[
            !hideMenu && props.data.content.menu.main,
            !hideTopNavigation && props.data.content.menu['top-nav']
          ]}
          homeLink={props.homeLink}
          locale={!hideLanguageSwitcher && props.data.meta.locale}
          contact={t(props, 'data.content.menu.contact[0]').safeObject}
          intranet={props.data.content.menu?.intranet}
          hideSearch={!showSearchInNavigation && !showSearchInPreFooter}
          toggleOffcanvas={toggleOffcanvas}
          show={state.offcanvas}
          colors={menuColors}
          topNavColors={topNavColors}
          logo={<SiteLogo {...logoMobileOpen} />}
          lang={props.data.meta.locale.current_lang}
        />
      </SC.HeaderWrapper>
    </HeaderContextProvider>
  )
}

export default Header
