import React from 'react'
import styled from 'styled-components'
import t from 'typy'
import { Link } from 'react-router-dom'
import InlineSVG from '../../../../../_ui/InlineSVG'
import { Strings } from '../../../../../_utility/Strings'
import { getLang, hexToRGBA } from '../../../../../../utils'

import { JobsContext } from '../../context'
import './list.css'

const JobItemWrap = styled.div`
  ${(props) => `
    && {
      color: ${props.color};
      border-color: ${hexToRGBA(props.color, 0.2)};
    }

    &&:hover {
      background-color: ${hexToRGBA(props.hoverColor, 0.2)};
    }
  `};
`

const Jobs = () => (
  <JobsContext.Consumer>
    {({ style, jobs }) => {
      const lang = getLang()
      const { results } = jobs

      if (!results || results.length === 0) {
        return <p className="text--center">{Strings.jobs.noResults[lang]}</p>
      }

      return (
        <ul className="jobs__list">
          {results.map((job) => (
            <li key={job.id}>
              <Link to={`/${lang}/JobListing/Details/${job.guid}`}>
                <JobItemWrap
                  className="jobs__item-wrap"
                  color={style.headingsColor}
                  hoverColor={style.highlightColor}
                >
                  <div className="jobs__item">
                    <span className="col col--title">{job.title}</span>
                    <span className="col col--cat">{job.category}</span>
                    <span className="col col--type">{job.job_type}</span>
                    <span className="col col--loc">{job.location}</span>
                  </div>
                  <InlineSVG
                    icon="arrow-right"
                    style={{ color: t(style, 'headingsColor').safeObject }}
                  />
                </JobItemWrap>
              </Link>
            </li>
          ))}
        </ul>
      )
    }}
  </JobsContext.Consumer>
)

export default Jobs
