// @react
import React, { Component } from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom'
// @common
import styled from 'styled-components'
import axios from 'axios'
import t from 'typy'
// @components
import InlineSVG from 'components/_ui/InlineSVG'
import TopBar from 'components/_global/Header/TopBar'
import Bottom from 'components/_global/Footer/Bottom'
import { Strings, Meta } from 'components/_utility/Strings'
import { withOAuth } from 'components/HOC/OAuth'
import BrowserLanguageRedirect from 'components/_utility/BrowserLanguageRedirect'
import HrefLang from 'components/_utility/HrefLang'
import Loader from 'components/_ui/Loader'

const BASE_URL = process.env.API_PATH

const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .header {
    color: currentColor;
  }

  .footer {
    margin-top: auto;
  }
`

const Content = styled.div`
  width: 100%;
  max-width: 500px;
  margin: auto;
  padding: 1.5em;

  .header__logo {
    margin-right: auto;
    margin-left: auto;
    color: #143d51;
    width: auto;

    svg {
      display: block;
      width: 11em;
      height: 7em;
      margin: auto auto 2em;
    }
  }
`

const ContentWrap = styled.div`
  width: 100%;
  max-width: 320px;
  margin: auto;
`

const SuccessMessage = styled.span`
  display: block;
  margin-bottom: 30px;
  text-align: center;
`

const InputWrap = styled.div`
  margin: 1.5em 0;
`

const Label = styled.label`
  display: block;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-weight: 600;

  span {
    color: #ff5940;
  }
`

const Input = styled.input`
  max-width: none;
  height: 48px;
  padding: 0;
  padding: 0.5rem 1rem;
  border: 2px solid transparent;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: #ecefef;
  color: #143d51;
  transition: border-color 0.2s ease;

  ${(props) =>
    props.error &&
    `
    border-color: #ff5940;
  `};
`

const Button = styled.button`
  display: block;
  margin: 2em auto;
  padding: 0.7em 3em 0.9em;
  text-align: center;
  font-size: 1em;
  font-weight: 600;
  border: 0;
  background-color: #143d51;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:hover {
    background-color: #05b8cf;
  }
`

const Error = styled.span`
  display: block;
  padding: 0.6em 1em 0.8em;
  margin-top: 0.5em;
  color: #ff5940;
  background-color: rgba(255, 89, 64, 0.1);
  font-weight: 700;
  font-size: 1em;
  letter-spacing: 0.35px;
  text-align: center;
`

const formView = {
  LOGIN: 'LOGIN',
  RESET: 'RESET',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD'
}

const resetError = {
  code: '',
  message: ''
}

/**
 *
 */
class SignIn extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props)

    this.state = {
      error: {
        code: '',
        message: ''
      },
      setFormState: !props.reset ? formView.LOGIN : formView.CHANGE_PASSWORD,
      userName: '',
      password: '',
      email: '',
      tmpPassword: '',
      newPassword: '',
      confirmPassword: '',
      successMessage: '',
      loading: false
    }
    this.onLogin = this.onLogin.bind(this)
    this.onReset = this.onReset.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.login = this.login.bind(this)
    this.triggerReset = this.triggerReset.bind(this)
    this.triggerReset = this.triggerReset.bind(this)
    this.onChange = this.onChange.bind(this)
    this.setFormView = this.setFormView.bind(this)
  }

  /**
   *
   * @param e
   */
  onChange(e) {
    let error = this.state.error

    if (error.code === e.target.name) {
      error = {}
    }

    this.setState({
      error: error,
      [e.target.name]: e.target.value
    })
  }

  /**
   *
   * @param e
   * @returns {boolean}
   */
  onLogin(e) {
    e.preventDefault()
    const { userName, password } = this.state

    if (!userName.length) {
      this.setState({
        error: {
          code: 'userName',
          message: Strings.intranet.no_username[this.props.lang]
        }
      })

      return false
    }

    if (!password.length) {
      this.setState({
        error: {
          code: 'password',
          message: Strings.intranet.no_password[this.props.lang]
        }
      })

      return false
    }

    this.login(userName, password)
  }

  /**
   *
   * @param e
   * @returns {boolean}
   */
  onReset(e) {
    e.preventDefault()
    const { email } = this.state

    if (!email.length) {
      this.setState({
        error: {
          code: 'email',
          message: Strings.intranet.no_email[this.props.lang]
        }
      })

      return false
    }

    if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email)) {
      this.setState({
        error: {
          code: 'email',
          message: Strings.intranet.invalid_email[this.props.lang]
        }
      })

      return false
    }

    this.triggerReset(email)
  }

  /**
   *
   * @param e
   * @returns {boolean}
   */
  onChangePassword(e) {
    e.preventDefault()
    const { userName, tmpPassword, newPassword, confirmPassword } = this.state

    if (!userName.length) {
      this.setState({
        error: {
          code: 'userName',
          message: Strings.intranet.no_username[this.props.lang]
        }
      })
      return false
    }

    if (!tmpPassword.length) {
      this.setState({
        error: {
          code: 'tmpPassword',
          message: Strings.intranet.no_password[this.props.lang]
        }
      })
      return false
    }

    if (!newPassword.length) {
      this.setState({
        error: {
          code: 'newPassword',
          message: Strings.intranet.no_password[this.props.lang]
        }
      })
      return false
    }

    if (!confirmPassword.length) {
      this.setState({
        error: {
          code: 'confirmPassword',
          message: Strings.intranet.no_password[this.props.lang]
        }
      })
      return false
    }

    if (confirmPassword !== newPassword) {
      this.setState({
        error: {
          code: 'confirmPassword',
          message: Strings.intranet.passwords_match[this.props.lang]
        }
      })
      return false
    }

    this.changePasswordReset(userName, tmpPassword, newPassword)
  }

  /**
   *
   * @param e
   */
  setFormView(view) {
    this.setState({
      setFormState: view
    })
  }

  /**
   *
   * @param userName
   * @param password
   */
  login(userName, password) {
    const { oauth } = this.props
    this.setState({
      loading: true,
      error: resetError
    })

    oauth
      .getToken(userName, password)
      .then((user) => {
        // If there's location referrer - user is redirected from a protected page
        const referrerUrl = this.props.location.state && this.props.location.state.referrer

        // If there's no referrer url - redirect to user's homepage
        const redirectTo = referrerUrl || user.userInfo.termHomePage.link

        this.setState({
          error: resetError,
          loading: false,
          redirectTo: redirectTo
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: {
            code: 'global',
            message: Strings.intranet.wrong_credentials[this.props.lang]
          }
        })
      })
  }

  /**
   *
   * @param userName
   * @param password
   * @returns {Promise<any>}
   */
  triggerReset(email) {
    this.setState({
      loading: true
    })
    const promise = new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${BASE_URL}/user/lost-password?_format=json`,
        data: {
          mail: email
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          this.setState({
            error: resetError,
            loading: false,
            setFormState: 'CHANGE_PASSWORD',
            successMessage: Strings.intranet.instructions_sent[this.props.lang]
          })
        })
        .catch((error) => {
          this.setState({
            loading: false,
            error: {
              code: 'global',
              message: Strings.intranet.user_not_found[this.props.lang]
            }
          })
          reject(error)
        })
    })

    return promise
  }

  /**
   *
   * @returns {Promise<any>}
   * @param userName
   * @param tempPassword
   * @param newPassword
   */
  changePasswordReset(userName, tempPassword, newPassword) {
    this.setState({
      loading: true
    })
    const promise = new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${BASE_URL}/user/lost-password-reset?_format=json`,
        data: {
          name: userName,
          temp_pass: tempPassword,
          new_pass: newPassword
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          this.setState({
            error: resetError,
            loading: false,
            setFormState: 'LOGIN',
            successMessage: response.data.message
          })
        })
        .catch((error) => {
          this.setState({
            loading: false,
            error: {
              code: 'global',
              message: Strings.intranet.credentials_invalid[this.props.lang]
            }
          })
          reject(error)
        })
    })

    return promise
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const { lang } = this.props
    const {
      userName,
      password,
      newPassword,
      confirmPassword,
      tmpPassword,
      email,
      error,
      redirectTo
    } = this.state
    let onSubmit = this.onLogin

    const redirect = redirectTo ? <Redirect to={redirectTo} /> : null

    const formLogin = (
      <div>
        <InputWrap>
          <Label>
            {Strings.intranet.username[lang]} <span>*</span>
          </Label>
          <Input
            type="text"
            name="userName"
            value={userName}
            onChange={(e) => this.onChange(e)}
            error={error.code === 'userName'}
          />
          {error.code === 'userName' && <Error>{error.message}</Error>}
        </InputWrap>

        <InputWrap>
          <Label>
            {Strings.intranet.password[lang]} <span>*</span>
          </Label>
          <Input
            type="password"
            name="password"
            value={password}
            onChange={(e) => this.onChange(e)}
            error={error.code === 'password'}
          />
          {error.code === 'password' && <Error>{error.message}</Error>}
        </InputWrap>
        <div
          style={{ cursor: 'pointer' }}
          className="wrapper--flex-center"
          onClick={(e) => this.setFormView(formView.RESET, e)}
        >
          <span>{Strings.intranet.forgotPasswordCTA[lang]}</span>
        </div>
      </div>
    )

    let renderForm = formLogin

    /**
     *
     */
    const formReset = (
      <div>
        <InputWrap>
          <Label>
            {Strings.intranet.email[lang]} <span>*</span>
          </Label>
          <Input
            type="text"
            name="email"
            value={email}
            onChange={(e) => this.onChange(e)}
            error={error.code === 'email'}
          />
          {error.code === 'email' && <Error>{error.message}</Error>}
        </InputWrap>
        <div
          style={{ cursor: 'pointer' }}
          className="wrapper--flex-center"
          onClick={(e) => this.setFormView(formView.LOGIN, e)}
        >
          <InlineSVG icon="arrow-left" />
          <span>{Strings.intranet.backToLoginCTA[lang]}</span>
        </div>
      </div>
    )

    /**
     *
     */
    const formChangePassword = (
      <div>
        <InputWrap>
          <Label>
            {Strings.intranet.username[lang]} <span>*</span>
          </Label>
          <Input
            type="text"
            name="userName"
            value={userName}
            onChange={(e) => this.onChange(e)}
            error={error.code === 'userName'}
          />
          {error.code === 'userName' && <Error>{error.message}</Error>}
        </InputWrap>

        <InputWrap>
          <Label>
            {Strings.intranet.tempPassword[lang]} <span>*</span>
          </Label>
          <Input
            type="password"
            name="tmpPassword"
            value={tmpPassword}
            onChange={(e) => this.onChange(e)}
            error={error.code === 'tmpPassword'}
          />
          {error.code === 'tmpPassword' && <Error>{error.message}</Error>}
        </InputWrap>

        <InputWrap>
          <Label>
            {Strings.intranet.newPassword[lang]} <span>*</span>
          </Label>
          <Input
            type="password"
            name="newPassword"
            value={newPassword}
            onChange={(e) => this.onChange(e)}
            error={error.code === 'newPassword'}
          />
          {error.code === 'newPassword' && <Error>{error.message}</Error>}
        </InputWrap>
        <InputWrap>
          <Label>
            {Strings.intranet.confirmNewPassword[lang]} <span>*</span>
          </Label>
          <Input
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => this.onChange(e)}
            error={error.code === 'confirmPassword'}
          />
          {error.code === 'confirmPassword' && <Error>{error.message}</Error>}
        </InputWrap>
        <div
          style={{ cursor: 'pointer' }}
          className="wrapper--flex-center"
          onClick={(e) => this.setFormView(formView.LOGIN, e)}
        >
          <InlineSVG icon="arrow-left" />
          <span>{Strings.intranet.login[lang]}</span>
        </div>
      </div>
    )

    /**
     *
     */
    switch (this.state.setFormState) {
      case 'LOGIN':
        renderForm = formLogin
        onSubmit = this.onLogin
        break
      case 'RESET':
        onSubmit = this.onReset
        renderForm = formReset
        break
      case 'CHANGE_PASSWORD':
        onSubmit = this.onChangePassword
        renderForm = formChangePassword
        break
      default:
        break
    }

    /**
     *
     */
    return (
      <Form onSubmit={onSubmit}>
        <HrefLang
          hasTranslation={t(Meta.sign_in[lang], 'hasTranslation').safeObject}
          translations={t(Meta.sign_in[lang], 'locale.translations').safeObject}
        />
        <BrowserLanguageRedirect
          hasTranslation={t(Meta.sign_in[lang], 'hasTranslation').safeObject}
          translations={t(Meta.sign_in[lang], 'locale.translations').safeObject}
        />
        {redirect}
        <div className="header-wrapper header-wrapper--minimal">
          <header className="header">
            <div className="wrapper">
              <TopBar locale={Meta.sign_in[lang].locale} />
            </div>
          </header>
        </div>

        <Content>
          <ContentWrap>
            <Link
              to="/"
              className="header__logo"
              style={{ height: 'auto', justifyContent: 'center' }}
            >
              <InlineSVG icon="logo" />
            </Link>
          </ContentWrap>

          {this.state.successMessage !== '' && (
            <SuccessMessage>{this.state.successMessage}</SuccessMessage>
          )}

          <ContentWrap>
            {renderForm}
            {error.code === 'global' && <Error>{error.message}</Error>}
            {this.state.loading ? (
              <div className="wrapper--flex-center">
                <Loader mini />
              </div>
            ) : (
              <Button>{Strings.intranet.submit[lang]}</Button>
            )}
          </ContentWrap>
        </Content>

        <footer className="footer">
          <Bottom lang={lang} />
        </footer>
      </Form>
    )
  }
}

export default withRouter(withOAuth(SignIn))
