export default {
  black: '#000',
  white: '#fff',
  blue: '#05b8cf',
  blueExtraLight: '#d7e9ed',
  green: '#36bc8b',
  darkBlue: '#143d51',
  darkBlueLogo: '#103141',
  yellow: '#d8c722',
  yellowAlt: '#e5db00',
  articleBorderGray: '#d1d5da'
}
