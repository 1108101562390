import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
// @components
import Button, { CMS_BUTTON_TYPE } from 'components/_ui/Button'
import InlineSVG from 'components/_ui/InlineSVG'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'
//@hoc
import { GTMConsumer } from 'components/_sections/Page/Section'
import anchorWrapper from 'components/HOC/anchorWrapper'
// @style
import 'components/_paragraphs/CTAImageTextDrawer/image-text-drawer.css'

/**
 *
 */
class CTAImageTextDrawer extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }

    this.bottomRef = React.createRef()

    this.toggleOpen = this.toggleOpen.bind(this)
  }

  componentDidMount() {
    this.bottom = this.bottomRef.current
  }

  toggleOpen() {
    const open = !this.state.open
    this.setState({ open })

    const bottom = this.bottom

    if (bottom.style.maxHeight) {
      bottom.style.maxHeight = null
    } else {
      bottom.style.maxHeight = bottom.scrollHeight + 'px'
    }
  }

  render() {
    const { theme, imagePosition, highlightColor, titleCentered, buttonStyle } = this.props

    const noCTAButton = theme === 'article_no_cta'
    const noLinks = theme === 'article'

    const cls = classnames({
      drawer: true,
      'drawer--image-left': !imagePosition,
      'drawer--title-center': titleCentered,
      [`drawer--image-${imagePosition}`]: imagePosition,
      'drawer--open': this.state.open,
      'drawer--article': noCTAButton || noLinks
    })

    return (
      <ThemeConsumer>
        {(theme) => (
          <GTMConsumer>
            {(context) => (
              <div
                id={this.props.anchor}
                className={cls}
                ref={(node) => {
                  this.props.setAnchorRef(node)
                }}
              >
                <div className="drawer__top">
                  <span
                    className="drawer__image"
                    style={{
                      backgroundImage: 'url(' + this.props.image + ')',
                      backgroundColor: theme.highlightColor
                    }}
                  />
                  <span className="drawer__image-hider" onClick={this.toggleOpen} />
                  <div className="drawer__top-text" onClick={this.toggleOpen}>
                    <div style={{ color: theme.headingsColor }}>
                      <div
                        className="drawer__spacer"
                        style={{ color: highlightColor || theme.highlightColor }}
                      >
                        <InlineSVG icon="close" />
                      </div>
                      <div className="drawer__title">
                        <h4>{this.props.title}</h4>
                        {this.props.titleSubtitle && <p>{this.props.titleSubtitle}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="drawer__bot" ref={this.bottomRef}>
                  <div className="wrapper" style={{ color: theme.paragraphColor }}>
                    <div className="drawer__bot-col drawer__bot-col--text">
                      <HtmlParser string={this.props.subtitle} />
                    </div>
                    {!noLinks && (
                      <div className="drawer__bot-col drawer__bot-col--links wysiwyg">
                        <HtmlParser string={this.props.links} />
                      </div>
                    )}
                    {!noCTAButton && this.props.button && (
                      <div className="drawer__bot-col drawer__bot-col--button">
                        <Button
                          href={this.props.button.uri}
                          options={{ ...this.props.button.options, gtm_id: context }}
                          primaryColor={
                            buttonStyle === CMS_BUTTON_TYPE.drakkar
                              ? theme.headingsColor
                              : highlightColor
                          }
                          secondaryColor={highlightColor || theme.highlightColor}
                          buttonStyle={buttonStyle}
                        >
                          {this.props.button.title}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </GTMConsumer>
        )}
      </ThemeConsumer>
    )
  }
}

CTAImageTextDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  titleSubtitle: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  links: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imagePosition: PropTypes.string.isRequired
}

// CTAImageTextDrawer.contextType = GTMContext

export default anchorWrapper(CTAImageTextDrawer)
