import React from 'react'

export const JobsContext = React.createContext({
  style: {},
  query: '',
  jobs: {},
  name: '',
  hideGeneralJobForm: false,
  filter: {
    data: {},
    collapsed: true,
    toggleCollapsed: () => {},
    active: -1,
    setActive: () => {}
  },
  sortBy: () => {},
  goToPage: () => {},
  applyFormURL: ''
})
