import React from 'react'
import Helmet from 'react-helmet'
import { getLang } from 'utils'
import ReactHtmlParser from 'react-html-parser'
import withConfig from 'components/HOC/config/HOC'

const Meta = (props) => {
  const { title } = props.config
  let metaTags
  let metaOGTitle = title

  if (props.meta.metaTags && props.meta.metaTags.length) {
    metaTags = props.meta.metaTags
      .map((tag, idx) => {
        const attributes = tag.attributes
          .map((attr, idx) => {
            // If we're at property `og:title`, next attribute is the og:title value
            if (attr.value === 'og:title') {
              // remove single/double quotes in <title> tag for SEO purposes
              metaOGTitle = tag.attributes[idx + 1].value.replace(/["']/g, '')
            } //replace double quote with XML entity representation
            return `${attr.type}="${attr.value.replace(/"/g, '&quot;')}"`
          })
          .join('')
        return `<${tag['tag']} ${attributes}/>`
      })
      .join('')
  }
  let tags = ReactHtmlParser(metaTags)

  return (
    <Helmet encodeSpecialCharacters htmlAttributes={{ lang: getLang() }} defaultTitle={title}>
      <title> {metaOGTitle} </title>
      <meta name="fragment" content="!" />
      {tags}
    </Helmet>
  )
}

export default withConfig(Meta)
