export type FixMeLater = any

const Strings: FixMeLater = {
  text: {
    readMore: {
      fr: 'En Savoir Plus',
      en: 'Discover More'
    },
    learnMore: {
      fr: 'En Savoir Plus',
      en: 'Learn More'
    },
    tips: {
      fr: 'Trucs <br /> et Astuces',
      en: 'Tips <br /> and Tricks'
    },
    location: {
      fr: 'Lieu',
      en: 'Location'
    },
    share: {
      fr: 'Partager',
      en: 'Share'
    },
    type: {
      fr: 'Type',
      en: 'Type'
    },
    apply: {
      fr: 'Postulez',
      en: 'Apply'
    },
    all: {
      fr: 'Tout',
      en: 'All'
    },
    page: {
      fr: 'Page',
      en: 'Page'
    },
    sponsored_content: {
      fr: 'Contenu Sponsorisé',
      en: 'Sponsored Content'
    },
    article_by: {
      fr: 'Rédigé par :',
      en: 'By:'
    }
  },
  sitemap: {
    title: {
      fr: 'Plan du site',
      en: 'Sitemap'
    }
  },
  popup: {
    title: {
      fr: 'Maintenant tous unis sous une seule marque.',
      en: 'Now all united under one brand.'
    },
    subtitle: {
      fr: 'Bonne visite du site!',
      en: 'Enjoy your visit!'
    }
  },
  utility: {
    thankyou: {
      fr: 'Merci!',
      en: 'Thank you!'
    },
    search: {
      fr: 'Recherchez',
      en: 'Search'
    },
    of: {
      fr: 'de',
      en: 'of'
    }
  },
  form: {
    101: {
      fr:
        'Votre formulaire a été soumis avec succès. <br> Notre équipe examinera attentivement votre requête et vous contactera sous peu. <br> Merci de votre intérêt envers Drakkar.',
      en:
        'Your form has been successfully submitted. <br> Our team will carefully look at your request and contact you shortly <br> Thank you for your interest in Drakkar.'
    }
  },
  intranet: {
    menu_title: {
      fr: 'Intranet',
      en: 'Intranet'
    },
    username: {
      fr: "Nom d'utilisateur",
      en: 'Username'
    },
    password: {
      fr: 'Mot de passe',
      en: 'Password'
    },
    submit: {
      fr: 'Soumettre',
      en: 'Submit'
    },
    sign_out: {
      fr: 'Déconnexion',
      en: 'Sign Out'
    },
    no_username: {
      fr: "Le nom d'utilisateur est obligatoire.",
      en: 'Username field cannot be blank.'
    },
    no_password: {
      fr: 'Le mot de passe est obligatoire.',
      en: 'Password field cannot be blank.'
    },
    no_email: {
      fr: 'Email est obligatoire.',
      en: 'Email field cannot be blank.'
    },
    wrong_credentials: {
      fr: "Le nom d'utilisateur ou le mot de passe est invalide. Réessayez.",
      en: 'Invalid username or password. Try again.'
    },
    credentials_invalid: {
      fr: 'Informations d’identification invalides.',
      en: 'Credentials not valid.'
    },
    forgotPasswordCTA: {
      fr: 'Mot de passe oublié?',
      en: 'Forgot password?'
    },
    backToLoginCTA: {
      fr: 'Retour',
      en: 'Back'
    },
    email: {
      fr: 'Courriel',
      en: 'Email'
    },
    login: {
      fr: "S'identifier",
      en: 'Login'
    },
    newPassword: {
      fr: 'Nouveau mot de passe',
      en: 'New Password'
    },
    confirmNewPassword: {
      fr: 'Retaper le mot de passe',
      en: 'Re-enter Password'
    },
    tempPassword: {
      fr: 'Mot de passe temporaire',
      en: 'Temporary password'
    },
    passwords_match: {
      fr: 'Les mots de passe doivent correspondre.',
      en: 'Passwords must match.'
    },
    invalid_email: {
      fr: 'Ceci ne ressemble pas à une adresse courriel.',
      en: "This doesn't look like an email."
    },
    user_not_found: {
      fr: "Ce nom d'utilisateur n'est pas valide.",
      en: 'This user was not found or invalid.'
    },
    password_invalid: {
      fr: "Le mot de passe temporaire n'est pas valide.",
      en: 'The temporary password is not valid.'
    },
    instructions_sent: {
      fr: 'Des instructions ont été envoyées à votre adresse de courriel.',
      en: 'Instructions have been sent to your email address.'
    }
  },
  footer: {
    followUs: {
      fr: 'Suivez-nous',
      en: 'Follow us'
    },
    subscribe: {
      fr: 'Abonnez-vous',
      en: 'Sign up'
    },
    jobAlert: {
      fr: 'Alerte-emploi',
      en: 'Job alert'
    },
    copyright: {
      fr: 'Tous droits réservés',
      en: 'All rights reserved'
    },
    email: {
      fr: 'email',
      en: 'email'
    },
    pratiquesrh: {
      followHR: {
        fr: 'Suivez l’actualité RH !',
        en: 'Follow HR news!'
      },
      cta: {
        fr: "Je m'abonne",
        en: 'Subscribe'
      }
    }
  },
  search: {
    title: {
      fr: 'Recherche',
      en: 'Search'
    },
    noResults: {
      fr: 'Aucun résultat trouvé',
      en: 'No results found'
    },
    seeMore: {
      fr: 'Voir plus',
      en: 'See More'
    },
    yourSearch: {
      fr: 'Votre recherche',
      en: 'Your search'
    },
    results: {
      fr: 'Résultats',
      en: 'Results'
    },
    loadMore: {
      fr: 'Voir plus',
      en: 'Load More'
    }
  },
  ATS: {
    all: {
      fr: 'Tout',
      en: 'All'
    },
    enterKeys: {
      fr: 'Mots-clés',
      en: 'Keywords'
    },
    enterLocation: {
      fr: 'Lieu',
      en: 'Location'
    }
  },
  jobs: {
    hero: {
      en: 'Your dream job starts here',
      fr: 'L’emploi de vos rêves, ça commence ici'
    },
    desc: {
      en: 'Job Description',
      fr: 'Description de l’offre'
    },
    not_ready: {
      en: 'Not ready to apply?',
      fr: 'Pas encore prêt à postuler?'
    },
    title: {
      en: 'About DRAKKAR',
      fr: 'À propos de DRAKKAR'
    },
    spontaneous: {
      en: 'Spontaneous Application',
      fr: 'Candidature spontanée'
    },
    about: {
      en:
        'In business for more than 25 years, DRAKKAR is a dedicated and engaged company, with a strong culture of partnership based on a philosophy of excellence, passion, determination, collaboration, and, above all, results. Based in Montreal, with offices in Canada and the U.S., DRAKKAR has an effective and determined team of over 2,500 employees which is the driver of its success.',
      fr:
        "En affaires depuis plus de 25 ans, DRAKKAR est une entreprise passionnée et engagée dont la forte culture partenariale repose sur une philosophie d'excellence, de passion, de détermination, de collaboration et, par-dessus tout, de résultats. Basée à Montréal et possédant plusieurs bureaux au Canada et aux États-Unis, DRAKKAR compte une équipe performante et déterminée de plus de 2 500 employés qui constituent le moteur de sa réussite."
    },
    share: {
      en: 'Share the offer',
      fr: 'Partagez l’offre'
    },
    summary: {
      en: 'Job Summary',
      fr: 'Résumé du poste'
    },
    back: {
      en: 'Back to available jobs',
      fr: 'Retour aux emplois disponibles'
    },
    enter_keyword: {
      en: 'Keywords',
      fr: 'Mots-clés'
    },
    more: {
      en: 'See More',
      fr: 'Voir plus'
    },
    less: {
      en: 'See Less',
      fr: 'Voir moins'
    },
    search: {
      en: 'Location',
      fr: 'Lieu'
    },
    job_title: {
      en: 'Job title',
      fr: 'Titre de l’emploi'
    },
    job_area: {
      en: 'Area of expertise',
      fr: 'Domaine d’expertise'
    },
    submit: {
      en: 'Submit your resume',
      fr: 'Soumettez votre CV'
    },
    without: {
      en: 'without applying to a job offer',
      fr: 'sans appliquer à une offre d’emploi'
    },
    noResults: {
      fr: "Votre recherche n'a donné aucun résultat.",
      en: "The search didn't return any results."
    }
  },
  selekktus: {
    jobSidebarTitle: {
      en: 'About Selekktus',
      fr: 'À propos de Selekktus'
    },
    jobSidebarDesc: {
      en:
        '<p>SELEKKTUS, an affiliate of DRAKKAR Aerospace & Ground Transportation, is a partner of choice for your recruitment process. Our strength is to facilitate your connection with companies. Your new career starts here! Connecting people. Connecting talents.</p>',
      fr:
        '<p>SELEKKTUS, une société affiliée de DRAKKAR Aéronautique et transport terrestre inc, est un partenaire de choix pour votre processus de recrutement.  Notre force, faciliter votre connection avec les entreprises.  Votre nouvelle carrière débute ici! Connecter les gens.  Connecter les talents.</p>'
    }
  },
  drakkardigital: {
    jobSidebarTitle: {
      en: 'About Drakkar Digital',
      fr: 'À propos de Drakkar Digital'
    },
    jobSidebarDesc: {
      en:
        '<p>Our agency proudly offers its capacity to meet all types of digital marketing and technology needs, serving clients of various industries such as retail, fashion, legal, innovation, software, aerospace, transportation and much more.</p><p>DRAKKAR Digital, supported by more than a hundred employees in Canada, the United-States and France, is heading towards a transformation in order to adapt itself to its industry’s evolving context and today’s fluctuating economy. Valuing integrity, collaboration and courage, we intend on developing an engaging and inclusive workplace.</p>',
      fr:
        "<p>C'est avec fierté que notre agence offre sa capacité à répondre à la plupart des besoins en matière de marketing numérique et de technologies, en accompagnant nos clients de diverses industries, telles que la vente au détail, la mode, le légal, l’innovation, le logiciel, l'aérospatiale, le transport et bien plus encore.</p><p>DRAKKAR Digital, soutenue par plus d’une centaine de collaborateurs et employés au Canada, aux États-Unis et en France, se dirige volontiers vers une transformation afin de s’adapter au contexte évolutif de l'industrie et à l’économie fluctuante d’aujourd’hui. Valorisant l’intégrité, la collaboration et le courage, nous nous employons à développer un lieu de travail engageant et inclusif.</p>"
    }
  }
}

const Meta: FixMeLater = {
  sitemap: {
    en: {
      pageTitle: 'Sitemap | Drakkar',
      hasTranslation: true,
      locale: {
        current_lang: 'en',
        link: '/fr/plan-du-site',
        link_lang: 'fr',
        translations: {
          en: '/en/sitemap',
          fr: '/fr/plan-du-site'
        },
        time: '',
        metaTags: {
          title: {
            '#tag': 'meta',
            '#attributes': {
              name: 'title',
              content: 'Sitemap'
            }
          },
          canonical_url: {
            '#tag': 'link',
            '#attributes': {
              rel: 'canonical',
              href: 'http://drakkar.local:8000/en/sitemap'
            }
          }
        }
      }
    },
    fr: {
      pageTitle: 'Plan du Site | Drakkar',
      hasTranslation: true,
      locale: {
        current_lang: 'fr',
        link: '/en/sitemap',
        link_lang: 'en',
        translations: {
          en: '/en/sitemap',
          fr: '/fr/plan-du-site'
        },
        time: '',
        metaTags: {
          title: {
            '#tag': 'meta',
            '#attributes': {
              name: 'title',
              content: 'Plan du Site'
            }
          },
          canonical_url: {
            '#tag': 'link',
            '#attributes': {
              rel: 'canonical',
              href: 'http://drakkar.local:8000/fr/plan-du-site'
            }
          }
        }
      }
    }
  },
  confirmation: {
    en: {
      pageTitle: 'Thank you!',
      id: '42',
      created: '',
      locale: {
        current_lang: 'en',
        link: '/fr/confirmation/message/101',
        link_lang: 'fr',
        translations: {
          en: '/en/confirmation/message/101',
          fr: '/fr/confirmation/message/101'
        }
      },
      time: '',
      metaTags: {
        title: {
          '#tag': 'meta',
          '#attributes': {
            name: 'title',
            content: 'Thank you!'
          }
        },
        canonical_url: {
          '#tag': 'link',
          '#attributes': {
            rel: 'canonical',
            href: '/en/confirmation/message/101'
          }
        }
      }
    },
    fr: {
      pageTitle: 'Merci!',
      id: '42',
      created: '',
      locale: {
        current_lang: 'fr',
        link: '/en/confirmation/message/101',
        link_lang: 'en',
        translations: {
          en: '/en/confirmation/message/101',
          fr: '/fr/confirmation/message/101'
        }
      },
      time: '',
      metaTags: {
        title: {
          '#tag': 'meta',
          '#attributes': {
            name: 'title',
            content: 'Merci!'
          }
        },
        canonical_url: {
          '#tag': 'link',
          '#attributes': {
            rel: 'canonical',
            href: 'http://drakkar.local:8000/fr/confirmation/message/101'
          }
        }
      }
    }
  },
  jobs: {
    en: {
      pageTitle: 'Drakkar Jobs',
      id: '55',
      created: '',
      locale: {
        current_lang: 'en',
        link: '/fr/jobs',
        link_lang: 'fr',
        translations: {
          en: '/en/jobs',
          fr: '/fr/jobs'
        }
      },
      time: '',
      metaTags: {
        title: {
          '#tag': 'meta',
          '#attributes': {
            name: 'title',
            content: 'Drakkar jobs'
          }
        },
        canonical_url: {
          '#tag': 'link',
          '#attributes': {
            rel: 'canonical',
            href: '/en/jobs'
          }
        }
      }
    },
    fr: {
      pageTitle: 'Drakkar jobs',
      id: '55',
      created: '',
      locale: {
        current_lang: 'fr',
        link: '/en/jobs',
        link_lang: 'en',
        translations: {
          en: '/en/jobs',
          fr: '/fr/jobs'
        }
      },
      time: '',
      metaTags: {
        title: {
          '#tag': 'meta',
          '#attributes': {
            name: 'title',
            content: 'Drakkar Jobs'
          }
        },
        canonical_url: {
          '#tag': 'link',
          '#attributes': {
            rel: 'canonical',
            href: '/fr/jobs'
          }
        }
      }
    }
  },
  sign_in: {
    en: {
      pageTitle: 'Intranet Sign In',
      id: '55',
      created: '',
      hasTranslation: true,
      locale: {
        current_lang: 'en',
        link: '/fr/intranet-sign-in',
        link_lang: 'fr',
        translations: {
          en: '/en/intranet-sign-in',
          fr: '/fr/intranet-sign-in'
        }
      },
      time: '',
      metaTags: {
        title: {
          '#tag': 'meta',
          '#attributes': {
            name: 'title',
            content: 'Drakkar jobs'
          }
        },
        canonical_url: {
          '#tag': 'link',
          '#attributes': {
            rel: 'canonical',
            href: '/fr/intranet-sign-in'
          }
        }
      }
    },
    fr: {
      pageTitle: 'Drakkar jobs',
      id: '55',
      created: '',
      hasTranslation: true,
      locale: {
        current_lang: 'fr',
        link: '/en/intranet-sign-in',
        link_lang: 'en',
        translations: {
          en: '/en/intranet-sign-in',
          fr: '/fr/intranet-sign-in'
        }
      },
      time: '',
      metaTags: {
        title: {
          '#tag': 'meta',
          '#attributes': {
            name: 'title',
            content: 'Drakkar Jobs'
          }
        },
        canonical_url: {
          '#tag': 'link',
          '#attributes': {
            rel: 'canonical',
            href: '/fr/intranet-sign-in'
          }
        }
      }
    }
  },
  search: {
    en: {
      pageTitle: 'Search',
      id: '',
      created: '',
      hasTranslation: true,
      locale: {
        current_lang: 'en',
        link: '/fr/recherche',
        link_lang: 'fr',
        translations: {
          en: '/en/search',
          fr: '/fr/recherche'
        }
      },
      time: '',
      metaTags: {
        title: {
          '#tag': 'meta',
          '#attributes': {
            name: 'title',
            content: 'Search'
          }
        },
        canonical_url: {
          '#tag': 'link',
          '#attributes': {
            rel: 'canonical',
            href: 'http://drakkar.local:8000/fr/recherche'
          }
        }
      }
    },
    fr: {
      pageTitle: 'Recherche',
      id: '',
      created: '',
      hasTranslation: true,
      locale: {
        current_lang: 'fr',
        link: '/en/search',
        link_lang: 'en',
        translations: {
          en: '/en/search',
          fr: '/fr/recherche'
        }
      },
      time: '',
      metaTags: {
        title: {
          '#tag': 'meta',
          '#attributes': {
            name: 'title',
            content: 'Recherche'
          }
        },
        canonical_url: {
          '#tag': 'link',
          '#attributes': {
            rel: 'canonical',
            href: 'http://drakkar.local:8000/fr/recherche'
          }
        }
      }
    }
  }
}

export { Strings, Meta }
