import React from 'react'
import classnames from 'classnames'
import Slider from 'react-slick'
import LinkRouter from 'components/_utility/LinkRouter'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'

import 'sass/vendor/slick.css'
import 'sass/vendor/slick-overrides.css'
import 'components/_paragraphs/LogoGroup/logo-group.css'
import { LogoGroupPara, LogoItem } from '../../../ts/DTO/paragraphs'

const settings = {
  dots: true,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  responsive: [
    {
      breakpoint: 1180,
      settings: {
        dots: true,
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 768,
      settings: {
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }
  ]
}

type PropsType = {
  content: LogoItem[]
}

/**
 *
 * @param content
 * @constructor
 */
const LogoGroup = ({ content }: PropsType) => {
  const logos = content.map((logo: any, idx: number) => {
    if (logo.content.cta && logo.content.cta.uri) {
      return (
        <div key={idx} className="logo-group__icon">
          <LinkRouter to={logo.content.cta.uri}>
            <span dangerouslySetInnerHTML={{ __html: logo.content.image.markup }} />
          </LinkRouter>
        </div>
      )
    }
    return (
      <div key={idx} className="logo-group__icon">
        <span dangerouslySetInnerHTML={{ __html: logo.content.image.markup }} />
      </div>
    )
  })

  return (
    <ThemeConsumer>
      {(theme) => (
        <div className="logo-group-wrapper wrapper" style={{ color: theme.headingsColor }}>
          <div
            className={classnames({
              'logo-group': true,
              [`logo-group-with-${logos.length}`]: true
            })}
          >
            <Slider className="logo-group__slide" {...settings}>
              {logos}
            </Slider>
          </div>
        </div>
      )}
    </ThemeConsumer>
  )
}

export default LogoGroup
