import React, { Component } from 'react'
import axios from 'axios'
import queryString from 'qs'
import { getLang } from 'utils'

const BASE_URL = process.env.API_PATH
const CLIENT_ID = process.env.CLIENT_ID
const CLIENT_SECRET = process.env.CLIENT_SECRET
const DRAKKAR_SESSION = '__drakkar__'

export const OAuthContext = React.createContext()

// Higher order component that wraps the component with OAuth Consumer
// and adds the oauth prop to be accessible by wrapped component
export const withOAuth = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      return (
        <OAuthContext.Consumer>
          {(value) => <WrappedComponent {...this.props} oauth={value} />}
        </OAuthContext.Consumer>
      )
    }
  }
}

class OAuth extends Component {
  constructor(props) {
    super(props)

    this.getToken = this.getToken.bind(this)
    this.renewToken = this.renewToken.bind(this)
    this.isExpired = this.isExpired.bind(this)
    this.getUserInfo = this.getUserInfo.bind(this)
    this.logout = this.logout.bind(this)

    // Get session state
    const session = JSON.parse(window.sessionStorage.getItem(DRAKKAR_SESSION))

    this.state = {
      getToken: this.getToken,
      renewToken: this.renewToken,
      isExpired: this.isExpired,
      logout: this.logout,
      token: session && session.token,
      userInfo: session && session.userInfo
    }
  }

  getToken(username, password) {
    const promise = new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${BASE_URL}/oauth/token`,
        data: queryString.stringify({
          grant_type: 'password',
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          username: username,
          password: password
        })
      })
        .then((response) => {
          const token = {
            ...response.data,
            expires: Date.now() + response.data.expires_in * 1000
          }

          this.getUserInfo(token.access_token).then((userInfo) => {
            this.setState(
              {
                userInfo: userInfo,
                token: token
              },
              () => {
                window.sessionStorage.setItem(
                  DRAKKAR_SESSION,
                  JSON.stringify({
                    userInfo: userInfo,
                    token: token
                  })
                )
                resolve({
                  userInfo: userInfo,
                  token: token
                })
              }
            )
          })
        })
        .catch((error) => {
          window.sessionStorage.removeItem(DRAKKAR_SESSION)
          reject(error)
        })
    })

    return promise
  }

  getUserInfo(accessToken) {
    const lang = getLang()

    const promise = new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `${BASE_URL}/get/userinfo/${lang}`,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          resolve(response.data.userBusinessUnit)
        })
        .catch((error) => {
          reject(error)
        })
    })

    return promise
  }

  // Returns true if token expires 10 or more seconds from current time
  isExpired() {
    const { token } = this.state

    if (token) {
      return Date.now() >= token.expires + 10000
    }

    return true
  }

  renewToken() {
    const { token } = this.state
    const refreshToken = token.refresh_token

    const promise = new Promise((resolve, reject) => {
      if (!refreshToken) {
        reject(Error("No refresh token present, can't renew token."))
      }

      axios({
        method: 'post',
        url: `${BASE_URL}/oauth/token`,
        data: queryString.stringify({
          grant_type: 'refresh_token',
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          refresh_token: refreshToken
        })
      })
        .then((response) => {
          const token = {
            ...response.data,
            expires: Date.now() + response.data.expires_in * 1000
          }

          this.getUserInfo(response.data.access_token).then((userInfo) => {
            this.setState(
              {
                userInfo: userInfo,
                token: token
              },
              () => {
                window.sessionStorage.setItem(
                  DRAKKAR_SESSION,
                  JSON.stringify({
                    userInfo: userInfo,
                    token: token
                  })
                )
                resolve({
                  userInfo: userInfo,
                  token: token
                })
              }
            )
          })
        })
        .catch((error) => {
          this.setState(
            {
              token: null,
              userInfo: null
            },
            () => {
              console.log('cant renew token, refresh token expired')
              window.sessionStorage.removeItem(DRAKKAR_SESSION)
              reject(error)
            }
          )
        })
    })

    return promise
  }

  // Logout by sending the refresh token and discarding the result
  // (and destroying the active token)
  logout() {
    const { token } = this.state
    const refreshToken = token.refresh_token

    if (refreshToken) {
      axios({
        method: 'post',
        url: `${BASE_URL}/oauth/token`,
        data: queryString.stringify({
          grant_type: 'refresh_token',
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          refresh_token: refreshToken
        })
      })
    }

    this.setState(
      {
        token: null,
        userInfo: null
      },
      () => {
        window.sessionStorage.removeItem(DRAKKAR_SESSION)
      }
    )
  }

  render() {
    const { children } = this.props

    return <OAuthContext.Provider value={this.state}>{children}</OAuthContext.Provider>
  }
}

export default OAuth
