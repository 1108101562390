import React from 'react'
import t from 'typy'
import Article from 'components/_sections/Article/ArticleGrid/Article'

const Theme4 = ({ articles }) => (
  <div className="article-grid article-grid--theme_4">
    {articles.map((article, idx) => (
      <div key={idx} className="article-grid__col article-grid__col--medium">
        <Article size="medium" {...article.content} />
      </div>
    ))}
  </div>
)

export default Theme4
