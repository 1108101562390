import React from 'react'
import Button from 'components/_ui/Button'
import { Strings } from 'components/_utility/Strings'
import { getLang } from 'utils'

const LoadMore = (props) => {
  const lang = getLang()
  return (
    <div className="load-more button-wrapper--center">
      <Button handler={props.handler}>{Strings.search.loadMore[lang]}</Button>
    </div>
  )
}
export default LoadMore
