import styled from 'styled-components'

export const StyledLi = styled.li<{ noLinkStyle: boolean }>`
  ${(props) =>
    props.noLinkStyle &&
    `
    pointerEvents: none;
  `}
`

export const Menu = styled.ul`
  padding-left: 4rem;
  flex-grow: 1;

  &:not(:last-child) {
    padding-right: 2em;
  }

  + a {
    align-self: center;
  }
`

export const HeaderDropdown = styled.div<{ columns: number; full: boolean; separator?: boolean }>`
  ${(props) =>
    props.full &&
    `
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -50%;
      width: 150vw;
      height: 100%;
      background-color: inherit;
    }

    .header__dropdown-title a {
      font-weight: 600;
    }
  `}

  ${(props) =>
    props.full &&
    props.separator &&
    `
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -50%;
      width: 150vw;
      border-top: 1px solid currentColor;
      color: currentColor;
      opacity: 0.2;
      z-index: 1;
    }
  `}

  ${(props) =>
    props.columns &&
    props.columns > 5 &&
    `
    flex-grow: 1;
    justify-content: flex-start !important;

    ul {
      flex-grow: 0;
    }
  `}

  ${(props) =>
    props.columns &&
    props.columns >= 2 &&
    props.columns < 5 &&
    `
    ul {
      flex-grow: 0;
      min-width: 180px;
    }
  `}
`
export const CustomLabel = styled.span<{ color?: string; matchHeight?: number }>`
  display: block;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;

  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}

  ${(props) =>
    props.matchHeight &&
    `
    min-height: ${props.matchHeight}px;
    margin-bottom: 3px;
  `}
`

export const Wrap = styled.div<{ align: 'center' | 'left' | 'right' }>`
  ${(props) =>
    props.align === 'left' &&
    `
    ${Menu} {
      justify-content: flex-start;
    }
  `}

  ${(props) =>
    props.align === 'center' &&
    `
    ${Menu} {
      justify-content: center;

      ${HeaderDropdown} {
        justify-content: center;
      }
    }
  `}

  ${(props) =>
    props.align === 'right' &&
    `
    ${Menu} {
      justify-content: flex-end;

      ${HeaderDropdown} {
        justify-content: flex-end;
      }
    }
  `}
`
