import React from 'react'
import t from 'typy'
import Article from 'components/_sections/Article/ArticleGrid/Article'

const Theme5 = ({ articles, layoutArticles }) => {
  const leftArticles = articles.slice(0, 2)
  const rightArticles = articles.slice(2, layoutArticles)
  return (
    <div className="article-grid article-grid--theme_5">
      <div className="article-grid__col article-grid__col--medium-two-col">
        {leftArticles.map((article, idx) => (
          <Article {...article.content} key={idx} size="medium" />
        ))}
      </div>
      <div className="article-grid__col">
        {rightArticles.map((article, idx) => (
          <Article {...article.content} key={idx} size="small" />
        ))}
      </div>
    </div>
  )
}

export default Theme5
