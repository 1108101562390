// @react
import React, { Children, ReactChild, ReactElement, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
// @common
import axios from 'axios'
import { getLang } from 'utils'
import Tracker from 'utils/tracking'
import useConfig from 'components/hooks/useConfig'
// @HOC
import { withOAuth } from 'components/HOC/OAuth'
// @components
import VendorIntegrations from 'components/_integrations'
import PageBuilder from 'components/_sections/Page/Builder'
import SiteFont from 'components/_global/SiteFont'
import Loader from 'components/_ui/Loader'
import Page500 from 'components/_global/Errors/500'
import Header from 'components/_global/Header'
import Footer from 'components/_global/Footer'
import Meta from 'components/_utility/MetaHandler'
import BrowserLanguageRedirect from 'components/_utility/BrowserLanguageRedirect'
import HrefLang from 'components/_utility/HrefLang'
import CMSRedirect from 'components/_utility/CMSRedirect'
import { PageContextProvider } from 'components/_sections/Page/Wrapper/context'
import { themeSanitizer } from 'components/_sections/Page/Section/ThemeContext'
// @types
import { ApiResponse } from 'ts/DTO'
import { RouteType } from 'App'

/*******************************************************
 * TYPE
 *******************************************************/
type PropsType = {
  data: {
    type: RouteType
    api: string
  }
  oauth: any
  children: ReactChild | ReactChild[]
  match: any
}

/**
 *
 * @param props
 * @constructor
 */
const PageWrapper = (props: PropsType) => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const [homeLink, setHomeLink] = useState<string>('')
  const history = useHistory()

  /**
   * PROPS
   */
  const { data } = props
  const {
    oauth: { isExpired }
  } = props

  const { isLoading, isError, data: queryData, error } = useQuery<ApiResponse, Error>(
    data.api, // dynamic so that the fetch runs on prop change (= hook dependency)
    async () => {
      return await fetchEndpoint(data.api)
    },
    { refetchOnWindowFocus: false }
  )
  /**
   *
   * @param endpoint
   */
  const fetchEndpoint = async (endpoint: string): Promise<ApiResponse> => {
    console.log(endpoint)
    const { oauth } = props
    const { token } = oauth
    const tokenExpired = oauth.isExpired()

    let config = {}

    if (!tokenExpired) {
      config = {
        headers: {
          Authorization: `Bearer ${token.access_token}`
        }
      }
    }

    return await axios
      .get(endpoint, config)
      .then((response) => {
        return {
          ...response.data,
          status: response.status
        }
      })
      .catch((error) => {
        // forbidden
        if (error?.response?.status === 403) {
          // If token is expired - redirect to login page
          if (tokenExpired) {
            const url = getLang() === 'fr' ? '/fr/intranet-sign-in' : '/en/intranet-sign-in'
            history.push(url, { referrer: history.location.pathname })
            return
          } else {
            // If token hasn't expired - the authed user doesn't have access
            // to protected content (ie one role accessing other role's page)
            // redirect back to user homepage
            history.push(oauth.userInfo.termHomePage.link)
            return
          }
        }

        // first pass handling not found job things
        if (error?.response?.status === 404) {
          return {
            ...error.response.data,
            status: 404
          }
        }

        return {
          status: 500
        }
      })
  }

  /*******************************************************
   * CONFIG
   *******************************************************/
  const { getConfig } = useConfig()
  const config = getConfig()
  const { title } = config

  /*******************************************************
   * LIFE CYCLE HOOKS
   *******************************************************/
  useEffect(() => {
    if (queryData && queryData?.meta.siteName && queryData?.meta.pageTitle) {
      Tracker('pageView')(`${queryData.meta.siteName} | ${queryData.meta.pageTitle}`)
    }
  }, [queryData])

  useEffect(() => {
    if (isError) {
      console.warn("Couldn't fetch from", props.data.api, 'due to', error, 'node not found')
    }
  }, [isError])

  useEffect(() => {
    update(queryData)
  }, [queryData])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   * @param response
   */
  const update = (response: any) => {
    const redirectedByCMS = cmsRedirect(response)

    if (redirectedByCMS) {
      return false
    }
    setHomeLink(`/${getLang()}`)
  }

  /**
   *
   * @param response
   */
  const cmsRedirect = (response: ApiResponse): boolean => {
    // do the external CMS redirect here
    // to redirect BEFORE the content is painted onto the page
    if (response && response.meta) {
      const { isRedirected, redirectPath } = response.meta
      const isExternalRedirect = redirectPath && redirectPath.startsWith('http')
      if (isRedirected && isExternalRedirect && redirectPath) {
        window.location.assign(String(redirectPath))
        return true
      }
    }
    return false
  }

  if (isLoading || !queryData) {
    return (
      <div className="loading header__logo">
        <Loader />
      </div>
    )
  }

  /***
   *
   * @constructor
   */
  const Content = (): ReactElement => {
    if (isLoading) {
      return (
        <section id="content-loader-wrapper" className="page-section page-section--border-bottom">
          <div className="page-section__inner min-height">
            <div className="loading header__logo mini">
              <Loader />
            </div>
          </div>
        </section>
      )
    }

    switch (queryData?.status) {
      case 200:
        /**
         * DYNAMIC ROUTE === DYNAMIC NODE PAGE BUILDER
         */
        if (data.type === RouteType.DYNAMIC_ROUTE) {
          return <PageBuilder data={queryData} />
        }

        return Children.map(props.children as any, (child) =>
          React.cloneElement(child, {
            data: queryData,
            match: props.match
          })
        )

      case 404:
        return <Redirect to={'/fr/not-found'} />
      case 500:
      default:
        return <Page500 />
    }
  }

  const pageClasses = (queryData.meta.classes && queryData.meta.classes.join(' ')) || ''

  const brandName = title ? `${title.replace(/ /g, '-').toLowerCase()}` : ''

  // Get Page colorPalette and pass it down to PageSection
  // @todo ...
  const { colorPalette } = queryData?.content
  const { themeColors, styleColors } = colorPalette || {}
  const pageColorPalette = { ...themeColors, ...styleColors }
  const sanitizedPageColorPalette = themeSanitizer(pageColorPalette)
  const buttonStyle = queryData?.content.styleOptions.buttonStyle

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <PageContextProvider
      value={{
        pageButtonStyle: buttonStyle || '',
        pagePalette: sanitizedPageColorPalette || ''
      }}
    >
      <CMSRedirect
        isRedirected={queryData?.meta.isRedirected}
        redirectPath={queryData?.meta.redirectPath}
        location={history.location}
      />
      <div className={`page-wrapper portal--${brandName} ${pageClasses}`}>
        <HrefLang
          hasTranslation={queryData.meta.hasTranslation}
          translations={queryData.meta.locale.translations}
        />
        <BrowserLanguageRedirect
          hasTranslation={queryData.meta.hasTranslation}
          translations={queryData.meta.locale.translations}
        />
        <SiteFont family={queryData.content.siteFont.default} />
        <Meta meta={queryData.meta} />
        <Header data={queryData} homeLink={homeLink} />
        <Content />
        <VendorIntegrations />
        <Footer data={queryData} />
      </div>
    </PageContextProvider>
  )
}

export default withOAuth(PageWrapper)
