// @react
import React from 'react'
// @common
import { getLang } from '../../../../utils'
import Tracker from '../../../../utils/tracking'
// @vendor
import axios from 'axios'
import classNames from 'classnames'
import qs from 'qs'
import { createBrowserHistory } from 'history'
import t from 'typy'
// @HOC
import withConfig from '../../../HOC/config/HOC'
// @components
import Search from './Search'
import List from './List'
import Sidebar from './Sidebar'
import { JobsContext } from './context'
import './job-list.css'
import { jobBoardConfig } from '../config'
const history = createBrowserHistory()

/**
 * class JobBoard
 */
class JobBoard extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props)

    // Sanitize the theme colors and later attach them to state (to pass down with context)
    this.style = props.style

    const parsedQ = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    const activeKeyword = parsedQ.search || -1
    const activeLocation = parsedQ.location || -1
    this.initialLoad = true

    this.state = {
      style: this.style || {},
      query: '',
      jobs: {},
      name: props.boardName,
      hideGeneralJobForm: false,
      filter: {
        locations: [],
        keywords: [],
        collapsed: true,
        toggleCollapsed: (toggleTo) => {
          const newVal = toggleTo !== undefined ? toggleTo : !this.state.filter.collapsed

          this.setState({
            ...this.state,
            filter: {
              ...this.state.filter,
              collapsed: newVal
            }
          })
        },
        activeLocation: activeLocation,
        activeKeyword: activeKeyword,
        active: -1,
        resetQuery: (callback) => {
          callback && callback()
        },
        setActive: (filter, value, callback) => {
          let query = window.location.search
          let setLocationTo = this.state.filter.activeLocation
          let setKeywordTo = this.state.filter.activeKeyword

          switch (filter) {
            case 'location':
              setLocationTo = !value || this.state.filter.activeLocation === value ? -1 : value

              query = setLocationTo !== -1 ? `location=${value}&page=0` : 'location=&page=0'

              // Track selected city
              if (value) {
                Tracker('UCS010')(value)
              }
              break
            case 'keyword':
              setKeywordTo = !value || this.state.filter.activeKeyword === value ? -1 : value

              query = setKeywordTo !== -1 ? `search=${value}&page=0` : 'search=&page=0'

              // Track selected city
              if (value) {
                Tracker('UCS009')(value)
              }
              break
            default:
              break
          }

          this.setState(
            {
              ...this.state,
              query: this.queryMerger(query),
              filter: {
                ...this.state.filter,
                activeKeyword: setKeywordTo,
                activeLocation: setLocationTo
              }
            },
            // callback
            () => {
              this.fetchJobs(this.state.query)
              callback && callback()
            }
          )
        }
      },
      sortBy: (column) => {
        const currQ = qs.parse(this.state.query, { ignoreQueryPrefix: true })
        const direction =
          currQ.sort_field === column ? (currQ.sort_direction === 'asc' ? 'desc' : 'asc') : 'asc'
        const query = `sort_field=${column}&sort_direction=${direction}`

        this.setState(
          {
            query: this.queryMerger(query)
          },
          () => {
            this.fetchJobs(this.state.query)
          }
        )
      },
      goToPage: (page) => {
        const query = `page=${page}`
        this.setState(
          {
            query: this.queryMerger(query)
          },
          () => {
            this.fetchJobs(this.state.query)
          }
        )
      },
      applyFormURL: this.getJobFormGeneralURL(props.boardName)
    }
  }

  /**
   *
   */
  componentWillMount() {
    const currQ = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    const search = qs.stringify({
      ...{ job_board_id: this.props.boardId },
      ...{ lang_code: getLang() },
      ...currQ
    })
    this.fetchJobs(`?${search}`)
  }

  /**
   *
   * @param query
   * @returns {string}
   */
  queryMerger(query = '') {
    const currQ = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    const newQ = qs.parse(query, { ignoreQueryPrefix: true })

    // ugh
    if (currQ.hasOwnProperty('search') && newQ.hasOwnProperty('category')) {
      delete currQ['search']
    }
    if (currQ.hasOwnProperty('category') && newQ.hasOwnProperty('search')) {
      delete currQ['category']
    }

    const merged = qs.stringify({
      ...{ job_board_id: this.props.boardId },
      ...{ lang_code: getLang() },
      ...currQ,
      ...newQ
    })

    return `?${merged}`
  }

  /**
   *
   * @param query
   */
  fetchJobs(query = '') {
    if (!this.initialLoad) {
      history.push(window.location.pathname + query)
    }

    axios
      .get(`${this.props.config.api_url}/get/jobs${query}&time=${Date.now()}`)
      .then((response) => {
        // Only update filters for first API endpoint fetch
        const filter = {
          ...this.state.filter,
          ...response.data.filters
        }

        this.setState({
          jobs: response.data,
          filter
        })
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            jobs: null,
            data: error.response.data
          })
          console.warn('Failed to fetch data.')
        } else {
          console.warn('Failed to fetch data. Is API down?')
          this.setState({
            jobs: null,
            data: null,
            response: 500
          })
        }
      })

    this.initialLoad = false
  }

  /**
   *
   * @returns {string}
   */
  getJobFormGeneralURL(boardId) {
    const lang = getLang()
    const base = 'https://portal.dynamicsats.com/Application/WebForm'
    const id = jobBoardConfig[boardId]['base'][lang]
    return `${base}/${id}`
  }

  /**
   *
   * @returns {null|*}
   */
  render() {
    const { jobs } = this.state

    if (!jobs) {
      return null
    }

    return (
      <JobsContext.Provider
        value={{
          ...this.state,
          buttonStyle: this.props.buttonStyle
        }}
      >
        <div
          className={classNames({
            'page--jobs': true,
            [`page--jobs-${this.props.boardName.toLowerCase()}`]: true
          })}
        >
          <div
            className="jobs wrapper"
            style={{ color: t(this.style, 'highlightColor').safeObject }}
          >
            <Search />
            <section className="jobs__content">
              <Sidebar />
              <List />
            </section>
          </div>
        </div>
      </JobsContext.Provider>
    )
  }
}

JobBoard.contextType = JobsContext

export default withConfig(JobBoard)
