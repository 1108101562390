import React from 'react'

import 'components/_paragraphs/Hero/Video/video.css'

const Video = ({ data }) => {
  if (!data) {
    return null
  }

  const { desktop, mobile } = data
  let videos = []

  if (desktop) {
    const dKeys = Object.keys(desktop)
    videos.push(
      <video
        key={1}
        className="slider__video slider__video--desktop"
        autoPlay
        loop
        muted
        playsInline
      >
        {dKeys.map((key) => (
          <source key={key} src={desktop[key]} type={`video/${key}`} />
        ))}
        Sorry, your browser doesn't support embedded videos.
      </video>
    )
  }

  if (mobile) {
    const mKeys = Object.keys(mobile)
    videos.push(
      <video
        key={2}
        className="slider__video slider__video--mobile"
        autoPlay
        loop
        muted
        playsInline
      >
        {mKeys.map((key) => (
          <source key={key} src={mobile[key]} type={`video/${key}`} />
        ))}
        Sorry, your browser doesn't support embedded videos.
      </video>
    )
  }

  return <div className="slider__videos">{videos}</div>
}

export default Video
