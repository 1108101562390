import React from 'react'
import Mailchimp from 'components/_integrations/MailchimpForm/index'
import withConfig, { WithConfigProps } from 'components/HOC/config/HOC'

import './mailchimp.css'

interface IProps extends WithConfigProps {}

const MailchimpForm = ({ config }: IProps) => {
  // Switch through config portal title and if matching
  // render appropriate Mailchimp form component
  switch (config.title.toLowerCase()) {
    case 'beatconnect':
      return (
        <Mailchimp
          className="bc-mc-form"
          action="https://beatconnect.us10.list-manage.com/subscribe/post?u=e2bc683f4fce90dd0c9ed9f36&id=4c6aa28d30"
          fields={[
            {
              name: 'EMAIL',
              placeholder: 'Email',
              type: 'email',
              required: true
            }
          ]}
        />
      )
    default:
      return <></>
  }
}

// @ts-ignore
export default withConfig(MailchimpForm)
