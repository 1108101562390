import React from 'react'
import PropTypes from 'prop-types'
import t from 'typy'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import classnames from 'classnames'
import { getLang } from 'utils'
import { Strings } from 'components/_utility/Strings'
import InlineSVG from 'components/_ui/InlineSVG'
import ReactHtmlParser from 'react-html-parser'
import colors from 'constants/colors'
import mq from 'constants/mediaQueries'
import { ArticleGridContextConsumer } from 'components/_sections/Article/ArticleGrid/context'
import ImageCard from 'components/_sections/Article/ArticleGrid/ImageCard'

export const TextWrap = styled.div`
  display: flex;
  width: 100%;
  padding: 1em 1.35em;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;

  ${mq.to(mq.breakpoint.tablet)} {
    padding-left: 1em;
    padding-right: 1em;
  }

  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `};
`

export const Category = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9em;

  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `};
`

export const Title = styled.h3`
  margin: 0.4rem 0 0.5em;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.25;
  transition: color 0.3s ease;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;

  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `};
`

export const Teaser = styled.div`
  margin: 0;
  margin-bottom: 1.3em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  line-height: 1.25;
  overflow: hidden;

  p {
    margin: 0;
    font-size: 1em;
    overflow: hidden;
  }

  ul,
  ol {
    list-style: initial;
    padding-left: 1.25em;
  }
`

const ImageWrap = styled.div`
  width: 100%;
  flex-shrink: 0;

  ${mq.from(mq.breakpoint.tablet)} {
    max-width: 150px;
  }

  ${mq.from(900)} {
    max-width: 200px;
  }

  ${mq.from(mq.breakpoint.desktop)} {
    max-width: 320px;
  }
`

const Image = styled.div`
  position: relative;
  padding-bottom: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Footer = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 1em;
  font-weight: 600;

  .icon {
    margin-right: 0.3em;
    vertical-align: middle;
  }
`

export const Date = styled.span`
  margin-right: 1em;
`

export const Share = styled.span`
  margin-left: auto;
  transition: color 0.3s ease;

  ${(props) =>
    props.style.highlightColor &&
    `
    color: ${props.style.highlightColor};
  `};
`

export const SponsoredTag = styled.span`
  position: absolute;
  left: 0;
  top: 1rem;
  max-width: 90%;
  padding: 0.3em 0.7em;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
  color: ${colors.white};
`

export const ReadMore = styled.span`
  display: flex;
  align-items: center;
  margin-top: auto;
  text-transform: uppercase;
  font-size: 0.85em;
  font-weight: 600;

  .icon {
    margin-left: 1em;
    font-size: 0.8em;
  }
`

const Wrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  ${(props) =>
    props.style.alignment &&
    `
    text-align: ${props.style.alignment};
  `}

  ${(props) =>
    props.style.highlightColor &&
    !props.style.notClickable &&
    `
    &:hover {
      ${Title} {
        color: ${props.style.highlightColor};
      }
    }
  `}

  ${(props) =>
    props.style.tileBackground &&
    `
    background-color: ${props.style.tileBackground};
  `}

  ${(props) =>
    props.style.hasBorder &&
    `
    border: 1px solid ${colors.articleBorderGray};
  `}

  ${(props) =>
    props.style.sponsoredColor &&
    `
    ${Title} {
      color: ${props.style.sponsoredColor};
    }

    ${SponsoredTag} {
      background-color: ${props.style.sponsoredColor};
    }
  `}

  ${(props) =>
    props.style.hasBorder &&
    props.style.sponsoredColor &&
    `
    border-color: ${props.style.sponsoredColor};
  `}

  ${(props) =>
    props.size &&
    props.size === 'small' &&
    `
    ${ImageWrap} {
      max-width: 150px;

      ${mq.to(mq.breakpoint.tablet)} {
        max-width: 130px;
      }
    }

    ${Image} {
      ${mq.to(mq.breakpoint.tablet)} {
        height: 100%;
      }
    }

    ${TextWrap} {
      ${mq.from(mq.breakpoint.tablet)} {
        padding-top: 1em;
      }

      ${mq.from(mq.breakpoint.desktop)} {
        padding-top: 1.25em;
      }
    }

    ${mq.to(375)} {
      ${Share} {
        .icon {
          margin-right: 0;
        }

        span:last-child {
          display: none;
        }
      }
    }
  `}

  ${(props) =>
    props.size &&
    props.size === 'medium' &&
    `
    ${mq.from(mq.breakpoint.tablet)} {
      ${TextWrap} {
        padding-top: 1em;
      }
    }

    ${mq.from(mq.breakpoint.desktop)} {
      ${TextWrap} {
        padding-top: 2.5em;
      }

      ${ImageWrap} {
        max-width: 50%;
      }
    }

    ${mq.to(mq.breakpoint.tablet)} {
      flex-direction: column;

      ${Image} {
        padding-bottom: 59.42%;
      }

      ${TextWrap} {
        padding-top: 1em;
        padding-bottom: 1em;
      }
    }

    ${mq.to(480)} {
      ${Share} {
        .icon {
          margin-right: 0;
        }

        span:last-child {
          display: none;
        }
      }
    }

    ${mq.from(mq.breakpoint.tablet)} {
      ${Image} {
        height: 100%;
      }
    }
  `}

  ${(props) =>
    props.size &&
    props.size === 'big' &&
    `
    flex-direction: column;
    align-items: flex-start;

    ${TextWrap} {
      padding-top: 1.25em;
      padding-bottom: 1.25em;

      ${mq.to(mq.breakpoint.tablet)} {
        padding-top: 1em;
        padding-bottom: 1em;
      }
    }

    ${ImageWrap} {
      max-width: none;
    }

    ${Image} {
      padding-bottom: 59.42%;
    }
  `}
`

const Article = ({
  url,
  title,
  displayTitle,
  teaserText,
  category,
  categoryText,
  image,
  imageDesktop,
  imageMobile,
  target,
  isSponsored,
  sponsored,
  displayShare,
  displayDate,
  date,
  size,
  cta,
  type,
  alignment
}) => (
  <ArticleGridContextConsumer>
    {({ style, border, wrapAlignment, tileBackgroundColor, sponsoredLabel, toggleShareModal }) => {
      const lang = getLang()

      const { highlightColor, headingsColor, paragraphColor, sponsoredContentColor } = style

      const externalLink = type === 'external_link' || type === 'image_card'
      const notClickable = externalLink && (!cta || (!cta.uri && !cta.title))
      const isExternalCTA = externalLink && cta && cta.options && cta.options.isExternal === 'true'

      let optionalProps = {}
      if (!notClickable) {
        if (isExternalCTA) {
          optionalProps = {
            as: 'a',
            href: cta.uri,
            target: '_blank',
            rel: 'noreferrer noopener'
          }
        } else {
          optionalProps = {
            as: Link,
            to: (cta && cta.uri) || url
          }
        }
      }

      const articleIsSponsored = isSponsored || sponsored
      const articleDisplayDate = displayDate || (externalLink && date) || false
      const imageUrl = externalLink ? image : t(image, 'url').safeObject

      if (type === 'image_card') {
        return (
          <Wrap
            {...optionalProps}
            className={classnames({
              'article-grid__article': true,
              [`article-grid__article--${size}`]: size
            })}
            size={size}
            style={{
              notClickable: notClickable,
              tileBackground: tileBackgroundColor,
              highlightColor: highlightColor,
              sponsoredColor: articleIsSponsored && sponsoredContentColor,
              alignment: wrapAlignment,
              hasBorder: border
            }}
          >
            <ImageCard
              title={title}
              displayTitle={displayTitle}
              imageDesktop={imageDesktop}
              imageMobile={imageMobile}
              teaserText={teaserText}
              cta={cta}
              categoryText={categoryText}
              alignment={alignment}
              wrapAlignment={wrapAlignment}
              tileBackground={tileBackgroundColor}
              style={style}
            />
          </Wrap>
        )
      }

      return (
        <Wrap
          {...optionalProps}
          className={classnames({
            'article-grid__article': true,
            [`article-grid__article--${size}`]: size
          })}
          size={size}
          style={{
            notClickable: notClickable,
            tileBackground: tileBackgroundColor,
            highlightColor: highlightColor,
            sponsoredColor: articleIsSponsored && sponsoredContentColor,
            alignment: wrapAlignment,
            hasBorder: border
          }}
        >
          {image && (
            <ImageWrap className="article-grid__img-wrap">
              <Image
                className="article-grid__img"
                style={{
                  backgroundColor: !imageUrl && highlightColor
                }}
              >
                {imageUrl && <img src={imageUrl} alt={title} />}
                {articleIsSponsored && (
                  <SponsoredTag>
                    {sponsoredLabel || Strings.text.sponsored_content[lang]}
                  </SponsoredTag>
                )}
              </Image>
            </ImageWrap>
          )}
          <TextWrap color={paragraphColor}>
            <Category color={highlightColor}>{categoryText || category || '\u00A0'}</Category>
            <Title className="article-grid__title" color={headingsColor}>
              {title}
            </Title>
            {teaserText && size !== 'small' && (
              <Teaser className="article-grid__teaser">{ReactHtmlParser(teaserText)}</Teaser>
            )}
            {externalLink && (articleDisplayDate || cta.title) && (
              <Footer>
                {articleDisplayDate && <Date style={{ color: headingsColor }}>{date}</Date>}
                {cta.title && (
                  <ReadMore style={{ color: highlightColor }}>
                    {cta.title}
                    <InlineSVG icon="arrow-right" />
                  </ReadMore>
                )}
              </Footer>
            )}
            {!externalLink && (articleDisplayDate || displayShare) && (
              <Footer>
                {articleDisplayDate && <Date style={{ color: headingsColor }}>{date}</Date>}
                {displayShare && (
                  <Share
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      toggleShareModal({
                        shareUrl: `${window.location.origin}${url}`,
                        shareTitle: title,
                        iconColor: headingsColor,
                        iconHoverColor: highlightColor
                      })
                    }}
                    style={{ highlightColor: highlightColor }}
                  >
                    <InlineSVG icon="share" />
                    <span>{Strings.text.share[lang]}</span>
                  </Share>
                )}
              </Footer>
            )}
          </TextWrap>
        </Wrap>
      )
    }}
  </ArticleGridContextConsumer>
)

Article.propTypes = {
  size: PropTypes.oneOf(['big', 'medium', 'small'])
}

export default Article
