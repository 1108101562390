import React from 'react'
import t from 'typy'
import Article from 'components/_sections/Article/ArticleGrid/Article'

const Theme3 = ({ articles, layoutArticles }) => {
  const leftArticle = articles[0]
  const rightArticles = articles.slice(1, layoutArticles)
  return (
    <div className="article-grid article-grid--theme_3">
      <div className="article-grid__col">
        <Article {...leftArticle.content} size="big" />
      </div>
      <div className="article-grid__col">
        {rightArticles.map((article, idx) => (
          <Article {...article.content} key={idx} size="small" />
        ))}
      </div>
    </div>
  )
}

export default Theme3
