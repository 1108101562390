import React from 'react'
import t from 'typy'
import classnames from 'classnames'
import LinkRouter from 'components/_utility/LinkRouter'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'

import 'components/_ui/Button/RoundedCorners/rounded-corners.css'
import InlineSVG from '../../InlineSVG'

const ASSETS_PATH = process.env.ASSETS_PATH

const Button = (props) => (
  <ThemeConsumer>
    {(theme) => {
      let button
      let target
      let to = props.href

      const cls = classnames({
        btn: true,
        'btn--rounded': true,
        'btn--hover': props.showHoverState,
        'btn--transparent': props.transparent,
        'btn--arrow-left': props.arrowLeft,
        'btn--full': props.full,
        [`${props.className}`]: props.className
      })

      if (
        props.target === '_blank' ||
        (props.options && (props.options['isExternal'] || props.options['type'] === 'file'))
      ) {
        target = 'external'
        if (props.options && props.options['type'] === 'file') {
          to = ASSETS_PATH + props.href
        } else {
          to = props.href
        }
      }

      // Button specific configuration
      const granularStyles = t(props, `granularStyles["Rounded Corners"]`).safeObject || {}

      // Allow props to override primary/secondary color
      // otherwise - use page section theme colors
      let primaryColor =
        granularStyles.highlightColor ||
        props.highlightColor ||
        props.primaryColor ||
        (theme && theme.headingsColor)

      if (props.href) {
        let options = {
          to: to,
          class: cls,
          target: target
        }

        if (props.href.indexOf('#') > -1) {
          options.href = props.href.substr(props.href.indexOf('#'))
        }

        button = (
          <LinkRouter
            {...options}
            style={{
              backgroundColor: primaryColor,
              backgroundImage: theme && theme.gradient,
              ...props.style
            }}
          >
            <span className="btn__label">{props.children}</span>
          </LinkRouter>
        )
      } else {
        const options = {
          className: cls,
          onClick: () => {
            props.onClick && props.onClick()
            props.handler && props.handler()
          }
        }

        button = (
          <button
            {...options}
            style={{
              backgroundColor: primaryColor,
              backgroundImage: theme && theme.gradient,
              ...props.style
            }}
          >
            <span className="btn__label">{props.children}</span>
          </button>
        )
      }

      return button
    }}
  </ThemeConsumer>
)

export default Button
