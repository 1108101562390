// This context is mainly used to be able to pass global settings
// down to smaller and deeply nested components instead of prop drilling
// across multilpe levels and not knowing whether those props have been
// modified by some of the components that pass them through

import React from 'react'
import { ButtonStyle } from 'ts/DTO/content.config'

type PageType = {
  pagePalette: any
  pageButtonStyle: ButtonStyle
}

const Context = React.createContext<PageType>({
  pagePalette: {},
  pageButtonStyle: null
})

export const PageContextProvider = Context.Provider
export const PageContextConsumer = Context.Consumer
