// @react
import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
// @common
import { getLang } from 'utils'
import { Strings } from 'components/_utility/Strings'
import qs from 'qs'
// @design
import './job.css'
// @components
import Hero from 'components/_paragraphs/Hero/Small'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import Loader from 'components/_ui/Loader'
import { CMS_BUTTON_TYPE } from 'components/_ui/Button'
import { SanitizedTheme, themeSanitizer } from 'components/_sections/Page/Section/ThemeContext'
import ArticleShareIcons from 'components/_sections/Article/ArticleShareIcons'
// @common
import { jobBoardConfig } from '../config'
import * as SC from './style'
import { ButtonStyle } from 'ts/DTO/content.config'
import { JobSingleApiResponse } from 'ts/DTO'
import { BoardId } from 'ts/DTO/job'
import { ActiveButtonColors, ThemeColors } from '../../../../ts/DTO/content.colorpalette'

/**
 *
 * @param colorPalette
 * @param buttonStyle
 * @param inverted
 */
const buttonStyleChooser = ({
  colorPalette,
  buttonStyle,
  inverted
}: {
  colorPalette: SanitizedTheme<ThemeColors & ActiveButtonColors>
  buttonStyle: ButtonStyle
  inverted: boolean
}) => {
  if (buttonStyle === 'DRAKKAR') {
    return {}
  }
  const { headingsColor, highlightColor, paragraphColor, activeColor } = colorPalette

  if (!inverted) {
    const selButtonStyle = {
      [CMS_BUTTON_TYPE.drakkar]: {
        style: {
          backgroundColor: highlightColor,
          hoverBackgroundColor: headingsColor
        }
      },
      [CMS_BUTTON_TYPE.rounded]: {
        style: {
          color: activeColor,
          hoverColor: highlightColor,
          backgroundColor: highlightColor,
          hoverBackgroundColor: activeColor
        }
      }
    }

    // case non inverted
    if (buttonStyle && selButtonStyle.hasOwnProperty(buttonStyle)) {
      const mainStyle = selButtonStyle[buttonStyle]
      return {
        ...mainStyle,
        buttonStyle,
        granularStyles: {
          [CMS_BUTTON_TYPE.drakkarDigital]: {
            color: highlightColor,
            borderColor: highlightColor,
            hoverColor: activeColor,
            hoverBackgroundColor: highlightColor
          }
        }
      }
    }
    return {}
  }

  // inverted
  const selButtonStyle = {
    [CMS_BUTTON_TYPE.drakkar]: {
      style: {
        color: highlightColor,
        hoverColor: paragraphColor
      }
    },
    [CMS_BUTTON_TYPE.rounded]: {
      style: {
        color: highlightColor,
        hoverColor: activeColor,
        backgroundColor: activeColor,
        hoverBackgroundColor: highlightColor
      }
    }
  }

  if (buttonStyle && selButtonStyle.hasOwnProperty(buttonStyle)) {
    const mainStyle = selButtonStyle[buttonStyle]
    return {
      ...mainStyle,
      buttonStyle,
      granularStyles: {
        [CMS_BUTTON_TYPE.drakkarDigital]: {
          hoverColor: highlightColor
        }
      }
    }
  }
  return {}
}

type IProps = {
  data: JobSingleApiResponse
}

/**
 *
 */
const Job = ({ data }: IProps) => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const lang = getLang()
  const history = useHistory()

  /*******************************************************
   * CONST
   *******************************************************/
  const logoColor = null
  const job = data.content.job
  const buttonStyle = data.content.styleOptions.buttonStyle

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  /**
   *
   */
  const jobSidebarTitle = (jobName: string): string => {
    const dynText = {
      'drakkar digital': Strings.drakkardigital.jobSidebarTitle[lang],
      selekktus: Strings.selekktus.jobSidebarTitle[lang]
    }
    if (dynText.hasOwnProperty(jobName)) {
      // @ts-ignore
      return String(dynText[jobName])
    }

    return String(Strings.jobs.title[lang] || '')
  }

  /**
   * @todo fix this
   */
  const jobSidebarDesc = (jobName: string): ReactElement => {
    const dynText = {
      'drakkar digital': <HtmlParser string={Strings.drakkardigital.jobSidebarDesc[lang]} />,
      selekktus: <HtmlParser string={Strings.selekktus.jobSidebarDesc[lang]} />
    }
    if (dynText.hasOwnProperty(jobName)) {
      // @ts-ignore
      return dynText[jobName]
    }
    return <p>{Strings.jobs.about[lang]}</p>
  }

  const getJobFormURL = () => {
    // Grab the query params
    const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    const queryString = qs.stringify(queryParams)
    // And pass them along to job URLs
    return `${job.application_url[0].uri}${queryString}`
  }

  const getJobFormGeneralURL = (boardId: BoardId): string => {
    if (!boardId) return ''

    const lang = getLang()
    const base = 'https://portal.dynamicsats.com/Application/WebForm'
    const id = jobBoardConfig[boardId].base[lang]
    const baseId = `${base}/${id}`
    const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    const queryString = qs.stringify(queryParams)
    return `${baseId}?${queryString}`
  }

  if (job) {
    /*******************************************************
     * JOB CONSTANTS
     *******************************************************/
    const { title, description, location } = job
    const share = {
      url: window.location.href,
      title: title || ''
    }
    const boardId = job.job_board_id.name
    const boardIdNiceName = job.job_board_id.name.toLowerCase()
    const jobType = job.job_type
    const jobFormGeneralURL = getJobFormGeneralURL(boardId)
    const jobFormURL = getJobFormURL()
    const headerTheme = jobBoardConfig[boardId].theme

    /*******************************************************
     * COLORS / BUTTON THEMES
     *******************************************************/
    const colorPalette = data.content.colorPalette
    const combinedPalette: ThemeColors & ActiveButtonColors = {
      ...colorPalette.themeColors,
      ...colorPalette.styleColors
    }
    const sanitizedColorPalette = themeSanitizer<ThemeColors & ActiveButtonColors>(combinedPalette)
    const { headingsColor, paragraphColor, activeColor } = sanitizedColorPalette
    const bStyle = buttonStyleChooser({
      colorPalette: sanitizedColorPalette,
      buttonStyle,
      inverted: false
    })
    const bStyleInverted = buttonStyleChooser({
      colorPalette: sanitizedColorPalette,
      buttonStyle,
      inverted: true
    })

    /*******************************************************
     * STRINGS
     *******************************************************/
    const email =
      lang === 'en'
        ? {
            subject: `This ${title} job may interest you!`,
            body: `I just came across this ${title} job currently available and thought it might interest you. Take a look! %0D%0A %0D%0A ${share.url}`
          }
        : {
            subject: `Ce poste de ${title} pourrait t'intéresser!`,
            body: `Je viens de voir ce poste de ${title} offert en ce moment et j’ai pensé qu’il pourrait t’intéresser. À y jeter un coup d’œil! %0D%0A %0D%0A ${share.url}`
          }

    /*******************************************************
     * RENDER
     *******************************************************/
    return (
      <div className="page--jobs">
        <Hero
          title={title}
          style={{
            ...combinedPalette,
            // @ts-ignore
            logoColor
          }}
          theme={headerTheme}
          buttonStyle={buttonStyle}
          cta={
            <div className="btn-wrapper">
              <SC.StyledButton
                {...bStyleInverted}
                className="btn--full"
                primaryColor={activeColor}
                href={jobFormURL}
              >
                {Strings.text.apply[lang]}
              </SC.StyledButton>

              <SC.StyledTextLink activeColor={paragraphColor}>
                <a
                  href={jobFormGeneralURL}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ color: activeColor }}
                >
                  {Strings.jobs.not_ready[lang]}
                </a>
              </SC.StyledTextLink>
            </div>
          }
        />
        <div className="jobs wrapper">
          <section className="jobs__content job">
            {/* Header back link */}
            <header className="btn--back">
              <SC.StyledButton
                to="/"
                onClick={() => {
                  history.goBack()
                }}
                {...bStyle}
                buttonStyle={buttonStyle}
                // arrowLeft
                showHoverState
              >
                {Strings.jobs.back[lang]}
              </SC.StyledButton>
            </header>

            {/* Job Description */}
            <section className="job-section job-section--description">
              <header style={{ backgroundColor: headingsColor }}>{Strings.jobs.desc[lang]}</header>
              <div className="jobDescription" style={{ color: paragraphColor }}>
                <HtmlParser string={description} theme={sanitizedColorPalette} />
              </div>

              {/* Job Description bottom Apply button */}
              <div className="btn-wrapper btn-wrapper--apply">
                <SC.StyledButton href={jobFormURL} {...bStyle} full showHoverState>
                  {Strings.text.apply[lang]}
                </SC.StyledButton>
                <SC.StyledTextLink activeColor={headingsColor}>
                  <a
                    href={jobFormGeneralURL}
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ color: paragraphColor }}
                  >
                    {Strings.jobs.not_ready[lang]}
                  </a>
                </SC.StyledTextLink>
              </div>
            </section>

            {/* Sidebar column: Summary */}
            <section className="job-section job-section--summary">
              <header style={{ backgroundColor: headingsColor }}>
                {Strings.jobs.summary[lang]}
              </header>
              <p>
                <span style={{ color: paragraphColor }}>
                  {Strings.text.location[lang]}: {location}
                </span>
                <span style={{ color: paragraphColor }}>
                  {Strings.text.type[lang]}: {jobType}
                </span>
              </p>
            </section>

            {/* Sidebar column: Share */}
            <section className="job-section">
              <header style={{ backgroundColor: headingsColor }}>{Strings.jobs.share[lang]}</header>
              <ArticleShareIcons title={window.location.href} email={email} noWrapper={true} />
            </section>

            {/* Sidebar column: Job description */}
            <section className="job-section job-section--about">
              <header style={{ backgroundColor: headingsColor }}>
                {jobSidebarTitle(boardIdNiceName)}
              </header>
              <div style={{ color: paragraphColor }}>{jobSidebarDesc(boardIdNiceName)}</div>
              <SC.StyledButton {...bStyle} href={lang === 'en' ? '/en/about-us' : '/fr/a-propos'}>
                {Strings.text.learnMore[lang]}
              </SC.StyledButton>
            </section>
          </section>
        </div>
      </div>
    )
  }

  /*******************************************************
   * LOADER
   * @todo why isn't this caught by main loader ?
   *******************************************************/
  return (
    <section id="content-loader-wrapper" className="page-section page-section--border-bottom">
      <div className="page-section__inner min-height">
        <div className="loading header__logo mini">
          <Loader />
        </div>
      </div>
    </section>
  )
}

export default Job
