// @react
import React from 'react'
import Select from 'react-select'
// @common
import { getLang } from '../../../../../utils'
// @components
import { Strings } from '../../../../_utility/Strings'
import { JobsContext } from '../context'
// @style
import '../../../../_integrations/ATS/Form/form.css'
import './search.css'

const Search = () => (
  <JobsContext.Consumer>
    {({ style, jobs, name, filter, query }) => {
      const lang = getLang()

      const { labels, results } = jobs

      if (!results) {
        return null
      }

      const {
        keywords,
        activeKeyword,
        locations,
        activeLocation,
        setActive,
        collapsed,
        toggleCollapsed
      } = filter

      const keywordValue =
        activeKeyword !== -1
          ? {
              value: activeKeyword,
              label: activeKeyword
            }
          : null

      const locationValue =
        activeLocation !== -1
          ? {
              value: activeLocation,
              label: activeLocation
            }
          : null

      return (
        <form className="jobs__search ats-form" style={{ color: style.headingsColor }}>
          <Select
            className="select"
            classNamePrefix="select"
            name="searchString"
            placeholder={labels.keywords}
            value={keywordValue}
            onChange={(selected) => {
              setActive('keyword', selected.value)
            }}
            options={
              (keywords &&
                [
                  { value: null, name: Strings.text.all[lang] }, // Prepend 'All'
                  ...keywords
                ].map((keyword) => {
                  if (keyword.value === null) {
                    // All
                    return {
                      value: null,
                      label: keyword.name
                    }
                  }
                  return {
                    value: keyword.name,
                    label: keyword.name
                  }
                })) ||
              []
            }
          />
          <Select
            className="select"
            classNamePrefix="select"
            name="searchLocation"
            placeholder={labels.location}
            value={locationValue}
            onChange={(selected) => {
              setActive('location', selected.value, () => {
                if (selected.index > 4 && collapsed) {
                  toggleCollapsed(false)
                }
              })
            }}
            options={
              (locations &&
                [
                  { value: null, name: Strings.text.all[lang] }, // Prepend 'All'
                  ...locations // And all the other locations
                ].map((location, index) => {
                  if (location.value === null) {
                    // All
                    return {
                      value: location.value,
                      label: location.name
                    }
                  }
                  return {
                    index: index,
                    value: location.name,
                    label: location.name
                  }
                })) ||
              []
            }
          />
        </form>
      )
    }}
  </JobsContext.Consumer>
)

export default Search
