import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { themeSanitizer } from 'components/_sections/Page/Section/ThemeContext'
import mq from 'constants/mediaQueries'

const Wrap = styled.div`
  padding-top: 2em;
  padding-bottom: 2em;
`
export interface TitlePropType {
  styles: {
    color: string
  }
}
const Title = styled.h3`
  margin: 0;
  font-size: 1.3em;
  color: currentColor;
  transition: color 0.3s ease;

  ${(props: TitlePropType) =>
    props.styles.color &&
    `
    color: ${props.styles.color};
  `} ${mq.from(mq.breakpoint.tablet)} {
    font-size: 1.5em;
  }
`
export interface ArticlePropType {
  style: {
    hoverColor: string
  }
}
const Article = styled(Link)`
  display: block;
  padding: 1em;
  border-left: 10px solid;
  color: currentColor;
  background-color: #ecefee;

  ${(props: ArticlePropType) =>
    props.style.hoverColor &&
    `
    border-color: ${props.style.hoverColor};

    &:hover,
    &:hover ${Title} {
      color: ${props.style.hoverColor};
    }
  `} + a {
    margin-top: 0.7em;
  }
`

const Category = styled.strong`
  display: inline-block;
  margin-bottom: 0.3em;
  font-weight: 600;
`
export type FixMeLater = any
export interface IProps {
  articles: FixMeLater
  highlightColor?: string
  paragraphColor: string
}

const ArticleRelated: React.FunctionComponent<IProps> = ({
  articles,
  highlightColor,
  paragraphColor
}) => {
  if (!articles) {
    return null
  }

  const nodes = articles.map((article: FixMeLater, idx: FixMeLater) => {
    const colors: FixMeLater = !highlightColor
      ? themeSanitizer(article.colorPalette.styleColors) || {}
      : { highlightColor: highlightColor }

    return (
      <Article to={article.url} key={idx} style={{ hoverColor: colors.highlightColor }}>
        <Category style={{ color: colors.highlightColor }}>{article.category}</Category>
        <Title //@ts-ignore
          styles={{ color: themeSanitizer({ paragraphColor: paragraphColor })!.paragraphColor }}
        >
          {article.title}
        </Title>
      </Article>
    )
  })

  return <Wrap className="wrapper">{nodes}</Wrap>
}

export default ArticleRelated
