import React, { useEffect, useState } from 'react'
import InlineSVG from 'components/_ui/InlineSVG'
import * as SC from './style'
import { Locale, Translations } from 'ts/DTO/meta'

/*******************************************************
 * TYPE
 *******************************************************/
type PropsType = {
  locale: Locale
}

interface IState {
  dropdown?: boolean
  activeLang: string
}

/**
 *
 * @param locale
 * @constructor
 */
const LanguagePicker = ({ locale }: PropsType) => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const [state, setState] = useState<IState>({
    activeLang: locale.current_lang
  })

  /*******************************************************
   * LIFESTYLE HOOKS
   *******************************************************/
  useEffect(() => {
    if (state.dropdown) {
      window.addEventListener('click', onWindowClick, false)
    }
  }, [state.dropdown])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const showDropdown = () => {
    setState({
      ...state,
      dropdown: !state.dropdown
    })
  }

  const onWindowClick = () => {
    setState({
      ...state,
      dropdown: !state.dropdown
    })
    window.removeEventListener('click', onWindowClick)
  }

  const onLanguageClick = () => {
    document.cookie = `drakkar_language_pref=${locale.link_lang}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`
  }

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  const translations = Object.keys(locale.translations)
  const languages = translations.map((lang, idx: number) => {
    if (lang !== locale.current_lang) {
      return (
        <SC.Language
          key={idx}
          href={locale.translations[lang as keyof Translations]}
          onClick={onLanguageClick}
        >
          {lang}
        </SC.Language>
      )
    }
    return null
  })

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.Wrap onClick={showDropdown} active={state.dropdown}>
      <SC.Language>
        {locale.current_lang}
        <InlineSVG icon="caret-bottom" />
      </SC.Language>
      <SC.Dropdown show={state.dropdown}>{languages}</SC.Dropdown>
    </SC.Wrap>
  )
}

export default LanguagePicker
