import React from 'react'
export type FixMeLater = any
export interface WithConfigProps {
  config: configProps
}

export interface configProps {
  title: string
  api_url: string
  client_id: string
  client_secret: string
  gtm_id: string
  ga?: boolean
  ga_ua_id?: string
  logo_url?: string
  defaultLanguage: string
  mailchimpPopup?: boolean
  mailchimpActionUrl?: string
}

/**
 *
 * @param WrappedComponent
 */
const withConfig = <P extends WithConfigProps>(WrappedComponent: React.ComponentType<P>) => {
  class WithConfig extends React.Component<P & WithConfigProps> {
    config: {}
    constructor(props: FixMeLater) {
      super(props)
      // Get session state
      this.config = this.getConfig()
    }

    getConfig() {
      let thisConfig = {}
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // load config directly from file
        const multisiteConfig = require('../../../../multisite.json')
        const multisiteConfigDev = require('../../../../multisite.dev.json')
        const multisiteConfigFinal = {
          ...multisiteConfig,
          ...multisiteConfigDev
        }
        const host = window.location.hostname
        thisConfig = multisiteConfigFinal['development'][host]
      } else {
        // read config via express from window object
        thisConfig = window.thisConfig
      }
      return thisConfig
    }

    render() {
      return <WrappedComponent {...(this.props as P)} config={this.config} />
    }
  }

  return WithConfig
}

export default withConfig
