import { ParagraphReference } from './DTO/content.fields'
import {
  ArticleTaxonomyReferencePara,
  ButtonPara,
  FormPara,
  GalleryPara,
  HtmlPara,
  IconAndTextGroupPara,
  ImageAndTextCtaDrawerPara,
  ImageAndTextCtaPara,
  ImagePara,
  ImageWithTitlePara,
  InspirationBannerPara,
  JobBoardPara,
  LogoGroupPara,
  MapPara,
  TextBlockPara,
  TwoColumnsImageAndTextPara,
  VectorItemPara
} from './DTO/paragraphs'
import SocialIcons from '../components/_global/Header/TopBar/SocialIcons'
import { IconNameType } from '../components/_ui/InlineSVG'

export const isAccordionGroupPara = (
  content: ParagraphReference
): content is TwoColumnsImageAndTextPara => {
  return content.type === 'accordion_group'
}

export const isTwoColumnsImageAndTextPara = (
  content: ParagraphReference
): content is TwoColumnsImageAndTextPara => {
  return content.type === '2_columns_image_and_text'
}

export const isButtonPara = (content: ParagraphReference): content is ButtonPara => {
  return content.type === 'button'
}

export const isFormPara = (content: ParagraphReference): content is FormPara => {
  return content.type === 'form'
}

export const isGalleryPara = (content: ParagraphReference): content is GalleryPara => {
  return content.type === 'gallery'
}

export const isIconAndTextGroupPara = (
  content: ParagraphReference
): content is IconAndTextGroupPara => {
  return content.type === 'icon_and_text_group'
}

export const isImagePara = (content: ParagraphReference): content is ImagePara => {
  return content.type === 'image'
}

export const isImageAndTextCtaPara = (
  content: ParagraphReference
): content is ImageAndTextCtaPara => {
  return content.type === 'image_and_text_cta'
}

export const isImageAndTextCtaDrawerPara = (
  content: ParagraphReference
): content is ImageAndTextCtaDrawerPara => {
  return content.type === 'image_and_text_cta_drawer'
}

export const isInspirationBannerPara = (
  content: ParagraphReference
): content is InspirationBannerPara => {
  return content.type === 'inspiration_banner'
}

export const isLogoGroupPara = (content: ParagraphReference): content is LogoGroupPara => {
  return content.type === 'logo_group'
}

export const isMapPara = (content: ParagraphReference): content is MapPara => {
  return content.type === 'map'
}

export const isTextBlockPara = (content: ParagraphReference): content is TextBlockPara => {
  return content.type === 'text_block'
}

export const isVectorItemPara = (content: ParagraphReference): content is VectorItemPara => {
  return content.type === 'vector_item'
}

export const isJobListingPara = (content: ParagraphReference): content is JobBoardPara => {
  return content.type === 'job_board'
}

export const isHtmlPara = (content: ParagraphReference): content is HtmlPara => {
  return content.type === 'html'
}

export const isArticleTaxonomyReferencePara = (
  content: ParagraphReference
): content is ArticleTaxonomyReferencePara => {
  return content.type === 'article_tax_reference'
}

export const isImageWithTitlePara = (
  content: ParagraphReference
): content is ImageWithTitlePara => {
  return content.type === 'image_with_title'
}
export const isSocialIconIndex = (
  content: IconNameType,
  index: string
): content is keyof typeof SocialIcons => {
  return !!content
}

export const isOfType = <T>(
  varToBeChecked: any,
  propertyToCheckFor: keyof T,
  keyToBeChecked: T[keyof T]
): varToBeChecked is T => (varToBeChecked as T)[propertyToCheckFor] === keyToBeChecked

type Common<T1, T2> = Pick<T1 | T2, Extract<keyof T1, keyof T2>>
