// @react
import React from 'react'
// @design
import styled from 'styled-components'
import classnames from 'classnames'
import './middle.css'
// @common
import LinkRouter from 'components/_utility/LinkRouter/index'
import Tracker from 'utils/tracking'
// @components
import MailchimpForm from './MailchimpForm'
import { FooterColorsType } from 'components/_global/Footer'
// @types
import { ColorNamesOf, FooterColors } from 'ts/DTO/content.colorpalette'
import { MenuSectionType } from 'ts/DTO/menu'

/*******************************************************
 * STYLE
 *******************************************************/
const Wrap = styled.div<ColorNamesOf<FooterColors>>`
  ${(props) =>
    props.fontColorActive &&
    `
    a:hover {
      color: ${props.fontColorActive};
    }
  `}
`

/*******************************************************
 * TYPES
 *******************************************************/
export type FixMeLater = any

// export interface ChildType {
//   hasTranslation: boolean
//   name: string
//   target: string
//   url_str: string
// }

interface SubNavPropType {
  trackClicks: boolean
  title: boolean
  items: MenuSectionType[]
}

interface MiddlePropsType {
  colors: FooterColorsType
  menu: MenuSectionType[]
}

const noLinkStyle = { style: { pointerEvents: 'none' } }

/**
 *
 * @param props
 * @constructor
 */
const Middle = (props: MiddlePropsType) => {
  const navList = props.menu.map((item, idx) => {
    const labelOnly = !item.url_str
    const titleLink = labelOnly ? (
      item.name
    ) : (
      <LinkRouter to={item.url_str} name={item.name} target={item.target} />
    )

    const style: FixMeLater = item.url_str === 'nolink' ? noLinkStyle : null

    const title: FixMeLater =
      item.name === '<none>' ? null : (
        <strong
          className={classnames({
            footer__title: true,
            'footer__title--label-only': labelOnly
          })}
          {...style}
        >
          {titleLink}
        </strong>
      )

    return (
      <div
        key={idx}
        className={classnames({
          footer__col: true,
          'footer__col--condensed': props.menu.length > 4
        })}
      >
        {title}
        {item.children && (
          <SubNavList
            trackClicks={idx === 0} // Track clicks for the 'Partenaires' menu
            items={item.children}
            title={title}
          />
        )}
      </div>
    )
  })

  return (
    <Wrap
      className={classnames({
        footer__mid: true,
        [`footer__mid-columns-third`]: props.menu.length > 4,
        [`footer__mid-columns-half`]: props.menu.length === 4,
        [`footer__mid-columns-${props.menu.length}`]: true
      })}
      {...props.colors}
    >
      <div className="footer__row wrapper">{navList}</div>
    </Wrap>
  )
}

/**
 *
 * @param props
 * @constructor
 */
const SubNavList = (props: SubNavPropType) => {
  const cls = classnames({
    footer__title: !props.title
  })

  const menuListItems = props.items.map((item, idx) => {
    const style: FixMeLater = item.url_str === 'nolink' ? noLinkStyle : null

    if (item.name === '<mailchimp>') {
      return (
        <li key={idx}>
          {/*// @ts-ignore*/}
          <MailchimpForm />
        </li>
      )
    }

    return (
      <li key={idx} {...style}>
        <LinkRouter
          to={item.url_str}
          class={cls}
          name={item.name}
          target={item.target}
          onClick={() => Tracker('UCS007')(item.name)}
        />
      </li>
    )
  })

  return <ul>{menuListItems}</ul>
}

export default Middle
