import React from 'react'
import styled from 'styled-components'
import { Strings } from '../../../../../_utility/Strings'
import { getLang } from '../../../../../../utils'
import InlineSVG from '../../../../../_ui/InlineSVG'
import Button, { CMS_BUTTON_TYPE } from '../../../../../_ui/Button'
import Input from './Input'

import { JobsContext } from '../../context'
import './pagination.css'

const PageLink = styled.span`
  ${(props) => `
    && .icon {
      color: ${props.color}

      &:hover {
        border-color: currentColor;
      }
    }
  `};
`

const Pagination = () => (
  <JobsContext.Consumer>
    {({ style, jobs, goToPage, applyFormURL, hideGeneralJobForm, buttonStyle }) => {
      const lang = getLang()
      const { meta } = jobs

      if (!meta) {
        return null
      }

      const currentPage = meta.page
      const totalPages = meta.pages

      return (
        <footer className="jobs__pagination-wrap" style={{ color: style.headingsColor }}>
          <div className="jobs__pagination">
            <div className="jobs__pagination-buttons left">
              {currentPage > 0 ? (
                <div>
                  <PageLink color={style.highlightColor} onClick={() => goToPage(0)}>
                    <InlineSVG icon="caret-left-solid-line" />
                  </PageLink>
                  <PageLink color={style.highlightColor} onClick={() => goToPage(currentPage - 1)}>
                    <InlineSVG icon="caret-left-solid" />
                  </PageLink>
                </div>
              ) : null}
            </div>
            <div className="jobs__pagination-current">
              {Strings.text.page[lang]}
              <Input
                totalPages={totalPages}
                currentPage={currentPage}
                goToPage={goToPage}
                focusBorderColor={style.highlightColor}
              />
              of {totalPages}
            </div>
            <div className="jobs__pagination-buttons right">
              {currentPage + 1 <= totalPages - 1 ? (
                <div>
                  <PageLink color={style.highlightColor} onClick={() => goToPage(currentPage + 1)}>
                    <InlineSVG icon="caret-right-solid" />
                  </PageLink>
                  <PageLink color={style.highlightColor} onClick={() => goToPage(totalPages - 1)}>
                    <InlineSVG icon="caret-right-solid-line" />
                  </PageLink>
                </div>
              ) : null}
            </div>
          </div>

          {!hideGeneralJobForm && (
            <div className="submit-resume">
              <Button
                href={applyFormURL}
                className="btn--submit-resume"
                style={{
                  backgroundColor: style.headingsColor,
                  color: '#fff'
                }}
                buttonStyle={buttonStyle}
                granularStyles={{
                  [CMS_BUTTON_TYPE.drakkarDigital]: {
                    hoverColor: style.activeColor
                  }
                }}
              >
                {Strings.jobs.submit[lang]}
              </Button>
              <span className="submit-resume__without">{Strings.jobs.without[lang]}</span>
            </div>
          )}
        </footer>
      )
    }}
  </JobsContext.Consumer>
)

export default Pagination
