import React, { Component } from 'react'
import styled from 'styled-components'

const StyledInput = styled.input`
  ${(props) => `
    &&&:focus {
      border-color: ${props.focusBorderColor};
    }
  `};
`

class Input extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: props.currentPage + 1
    }

    this.onChange = this.onChange.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
  }

  onKeyPress(e) {
    const { totalPages, currentPage, goToPage } = this.props
    const newVal = parseInt(e.target.value, 10)

    if (
      e.key === 'Enter' &&
      !isNaN(newVal) &&
      newVal > 0 &&
      newVal <= totalPages &&
      newVal !== currentPage
    ) {
      goToPage(newVal - 1)
    }
  }

  onChange(e) {
    const { currentPage } = this.props
    const newVal = parseInt(e.target.value, 10)

    if (e.target.value === '') {
      this.setState({
        current: ''
      })
    } else {
      this.setState({
        current: !isNaN(newVal) ? newVal : currentPage + 1
      })
    }
  }

  // Reset the pager value
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.setState({
        current: this.props.currentPage + 1
      })
    }
  }

  render() {
    const { totalPages, currentPage, focusBorderColor } = this.props
    const { current } = this.state

    return (
      <StyledInput
        type="number"
        className="number"
        min="1"
        max={totalPages}
        onChange={(e) => this.onChange(e)}
        onKeyPress={(e) => this.onKeyPress(e)}
        placeholder={currentPage + 1}
        value={current}
        focusBorderColor={focusBorderColor}
      />
    )
  }
}

export default Input
