import React from 'react'
import Horizontal from './Horizontal'
import Centered from './Centered'
import { FixMeLater } from 'ts/types'
import { ButtonStyle } from 'ts/DTO/content.config'
import './image-text.css'

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */

export interface IProps {
  title: string
  subtitle: string
  image: string
  imageMobile: string
  theme: string
  paragraphColor: string
  highlightColor: string
  activeColor: string
  button: FixMeLater
  buttonStyle: ButtonStyle
  pagePalette: FixMeLater
}

const CTAImageText = (props: IProps) => {
  if (props.theme === 'horizontal_drawer') {
    return <Horizontal {...props} />
  } else {
    return <Centered {...props} />
  }
}

/**
 *
 * @type {{image: *, paragraphColor: *, highlightColor: *, activeColor: *, subtitle: *, theme: *, title: *}}
 */
// CTAImageText.propTypes = {
//   title: PropTypes.string,
//   subtitle: PropTypes.string,
//   image: PropTypes.string,
//   imageMobile: PropTypes.string,
//   theme: PropTypes.string,
//   paragraphColor: PropTypes.string,
//   highlightColor: PropTypes.string,
//   activeColor: PropTypes.string
// }

/**
 * @type {{theme: string}}
 */
CTAImageText.defaultProps = {
  theme: 'horizontal_drawer'
}

export default CTAImageText
