import React from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import { removeStringNewline } from 'utils'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'

import 'components/_paragraphs/Map/map.css'

const Map = (props) => {
  const center = {
    lat: parseFloat(props.lat) || 45.5017,
    lng: parseFloat(props.lng) || -73.5673
  }

  const fieldText = props.label
  const fieldCity = props.city
  const fieldSubtitle = props.address ? removeStringNewline(props.address) : false
  const fieldTollFree = props.tollFreeNumber
  const fieldPhone = props.phone
  const fieldFax = props.fax

  const renderMarkers = (map, maps) => {
    const marker = new maps.Marker({
      map,
      position: center
    })

    return marker
  }

  return (
    <ThemeConsumer>
      {(theme) => (
        <div
          className="map"
          style={{ backgroundColor: theme.activeColor, color: theme.headingsColor }}
        >
          <div className="wrapper">
            <div className="map__info wrapper" style={{ color: theme.paragraphColor }}>
              {fieldText && <span className="map__state">{fieldText}</span>}
              {fieldCity && (
                <h3 className="map__city" style={{ color: theme.headingsColor }}>
                  {fieldCity}
                </h3>
              )}
              {fieldSubtitle && (
                <div className="address">
                  <HtmlParser string={fieldSubtitle} />
                </div>
              )}
              <div className="map__phones">
                {fieldPhone && (
                  <span>
                    <strong style={{ color: theme.headingsColor }}>T</strong>
                    {fieldPhone}
                  </span>
                )}
                {fieldTollFree && (
                  <span>
                    <strong style={{ color: theme.headingsColor }}>SF</strong>
                    {fieldTollFree}
                  </span>
                )}
                {fieldFax && (
                  <span>
                    <strong style={{ color: theme.headingsColor }}>F</strong>
                    {fieldFax}
                  </span>
                )}
              </div>
            </div>
            <div className="map__map" style={{ borderColor: theme.activeColor || 'white' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyCU8ezl7_1Tiu_GIE1JT-DvXFTv-G5ai5M' }}
                defaultCenter={center}
                defaultZoom={props.zoom}
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
              />
            </div>
          </div>
        </div>
      )}
    </ThemeConsumer>
  )
}

Map.propTypes = {
  zoom: PropTypes.number,
  lat: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
  label: PropTypes.string,
  city: PropTypes.string,
  address: PropTypes.string,
  phone: PropTypes.string,
  tollFreeNumber: PropTypes.string,
  fax: PropTypes.string
}

Map.defaultProps = {
  zoom: 14
}

export default Map
