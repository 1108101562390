import React from 'react'
import PropTypes from 'prop-types'

const Image = (props) => {
  if (props.width && props.width === 'full_width') {
    return <img src={props.src} alt={props.alt} style={{ width: '100%' }} />
  } else if (props.width && props.width === 'small') {
    // Determine if the image is landscape / portrait
    // portrait image ends up being 50% of the container width
    return (
      <div className="text-block--padded">
        <div className="wrapper text--center">
          <img
            src={props.src}
            alt={props.alt}
            onLoad={(e) => {
              if (e.target.width < e.target.height) {
                e.target.style.maxWidth = '350px'
              }
            }}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div className="wrapper text--center">
        <img src={props.src} alt={props.alt} />
      </div>
    )
  }
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  width: PropTypes.string
}

export default Image
