import React from 'react'
import styled from 'styled-components'
import { Strings } from 'components/_utility/Strings'
import InlineSVG from 'components/_ui/InlineSVG'
import { getLang } from 'utils'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'

import 'components/_sections/Search/Results/results.css'

const Link = styled.a`
  ${(props) =>
    props.styles.color &&
    `
    color: ${props.styles.color};
  `}

  ${(props) =>
    props.styles.hoverColor &&
    `
    &:hover {
      color: ${props.styles.hoverColor};
    }
  `};
`

const Results = (props) => {
  const lang = getLang()
  return (
    <ThemeConsumer>
      {(theme) => (
        <div className="results">
          {props.results.map((result, idx) => (
            <Link
              key={idx}
              href={result.url}
              className="results__item"
              styles={{
                color: theme.headingsColor,
                hoverColor: theme.highlightColor
              }}
            >
              <div className="results__item-inner">
                <h3 className="results__title">{result.title}</h3>
                <div className="results__excerpt" style={{ color: theme.paragraphColor }}>
                  <HtmlParser string={result.excerpt} />
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}
    </ThemeConsumer>
  )
}

export default Results
