import React from 'react'
import t from 'typy'
import styled from 'styled-components'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'
import Button from 'components/_ui/Button'
import { shadeHexColor } from 'utils'
import { IconAndTextGroupPara } from 'ts/DTO/paragraphs'
import { ButtonStyle as ButtonStyleType } from 'ts/DTO/content.config'
import 'components/_paragraphs/IconTextGrid/icon-text-grid.css'

const HoverLink = styled.h4<{ styles: { color: string } }>`
  transition: color 0.3s ease;

  ${(props) =>
    props.styles.color &&
    `
    color: ${props.styles.color};
  `};
`

const Title = styled.div<{ styles: { hoverColor?: string | boolean } }>`
  ${(props) =>
    props.styles.hoverColor &&
    `
    &:hover ${HoverLink} {
      color: ${props.styles.hoverColor};
    }
  `} a {
    display: block;
  }
`

type BlockProps = {
  icon: string
  title: string
  subtitle: string
  theme: any
  buttonStyle: ButtonStyleType
  highlightColor: string
  cta:
    | string
    | {
        uri: string
        title: string
        options: {
          isExternal: string
        }
      }
}

const Block = (props: BlockProps) => {
  const { cta, buttonStyle, highlightColor } = props

  let options = {}
  let title = props.title
  let icon = (
    <span
      className="ictxt-grid__icon"
      style={{ color: props.theme.highlightColor }}
      dangerouslySetInnerHTML={{ __html: props.icon }}
    />
  )

  // If button isn't showing, but there's a URI coming back from API
  let button = null
  if (typeof cta !== 'string') {
    const { isExternal } = cta.options
    button = cta.uri && cta.title && (
      <Button href={cta.uri} buttonStyle={buttonStyle} primaryColor={highlightColor}>
        {cta.title}
      </Button>
    )

    if (!button && cta.uri) {
      icon = (
        <HoverLink
          as="span"
          className="ictxt-grid__icon"
          styles={{ color: props.theme.highlightColor }}
        >
          <span dangerouslySetInnerHTML={{ __html: props.icon }} />
        </HoverLink>
      )

      options = {
        as: isExternal ? 'a' : Link,
        href: isExternal ? cta.uri : null,
        to: isExternal ? null : cta.uri,
        target: isExternal ? '_blank' : null,
        rel: isExternal ? 'noreferrer noopener' : null
      }
    }
  }

  const ctaUri = typeof cta !== 'string' && cta.uri

  return (
    <div className="ictxt-grid__block" style={{ color: props.theme.paragraphColor }}>
      <Title
        {...options}
        className="ictxt-grid__title"
        styles={{
          hoverColor: !button && ctaUri && shadeHexColor(props.theme.highlightColor, -0.3)
        }}
      >
        {icon}
        <HoverLink styles={{ color: props.theme.headingsColor }}>{title}</HoverLink>
      </Title>
      <div className="ictxt-grid__desc" style={{ color: props.theme.paragraphColor }}>
        <HtmlParser string={props.subtitle} />
        {button}
      </div>
    </div>
  )
}

interface IconTextGridProps {
  buttonStyle: ButtonStyleType
  highlightColor: string
  alignment: string
  columns: IconAndTextGroupPara['content']
}

const IconTextGrid = (props: IconTextGridProps) => (
  <ThemeConsumer>
    {(theme) => {
      const blocks = props.columns.map((block: any, idx: number) => (
        <Block
          key={idx}
          icon={t(block, 'content.image.markup').safeObject}
          title={block.content.title}
          subtitle={block.content.subtitle}
          cta={block.content.cta}
          theme={theme}
          buttonStyle={props.buttonStyle}
          highlightColor={props.highlightColor}
        />
      ))
      return (
        <div
          className={classnames({
            'ictxt-grid': true,
            [`ictxt-grid--${blocks.length}`]: true,
            [`ictxt-grid--align-${props.alignment}`]: props.alignment
          })}
        >
          <div className="wrapper">
            <div className="ictxt-grid__inner">{blocks}</div>
          </div>
        </div>
      )
    }}
  </ThemeConsumer>
)

export default IconTextGrid
