// @react
import React, { ReactElement, ReactNode } from 'react'
// @components
import InlineSVG from 'components/_ui/InlineSVG'
// @common
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import classnames from 'classnames'
import { themeSanitizer } from 'components/_sections/Page/Section/ThemeContext'
import { removeParagraphFromString, generateGradient } from 'utils'
// @design
import 'components/_paragraphs/Hero/Small/small.css'
import { ActiveButtonColors } from 'ts/DTO/content.colorpalette'

/*******************************************************
 * TYPE
 *******************************************************/
type PropsType = {
  theme: string
  title?: string
  cta?: ReactElement | ReactNode
  logo?: any
  style?: ActiveButtonColors
  image?: string
  alignment?: string
  pagePalette?: any
  buttonStyle?: any
}

/***
 *
 * @param theme
 * @param styles
 */
const BgLogo = ({ theme, styles }: { theme: string; styles: any }) => {
  switch (theme) {
    case 'theme_1':
    case 'theme_3':
      return null
    // three sails
    default:
    case 'theme_2':
      return <InlineSVG icon="logo-stripes" style={{ color: styles && styles.logoColor }} />
  }
}

/**
 *
 * @param theme
 * @param title
 * @param image
 * @param style
 * @param logo
 * @param cta
 * @param alignment
 * @param pagePalette
 * @returns {*}
 * @constructor
 */
const Small = ({
  theme = 'theme_1',
  title,
  cta,
  logo,
  image,
  style,
  alignment,
  pagePalette
}: PropsType) => {
  const styles = themeSanitizer<ActiveButtonColors>(style)
  const gradient = style?.gradient
  const pageGradient = pagePalette?.styleColors?.gradient
  const pageHighlightColor = pagePalette?.styleColors?.highlightColor

  if (image) {
    styles.background = `url(${image}) no-repeat 50% / cover transparent`
  }

  // Make highlightColor actually be backgroundColor
  if (styles && styles.highlightColor) {
    styles.background =
      generateGradient(gradient) ||
      // @ts-ignore
      styles.highlightColor ||
      generateGradient(pageGradient) ||
      pageHighlightColor
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <div
      className={classnames({
        hero: true,
        'hero-custom-logo': logo
      })}
      style={styles}
    >
      {logo && <header className="wrapper">{logo}</header>}
      <div
        className={classnames({
          wrapper: true,
          [`text-align-${alignment}`]: alignment
        })}
      >
        {title && (
          <h1>
            <HtmlParser string={removeParagraphFromString(title)} />
          </h1>
        )}
        <BgLogo theme={theme} styles={styles} />
        {cta}
      </div>
    </div>
  )
}

export default Small
