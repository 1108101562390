import React from 'react'
import styled from 'styled-components'
import t from 'typy'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import InlineSVG from 'components/_ui/InlineSVG'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import colors from 'constants/colors'
import mq from 'constants/mediaQueries'
import { Strings } from 'components/_utility/Strings'
import { getLang } from 'utils'
import { ArticleGridContextConsumer } from 'components/_sections/Article/ArticleGrid/context'
import ImageCard from 'components/_sections/Article/ArticleGrid/ImageCard'

import {
  TextWrap,
  Category,
  Title,
  Teaser,
  Footer,
  Date,
  Share,
  SponsoredTag,
  ReadMore
} from 'components/_sections/Article/ArticleGrid/Article'

import 'sass/vendor/slick.css'
import 'sass/vendor/slick-overrides.css'

// When the component goes into mobile carousel mode
const layoutSwitch = mq.breakpoint.tablet
// Move isMobile to state of parent component for watch when window on resize
// const isMobile = window.matchMedia(`(max-width: ${layoutSwitch}px)`).matches

const SlickArrow = styled.button`
  display: inline-flex;
  width: 3em;
  height: 3em;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  box-shadow: none;
  border-radius: 50%;
  color: currentColor;
  background-color: ${colors.white};
  transition: all 0.3s ease;

  &:hover {
    color: ${colors.white};

    ${(props) =>
      props.hoverBg &&
      `
      background-color: ${props.hoverBg};
    `};
  }

  svg {
    font-size: 0.55em;
  }
`

const SliderPrevArrow = (props) => {
  const { className, style, onClick, hoverBg } = props
  return (
    <SlickArrow className={className} style={style} onClick={onClick} hoverBg={hoverBg}>
      <InlineSVG icon="caret-left" />
    </SlickArrow>
  )
}

const SliderNextArrow = (props) => {
  const { className, style, onClick, hoverBg } = props
  return (
    <SlickArrow className={className} style={style} onClick={onClick} hoverBg={hoverBg}>
      <InlineSVG icon="caret-right" />
    </SlickArrow>
  )
}

const ArticlesWrap = styled.div`
  display: flex;
  width: 100%;

  > div {
    width: 100%;
  }

  ${(props) =>
    props.asCarousel &&
    `
    ${mq.from(mq.breakpoint.ipad)} {
      width: calc(100% - 2em - ${props.hideTitle ? '0px' : '285px'});
    }

    ${mq.from(layoutSwitch)} {

      .slick-list {
        margin-left: -0.5em;
        margin-right: -0.5em;
      }
    }

    .slick-slider {
      position: static;

      ${mq.from(mq.breakpoint.ipad)} {
        height: 100%;
      }
    }

    .slick-list,
    .slick-track {
      ${mq.from(mq.breakpoint.ipad)} {
        height: 100%;
      }
    }

    .slick-slide > div {
      display: flex;
    }

    ${mq.between(layoutSwitch, mq.breakpoint.desktop)} {
      .slick-track {
        display: flex;
      }

      .slick-slide {
        height: auto;
      }
    }

    ${mq.between(mq.breakpoint.ipad, mq.breakpoint.desktop)} {
      .slick-list {
        height: auto !important;
      }

      .slick-slide > div > * {
        width: 100%;
      }
    }

    .slick-arrow {
      position: absolute;
      top: 0;
      right: 0;
    }

    .slick-prev {
      right: 4em;
    }
  `} .slick-dots {
    position: static;
    margin-top: 1.5em;

    ${(props) =>
      props.highlightColor &&
      `
      color: ${props.highlightColor};
    `};
  }
`

const STextWrap = styled(TextWrap)`
  padding: 1.5em 1.35em;
`

const SSponsoredTag = styled(SponsoredTag)``

const STeaser = styled(Teaser)`
  display: block;
  -webkit-line-clamp: initial;
  line-clamp: initial;
  overflow: initial;
`

const SFooter = styled(Footer)`
  margin-top: auto;
`

const Article = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  color: currentColor;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    transition: background-color 0.3s ease;
  }

  ${(props) =>
    props.style.hoverColor &&
    !props.style.notClickable &&
    `
    &:hover ${Title} {
      color: ${props.style.hoverColor};
    }
  `}

  ${(props) =>
    props.style.notClickable &&
    `
    cursor: auto;
  `}

  ${(props) =>
    props.style.drawerTarget &&
    `
    cursor: pointer;
  `}

  ${(props) =>
    props.style.hasBorder &&
    `
    border: 1px solid ${colors.articleBorderGray};

    picture {
      margin-left: -1px;
      margin-right: -1px;
      margin-top: -1px;
    }
  `}

  ${(props) =>
    props.style.isActive &&
    props.style.hoverColor &&
    `
    &::before {
      background-color: ${props.style.hoverColor};
    }
  `}

  ${(props) =>
    props.style.tileBackground &&
    `
    background-color: ${props.style.tileBackground};
  `}

  ${(props) =>
    props.style.sponsoredColor &&
    `
    ${Title} {
      color: ${props.style.sponsoredColor};
    }

    ${SSponsoredTag} {
      background-color: ${props.style.sponsoredColor};
    }
  `}

  ${(props) =>
    props.style.hasBorder &&
    props.style.sponsoredColor &&
    `
    border-color: ${props.style.sponsoredColor};
  `}

  .banner-articles--has-1 & {
    ${mq.from(layoutSwitch)} {
      flex-direction: row;
      background-color: ${colors.white};

      picture {
        height: auto;
        width: 50%;
      }

      ${(props) =>
        props.style.hasBorder &&
        `
        picture {
          margin-bottom: -1px;
        }
      `}

      ${STextWrap} {
        margin: auto 0em;
        width: 50%;
      }
    }
  }

  .banner-articles--as-carousel & {
    ${mq.from(layoutSwitch)} {
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
  }

  &:not(:last-child) {
    margin-right: 1.2em;
  }

  picture {
    display: block;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

/**
 *
 * @param layout
 * @param asCarousel
 * @param articles
 * @param setDrawerContent
 * @param active
 * @param hideTitle
 * @param isMobile
 * @returns {JSX.Element}
 * @constructor
 */
const Articles = ({
  layout,
  asCarousel,
  articles,
  setDrawerContent,
  active,
  hideTitle,
  isMobile
}) => (
  <ArticleGridContextConsumer>
    {({ style, border, wrapAlignment, tileBackgroundColor, sponsoredLabel, toggleShareModal }) => {
      const lang = getLang()

      const articleNodes = articles.map(({ content: article }, idx) => {
        const {
          url,
          target,
          title,
          teaserText,
          displayDate,
          date,
          displayShare,
          sponsored,
          isSponsored,
          category,
          categoryText,
          image,
          content,
          cta,
          type
        } = article
        const externalLink = type === 'external_link' || type === 'image_card'
        const notClickable = externalLink && (!cta || (!cta.uri && !cta.title))
        const isExternalCTA =
          externalLink && cta && cta.options && cta.options.isExternal === 'true'

        let optionalProps = {}
        if (!notClickable) {
          if (isExternalCTA) {
            optionalProps = {
              as: 'a',
              href: cta.uri,
              target: '_blank',
              rel: 'noreferrer noopener'
            }
          } else {
            if (target === 'drawer') {
              optionalProps = {
                onClick: () => {
                  setDrawerContent(idx, {
                    content: content.field_para_reference.content,
                    relatedArticles: article.relatedArticles,
                    highlightColor: style.highlightColor
                  })
                }
              }
            } else {
              // target === 'page'
              optionalProps = {
                as: Link,
                to: (cta && cta.uri) || url
              }
            }
          }
        }

        const articleIsSponsored = isSponsored || sponsored
        const articleDisplayDate = displayDate || (externalLink && date) || false
        const imageUrl = externalLink ? image : t(image, 'url').safeObject

        if (type === 'image_card') {
          return (
            <React.Fragment key={idx}>
              <Article
                {...optionalProps}
                style={{
                  notClickable: notClickable,
                  drawerTarget: target === 'drawer',
                  hoverColor: style.highlightColor,
                  textAlign: wrapAlignment,
                  tileBackground: tileBackgroundColor,
                  sponsoredColor: articleIsSponsored && style.sponsoredContentColor,
                  hasBorder: border,
                  isActive: idx === active
                }}
              >
                <ImageCard
                  {...article}
                  size="big"
                  wrapAlignment={wrapAlignment}
                  tileBackground={tileBackgroundColor}
                  style={style}
                />
              </Article>
            </React.Fragment>
          )
        }

        return (
          <React.Fragment key={idx}>
            <Article
              {...optionalProps}
              style={{
                notClickable: notClickable,
                drawerTarget: target === 'drawer',
                hoverColor: style.highlightColor,
                textAlign: wrapAlignment,
                tileBackground: tileBackgroundColor,
                sponsoredColor: articleIsSponsored && style.sponsoredContentColor,
                hasBorder: border,
                isActive: idx === active
              }}
            >
              <picture
                style={{
                  backgroundColor: !imageUrl && style.highlightColor
                }}
              >
                {imageUrl && <img src={imageUrl} alt={title} />}
                {articleIsSponsored && (
                  <SSponsoredTag>
                    {sponsoredLabel || Strings.text.sponsored_content[lang]}
                  </SSponsoredTag>
                )}
              </picture>
              <STextWrap color={style.paragraphColor}>
                <Category style={{ color: style.highlightColor }}>
                  {categoryText || category || '\u00A0'}
                </Category>
                <Title color={style.headingsColor}>{title}</Title>
                {!externalLink && (
                  <Teaser>
                    <HtmlParser string={teaserText} />
                  </Teaser>
                )}
                {externalLink && <STeaser dangerouslySetInnerHTML={{ __html: teaserText }} />}
                <SFooter>
                  {externalLink && (
                    <React.Fragment>
                      {articleDisplayDate && (
                        <Date style={{ color: style.headingsColor }}>{date}</Date>
                      )}
                      {cta.title && (
                        <ReadMore style={{ color: style.highlightColor }}>
                          {cta.title}
                          <InlineSVG icon="arrow-right" />
                        </ReadMore>
                      )}
                    </React.Fragment>
                  )}
                  {!externalLink && (
                    <React.Fragment>
                      {displayDate && <Date style={{ color: style.headingsColor }}>{date}</Date>}
                      {displayShare && target === 'page' && (
                        <Share
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            toggleShareModal({
                              shareUrl: `${window.location.origin}/${url}`,
                              shareTitle: title,
                              iconColor: style.headingsColor,
                              iconHoverColor: style.highlightColor
                            })
                          }}
                          style={{ highlightColor: style.highlightColor }}
                        >
                          <InlineSVG icon="share" />
                          {Strings.text.share[lang]}
                        </Share>
                      )}
                    </React.Fragment>
                  )}
                </SFooter>
              </STextWrap>
            </Article>
          </React.Fragment>
        )
      })

      const settingsMobile = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        adaptiveHeight: true,
        focusOnSelect: false,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1
      }

      const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        focusOnSelect: false,
        variableWidth: false,
        adaptiveHeight: true,
        slidesToShow: layout === 'theme_13' ? 4 : 3,
        slidesToScroll: layout === 'theme_13' ? 4 : 3,
        prevArrow: <SliderPrevArrow hoverBg={style.highlightColor} />,
        nextArrow: <SliderNextArrow hoverBg={style.highlightColor} />,
        responsive: [
          {
            breakpoint: mq.breakpoint.desktop,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              arrows: false
            }
          },
          {
            breakpoint: layoutSwitch,
            settings: settingsMobile
          }
        ]
      }

      return (
        <ArticlesWrap
          asCarousel={asCarousel}
          highlightColor={style.highlightColor}
          hideTitle={hideTitle}
        >
          {asCarousel && (
            <div>
              <Slider {...settings}>{articleNodes}</Slider>
            </div>
          )}

          {!asCarousel && isMobile && (
            <div>
              <Slider {...settingsMobile}>{articleNodes}</Slider>
            </div>
          )}

          {!asCarousel && !isMobile && articleNodes}
        </ArticlesWrap>
      )
    }}
  </ArticleGridContextConsumer>
)

export default Articles
