export interface configProps {
  title: string
  api_url: string
  client_id: string
  client_secret: string
  gtm_id: string
  wisepops_id: string
  ga?: boolean
  ga_ua_id?: string
  logo_url?: string
  defaultLanguage: string
  mailchimpPopup?: boolean
  mailchimpActionUrl?: string
}

const useConfig = () => {
  // const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  //  const isDev = !process.env.NODE_ENV ||
  /**
   *
   */
  const getConfig: () => configProps =
    process.env.NODE_ENV === 'development'
      ? () => {
          // load config directly from file
          const multisiteConfig = require('../../multisite.json')
          const multisiteConfigDev = require('../../multisite.dev.json')
          const multisiteConfigFinal = {
            ...multisiteConfig,
            ...multisiteConfigDev
          }
          const host = window.location.hostname
          return multisiteConfigFinal['development'][host]
        }
      : () => {
          return window.thisConfig
        }

  return {
    getConfig
  }
}

export default useConfig
