import React, { Component } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import Banner from 'components/_sections/Article/ArticleGrid/Slider/Banner'
import Articles from 'components/_sections/Article/ArticleGrid/Slider/Articles'
import ArticleRelated from 'components/_sections/Article/ArticleRelated'
import PageSection from 'components/_sections/Page/Section'
import colors from 'constants/colors'
import mq from 'constants/mediaQueries'

// When the component goes into mobile carousel mode
const layoutSwitch = mq.breakpoint.tablet

const Wrap = styled.div`
  padding-bottom: 2.5rem;
`

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  ${(props) =>
    props.asCarousel &&
    props.showBorder &&
    `
    ${mq.from(mq.breakpoint.desktop)} {
      padding-top: 4em;

      &::before {
        content: '';
        position: absolute;
        top: 1.5em;
        left: calc(${props.hideTitle ? '0px' : '285px + 2em'});
        right: 8.5em;
        height: 3px;
        transform: translateY(-50%);
        background-color: #d1d5da;
      }
    }
  `} ${mq.to(mq.breakpoint.ipad)} {
    flex-direction: column;
  }
`

const DrawerContent = styled.div`
  width: 100%;
  margin-top: 1em;
  background-color: ${colors.white};
`

/**
 *
 */
class ArticleBanner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      articleId: null,
      drawerContent: null,
      isMobile: window.matchMedia(`(max-width: ${layoutSwitch}px)`).matches
    }

    this.setDrawerContent = this.setDrawerContent.bind(this)
    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    this.setState({
      isMobile: window.matchMedia(`(max-width: ${layoutSwitch}px)`).matches
    })
  }

  setDrawerContent(articleId, APIContent) {
    // If the same article toggled action again, hide the content/unset
    if (articleId === this.state.articleId) {
      this.setState({
        articleId: null,
        drawerContent: null
      })
    } else {
      this.setState({
        articleId: articleId,
        drawerContent: APIContent
      })
    }
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  render() {
    const { layout, title, articles, images, theme, hideTitle } = this.props

    const { drawerContent, articleId, isMobile } = this.state

    if (!articles) {
      return null
    }

    const carouselShowingTiles = layout === 'theme_13' ? 4 : 3
    const asCarousel = articles.length > carouselShowingTiles

    return (
      <Wrap
        className={classnames({
          'banner-articles': true,
          'banner-articles--as-carousel': asCarousel,
          [`banner-articles--has-${articles.length}`]: articles
        })}
      >
        <div className="wrapper">
          <Inner
            asCarousel={asCarousel}
            hideTitle={hideTitle}
            showBorder={carouselShowingTiles < articles.length}
          >
            <Banner title={title} images={images} hideTitle={hideTitle} />
            <Articles
              layout={layout}
              articles={articles}
              asCarousel={asCarousel}
              setDrawerContent={this.setDrawerContent}
              active={articleId}
              theme={theme}
              hideTitle={hideTitle}
              isMobile={isMobile}
            />
          </Inner>
          {drawerContent && (
            <Inner>
              <Banner spacer />
              <DrawerContent>
                {drawerContent.content.map((data, idx) => (
                  <PageSection key={idx} data={data} />
                ))}
                <ArticleRelated
                  articles={drawerContent.relatedArticles}
                  highlightColor={drawerContent.highlightColor}
                />
              </DrawerContent>
            </Inner>
          )}
        </div>
      </Wrap>
    )
  }
}

export default ArticleBanner
