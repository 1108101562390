// @react
import React from 'react'
// @components
import GoogleTagManager from 'components/_integrations/GoogleTagManager'
import MailchimpPopup from 'components/_ui/MailchimpPopup'
import { PageContextConsumer } from 'components/_sections/Page/Wrapper/context'
import { ThemeProvider } from 'components/_sections/Page/Section/ThemeContext'
import { useLocation } from 'react-router-dom'
import useConfig from 'components/hooks/useConfig'
import WisePops from './WisePops'

/**
 *
 * @constructor
 */

const VendorIntegrations = () => {
  const location = useLocation()
  const { getConfig } = useConfig()
  const config = getConfig()

  return (
    <PageContextConsumer>
      {({ pagePalette }) => {
        const path = location.pathname.replace(/\/+$/, '')
        return (
          <ThemeProvider value={pagePalette}>
            {/* MAIL CHIMP POPUP FORM INJECT */}
            {config.mailchimpPopup && <MailchimpPopup action={config.mailchimpActionUrl} />}
            {/* GOOGLE TAG MANAGER */}
            {/*{config.gtm_id && <GoogleTagManager gtmId={config.gtm_id} />}*/}
            {config.wisepops_id && <WisePops wisepopsId={config.wisepops_id} />}
          </ThemeProvider>
        )
      }}
    </PageContextConsumer>
  )
}

export default VendorIntegrations
