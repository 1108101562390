import React, { Component } from 'react'
import SmoothScroll from 'smooth-scroll'
import classnames from 'classnames'
import styled from 'styled-components'

import InlineSVG from '../../../../_ui/InlineSVG'
import { Strings } from '../../../../_utility/Strings'
import { getLang } from '../../../../../utils'
import { JobsContext } from '../context'

import './sidebar.css'

const SidebarWrap = styled.aside`
  ${(props) => `
    border-color: ${props.borderColor};
  `};
`

const SidebarFilterItem = styled.li`
  ${(props) => `
    && {
      color: ${props.color};
    }

    &&:hover {
      color: ${props.hoverColor};
    }
  `} ${(props) =>
    props.active &&
    `
    &&.active {
      background-color: ${props.activeBackground};
      color: ${props.activeColor};
    }

    &&,
    &&:hover {
      color: #fff;
    }
  `};
`

const SidebarFilterMore = styled.span`
  ${(props) => `
    color: ${props.color};

    &&:hover {
      color: ${props.hoverColor};
    }
  `};
`

class Sidebar extends Component {
  constructor(props) {
    super(props)

    this.scroll = new SmoothScroll()
    this.scrollToLocations = this.scrollToLocations.bind(this)
  }

  scrollToLocations() {
    this.scroll.animateScroll(this.title, false, {
      updateURL: false,
      offset: () => {
        return window.matchMedia('(min-width: 1100px)').matches ? 97 : 102
      }
    })
  }

  render() {
    const lang = getLang()

    return (
      <JobsContext.Consumer>
        {({ style, jobs, filter }) => {
          const { locations, activeLocation, setActive, collapsed, toggleCollapsed } = filter

          const { labels, results } = jobs

          if (!results) {
            return null
          }

          return (
            <SidebarWrap
              className={classnames({
                jobs__sidebar: true,
                'jobs__sidebar--expanded': !collapsed
              })}
              borderColor={style.headingsColor}
            >
              <h3
                className="jobs__sidebar-title"
                ref={(title) => {
                  this.title = title
                }}
                style={{
                  backgroundColor: style.headingsColor,
                  color: style.activeColor
                }}
              >
                {labels.location}
              </h3>

              <ul className="jobs__sidebar-list">
                {locations &&
                  locations.map((location, idx) => (
                    <SidebarFilterItem
                      key={idx}
                      className={classnames({
                        active: activeLocation === location.name
                      })}
                      active={activeLocation === location.name}
                      color={style.headingsColor}
                      hoverColor={style.highlightColor}
                      activeColor={style.activeColor}
                      activeBackground={style.highlightColor}
                      onClick={() => setActive('location', location.name)}
                    >
                      {location.name} ({location.count})
                    </SidebarFilterItem>
                  ))}
              </ul>
              <SidebarFilterMore
                className="jobs__sidebar-more"
                color={style.headingsColor}
                hoverColor={style.highlightColor}
                onClick={() => {
                  toggleCollapsed()
                  this.scrollToLocations()
                }}
              >
                {collapsed ? Strings.jobs.more[lang] : Strings.jobs.less[lang]}{' '}
                <InlineSVG icon="arrow-right" />
              </SidebarFilterMore>
            </SidebarWrap>
          )
        }}
      </JobsContext.Consumer>
    )
  }
}

export default Sidebar
