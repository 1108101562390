/* eslint-disable no-useless-escape */
// Initially, we used https://github.com/gndx/react-mailchimp-form
// but since that component doesn't allow for any functionality
// like custom Button component or responding to events and state
// we decided to clone it and update to suit our usecase
// NOTE: it uses jsonp to circumvent the CORS

import React from 'react'
import jsonp from 'jsonp'
import PropTypes from 'prop-types'
import Button from 'components/_ui/Button'

/**
 *
 */
class Mailchimp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: null,
      values: {},
      isPopup: props.isPopup ? props.isPopup : false
    }

    this.innerRef = React.createRef()
    this.infoRef = React.createRef()

    this.handleSubmit = this.handleSubmit.bind(this)
    this.sendData = this.sendData.bind(this)
  }

  componentDidMount() {
    this.inner = this.innerRef.current
    this.info = this.infoRef.current
  }

  handleSubmit(evt) {
    evt.preventDefault()
    const { fields, action } = this.props
    const { values } = this.state
    const fieldValues = fields
      .map((field) => {
        return `${field.name}=${encodeURIComponent(
          this.state.values[field.name] ? this.state.values[field.name] : ''
        )}`
      })
      .join('&')
    const path = `${action}&${fieldValues}`
    const url = path.replace('/post?', '/post-json?')
    const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/

    const { EMAIL } = values

    !regex.test(EMAIL) ? this.setState({ status: 'empty' }) : this.sendData(url)
  }

  sendData(url) {
    this.setState({ status: 'sending' })

    jsonp(url, { param: 'c' }, (err, data) => {
      if (data.msg.includes('already subscribed')) {
        this.setState({ status: 'duplicate' }) //set subscribed cookie if already subscribed
        if (this.state.isPopup) {
          document.cookie = 'mailchimp_subscribed=true; expires=Fri, 31 Dec 9999 23:59:59 GMT'
        }
      } else if (err) {
        this.setState({ status: 'error' })
      } else if (data.result !== 'success') {
        this.setState({ status: 'error' })
      } else {
        this.setState({ status: 'success' })

        // Hide the form and just show the message
        // if popup close after 2 seconds
        // or 5 seconds later, show the form back and hide the text
        this.inner.style.display = 'none'
        if (this.state.isPopup) {
          setTimeout(() => {
            this.props.closePopup() //set subscribed cookie if successfully subscribed
            document.cookie = 'mailchimp_subscribed=true; expires=Fri, 31 Dec 9999 23:59:59 GMT'
            this.setState({ isPopup: false })
          }, 2000)
        }
        setTimeout(() => {
          this.inner.style.display = null
          this.setState({
            status: null,
            values: {}
          })
        }, 5000)
      }
    })
  }

  render() {
    const { status, values } = this.state
    const { fields, styles, className } = this.props
    const messages = {
      ...Mailchimp.defaultProps.messages,
      ...this.props.messages
    }

    return (
      <form onSubmit={this.handleSubmit} className={`mcform ${className}`}>
        <div className="mcform__inner" ref={this.innerRef}>
          {fields.map((input) => (
            <div key={input.name}>
              {this.state.isPopup && <label className="mcform__inner__label">{input.label}</label>}
              <input
                {...input}
                key={input.name}
                value={values[input.name] || ''}
                onChange={({ target }) =>
                  this.setState((state) => {
                    return {
                      values: {
                        ...values,
                        [input.name]: target.value
                      }
                    }
                  })
                }
              />
            </div>
          ))}

          <Button type="submit" disabled={status === 'sending' || status === 'success'}>
            {messages.button}
          </Button>
        </div>

        <div className="mcform__info" ref={this.infoRef}>
          {status === 'sending' && <p style={styles.sendingMsg}>{messages.sending}</p>}
          {status === 'success' && (
            <p className="__success" style={styles.successMsg}>
              {messages.success}
            </p>
          )}
          {status === 'duplicate' && <p style={styles.duplicateMsg}>{messages.duplicate}</p>}
          {status === 'empty' && <p style={styles.errorMsg}>{messages.empty}</p>}
          {status === 'error' && <p style={styles.errorMsg}>{messages.error}</p>}
        </div>
      </form>
    )
  }
}

Mailchimp.defaultProps = {
  messages: {
    sending: 'Sending...',
    success: 'Thank you for subscribing!',
    error: 'An unexpected internal error has occurred.',
    empty: 'You must enter a valid e-mail address.',
    duplicate: 'You are already subscribed.',
    button: 'Subscribe'
  },
  styles: {
    sendingMsg: {
      color: 'currentColor'
    },
    successMsg: {
      color: '#fff'
    },
    duplicateMsg: {
      color: '#EE5A24'
    },
    errorMsg: {
      color: '#ED4C67'
    }
  }
}

Mailchimp.propTypes = {
  action: PropTypes.string,
  messages: PropTypes.object,
  fields: PropTypes.array,
  styles: PropTypes.object,
  className: PropTypes.string
}

export default Mailchimp
