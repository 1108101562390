import React from 'react'
import Hero from 'components/_paragraphs/Hero/Small'

const Page500 = () => {
  return (
    <div>
      <Hero title={'500: error!'} theme={'theme_2'} />
    </div>
  )
}

export default Page500
