// @todo this should be in the backend
// 'base' is not used here as the base feed or portal ID, but only for the webforms
export const jobBoardConfig = {
  DRAKKAR: {
    base: {
      en: '5e162c40-a0e1-4fdc-b76c-015d916a5fe4',
      fr: '5adc4df1-96ad-4cd1-95a7-0712cd4c6272'
    },
    theme: 'theme_2'
  },
  'DRAKKAR Digital': {
    base: {
      en: '34eedfc2-d153-4d39-81f0-6fb842500c6f',
      fr: '10e2d53a-d405-4841-b175-d5e5d19c18f9'
    },
    theme: 'theme_2'
  },
  SELEKKTUS: {
    base: {
      en: '6ba6a478-063c-433b-9ab7-6da73068f232',
      fr: 'aee23e0c-a4e5-444a-9ff4-e89606dd75ca'
    },
    theme: 'theme_3'
  }
}
