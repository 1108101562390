import React from 'react'
import styled from 'styled-components'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import { getLang } from 'utils'
import InlineSVG from 'components/_ui/InlineSVG'
import { ShareDataType } from 'components/_sections/Article/ArticleShare'

export type FixMeLater = any
export interface WrapPropType {
  show: boolean
}

export interface ContentPropType {
  style: {
    iconColor: string
    iconHoverColor: string
  }
}
export interface OverlayProps {
  onClick: (data: ShareDataType) => void
}
const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0s ease;
  z-index: 11;

  ${(props: WrapPropType) =>
    props.show &&
    `
    opacity: 1;
    visibility: visible;
    transition-duration: 0.3s;
  `};
`

const Overlay: FixMeLater = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
`

const Content = styled.div`
  position: relative;
  max-width: 300px;
  width: 100%;
  margin: 2em auto;
  padding: 1.5em;
  background-color: #fff;
  z-index: 2;
  flex-shrink: 0;

  .social-media {
    display: flex;
    justify-content: center;
  }

  .icon {
    display: flex;
    width: 2em;
    height: 2em;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;

    &--email path {
      stroke-width: 15px;
      stroke: currentColor;
    }
  }

  .SocialMediaShareButton {
    cursor: pointer;
    transition: color 0.3s ease;

    ${(props: ContentPropType) =>
      props.style.iconColor &&
      `
      color: ${props.style.iconColor};
    `} ${(props) =>
      props.style.iconHoverColor &&
      `
      &:hover {
        color: ${props.style.iconHoverColor};
      }
    `};
  }

  a {
    color: currentColor;
  }
`
export interface IProps {
  show: boolean
  toggleShare: (data: ShareDataType) => void
  shareUrl: string
  shareTitle: string
  iconColor: string
  iconHoverColor: string
}

const ShareModal: React.FunctionComponent<IProps> = ({
  show,
  toggleShare,
  shareUrl,
  shareTitle,
  iconColor,
  iconHoverColor
}) => {
  const lang = getLang()

  const share = {
    url: shareUrl,
    title: shareTitle
  }

  const email =
    lang === 'en'
      ? {
          subject: `This article may interest you!`,
          body: `I just came across this article and thought it might interest you. Take a look! %0D%0A %0D%0A ${share.url}`
        }
      : {
          subject: `Cet article pourrait t'intéresser!`,
          body: `Je viens de voir cet article et j'ai pensé qu'il pourrait t'intéresser. À y jeter un coup d'œil! %0D%0A %0D%0A ${share.url}`
        }

  return (
    <Wrap show={show}>
      <Overlay onClick={toggleShare} />
      <Content
        style={{
          iconColor: iconColor,
          iconHoverColor: iconHoverColor
        }}
      >
        {show && (
          <div className="social-media">
            <TwitterShareButton {...share}>
              <InlineSVG icon="twitter" />
            </TwitterShareButton>
            <LinkedinShareButton {...share}>
              <InlineSVG icon="linkedin" />
            </LinkedinShareButton>
            <FacebookShareButton url={share.url}>
              <InlineSVG icon="facebook" />
            </FacebookShareButton>
            <a
              className="SocialMediaShareButton"
              href={`mailto:?subject=${email.subject}&body=${email.body}`}
            >
              <InlineSVG icon="email" />
            </a>
          </div>
        )}
      </Content>
    </Wrap>
  )
}

export default ShareModal
