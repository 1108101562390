import { getLang } from '../utils'
import TagManager from 'react-gtm-module'

const getRequestURI = () => {
  return window.location.pathname.replace('/en/', '/')
}

export const getTrackingFormName = (id: string) => {
  switch (id) {
    case 'plj6bpsceeipbaanovsseq':
    case 'viljzpsdeeipbaanovsqza':
      return 'impartition'
    case 'oazlcpsyeeipawanovscra':
    case 'qgid0zsweeipbaanovsseq':
      return 'recrutement'
    case 'ouiixpsveeipawanovscra':
    case 'xb4vrowweeipawanovsxwa':
      return 'contact'
    default:
      return false
  }
}

const Tracking = (id: string) => {
  switch (id) {
    case 'pageView':
      return (title: {}) => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'VPV',
            page: {
              url: getRequestURI(),
              title: title
            }
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS001':
      return () => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'PV',
            page: {
              gaStep: 'recrutement-form-view',
              language: getLang(),
              requestURI: getRequestURI()
            }
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS002':
      return () => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'PV',
            page: {
              gaStep: 'recrutement-form-start',
              language: getLang(),
              requestURI: getRequestURI()
            }
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS003':
      return (leadId: string) => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'PV',
            page: {
              gaStep: 'recrutement-form-sent',
              language: getLang(),
              requestURI: getRequestURI()
            },
            user: {
              leadId: leadId
            }
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS004':
      return () => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'PV',
            page: {
              gaStep: 'impartition-form-view',
              language: getLang(),
              requestURI: getRequestURI()
            }
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS005':
      return () => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'PV',
            page: {
              gaStep: 'impartition-form-start',
              language: getLang(),
              requestURI: getRequestURI()
            }
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS006':
      return (leadId: string) => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'PV',
            page: {
              gaStep: 'impartition-form-sent',
              language: getLang(),
              requestURI: getRequestURI()
            },
            user: {
              leadId: leadId
            }
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS007':
      return (eventAction: string) => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'userAction',
            eventCategory: 'Clic vers site partenaire',
            eventAction: eventAction
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS008':
      return (leadId: string) => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'VPV',
            page: {
              gaStep: 'job-form-sent',
              language: getLang()
            },
            user: {
              leadId: leadId
            }
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS009':
      return (keyWord: string) => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'userAction',
            eventCategory: 'Filtre ATS',
            eventAction: "Domaine d'expertise",
            eventLabel: keyWord
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS010':
      return (location: string) => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'userAction',
            eventCategory: 'Filtre ATS',
            eventAction: 'Lieu',
            eventLabel: location
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS011':
      return (term: string, location?: string) => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'userAction',
            eventCategory: 'Recherche emploi sur site',
            eventAction: `${term} + ${location}`
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS012':
      return () => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'PV',
            page: {
              gaStep: 'alert-job-form-view',
              language: getLang()
            }
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS013':
      return () => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'VPV',
            page: {
              gaStep: 'alert-job-form-sent',
              language: getLang()
            }
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS015_a':
      return () => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'VPV',
            eventCategory: "Clic vers l'ATS",
            eventAction: "Recherche d'emploi",
            eventLabel: 'Tous'
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS015_b':
      return (label: string) => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'VPV',
            eventCategory: "Clic vers l'ATS",
            eventAction: "Recherche d'emploi",
            eventLabel: label
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS015_c':
      return () => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'VPV',
            eventCategory: "Clic vers l'ATS",
            eventAction: "Recherche d'emploi",
            eventLabel: 'Par domaine'
          },
          dataLayerName: 'dataLayer'
        })
      }
    case 'UCS016':
      return () => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'VPV',
            page: {
              gaStep: 'contact-form-sent',
              language: getLang()
            }
          },
          dataLayerName: 'dataLayer'
        })
      }
    default:
      return () => {
        console.log(`Tracking code ${id} doesn't exist.`)
      }
  }
}

export default Tracking
