export default {
  breakpoint: {
    mobile: 480,
    tablet: 768,
    ipad: 1024,
    desktop: 1100,
    desktopLg: 1450
  },
  from: (breakpoint) => `@media (min-width: ${breakpoint}px)`,
  to: (breakpoint) => `@media (max-width: ${breakpoint - 1}px)`,
  between: (from, to) => `@media (min-width: ${from}px) and (max-width: ${to - 1}px)`
}
