// @react
import React, { useState } from 'react'
import styled from 'styled-components'
import './footer.css'
// @common
import classnames from 'classnames'
// @components
import Top from './Top'
import Middle from './Middle'
import Bottom from './Bottom'
import SiteLogo from 'components/_global/SiteLogo/index'
import { themeSanitizer } from 'components/_sections/Page/Section/ThemeContext'
// @type
import {
  ColorNamesOf,
  ActiveButtonColors,
  PreFooterColors,
  FooterColors
} from 'ts/DTO/content.colorpalette'
import { ApiResponse } from 'ts/DTO'

/*******************************************************
 * STYLE
 *******************************************************/
const Wrap = styled.footer<ColorNamesOf<FooterColors>>`
  ${(props) =>
    props.fontColor &&
    `
    color: ${props.fontColor};
  `}

  ${(props) =>
    props.backgroundColor &&
    `
    background-color: ${props.backgroundColor};
  `}
`

/*******************************************************
 * TYPES
 *******************************************************/
interface SelfProps {
  data: ApiResponse
}
export type StyleColorsType = ColorNamesOf<ActiveButtonColors>
export type PreFooterColorsType = ColorNamesOf<PreFooterColors> | {}
export type FooterColorsType = ColorNamesOf<FooterColors> | {}

/**
 *
 * @param data
 * @constructor
 */
const Footer = ({ data }: SelfProps) => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const [search, setSearch] = useState(false)

  /*******************************************************
   * CONSTANTS
   *******************************************************/
  const lang = data.meta.locale.current_lang
  const { desktop, mobile } = data.content.siteLogos
  const { preFooter, colorPalette, layoutOptions, searchSettings, socialMedia } = data.content
  const { showSearchInPreFooter } = searchSettings
  /**
   * layout
   */
  const hideSeparators = data.content.searchSettings.drakkarHideFooterSeparators
  const socialCompanionText = data.content.preFooter.socialMediaCompanionText
  /**
   * Color palette
   */
  const { styleColors, footerColors, preFooterColors } = colorPalette
  const { hidePreFooter, hideFooter, hideCopyright } = layoutOptions || {}

  const sStyleColors: StyleColorsType = themeSanitizer(styleColors)
  const sFooterColors: FooterColorsType = themeSanitizer(footerColors)
  const sPreFooterColors: PreFooterColorsType = themeSanitizer(preFooterColors)

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const toggleSearch = () => {
    setSearch(!search)
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  if (hidePreFooter && hideFooter && hideCopyright) {
    return null
  }

  return (
    <Wrap
      className={classnames({
        footer: true,
        'footer--no-separator': hideSeparators
      })}
      {...sFooterColors}
    >
      {!hidePreFooter && (
        <Top
          socialCompanionText={socialCompanionText}
          buttonOptions={preFooter}
          socialIcons={socialMedia}
          lang={lang}
          search={search}
          toggleSearch={toggleSearch}
          showSearch={showSearchInPreFooter}
          colors={sPreFooterColors}
          styleColors={sStyleColors}
        />
      )}

      {!hideFooter && <Middle menu={data.content.menu.footer} colors={sFooterColors} />}

      {!hideCopyright && (
        <Bottom
          lang={lang}
          logo={
            <>
              {desktop && <SiteLogo {...desktop} className={mobile?.type ? 'hide--mobile' : ''} />}
              {mobile && <SiteLogo {...mobile} className="show--mobile" />}
            </>
          }
        />
      )}
    </Wrap>
  )
}

export default Footer
