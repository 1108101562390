import React from 'react'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import MutationObserver from 'react-mutation-observer'
import PropTypes from 'prop-types'
import Tracker, { getTrackingFormName } from 'utils/tracking'

import 'components/_integrations/Clickdimensions/Widget/widget.css'

const Widget = ({ id }) => {
  let formStartTracked = false

  return (
    <div className="iframe__wrapper">
      <MutationObserver
        onAttributeChange={(e) => {
          // On last attribute change
          if (e.target.getAttribute('name') === 'cd_timezone') {
            // Get form name identifier
            const formName = getTrackingFormName(id)

            // Get name input field
            const name = document.querySelector('#clickdimensionsForm .responsiveRow input')

            // Use keydown event to track it as 'form start'
            name.addEventListener('keydown', () => {
              if (!formStartTracked) {
                if (formName === 'recrutement') {
                  Tracker('UCS002')()
                } else if (formName === 'impartition') {
                  Tracker('UCS005')()
                }

                // Don't trigger tracking again
                formStartTracked = true
              }
            })
          }
        }}
      >
        <div>
          <HtmlParser string={`<div pageID="${id}"></div>`} />
        </div>
      </MutationObserver>
    </div>
  )
}

Widget.propTypes = {
  id: PropTypes.string
}

export default Widget
