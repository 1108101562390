import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint'
import classnames from 'classnames'
import Button, { CMS_BUTTON_TYPE } from 'components/_ui/Button/index'

import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'
import { IProps } from 'components/_paragraphs/CTAImageText/index'
import './horizontal.css'

export interface IState {
  active: boolean
}

class Horizontal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      active: false
    }

    this.onBlockEnterViewport = this.onBlockEnterViewport.bind(this)
  }

  onBlockEnterViewport() {
    this.setState({ active: true })
  }

  render() {
    const cls = classnames({
      'image-text': true,
      'image-text--horizontal': true,
      'image-text--active': this.state.active
    })
    const { pagePalette, buttonStyle } = this.props

    return (
      <ThemeConsumer>
        {(theme) => (
          <div className={cls}>
            <Waypoint onEnter={this.onBlockEnterViewport} bottomOffset="60%" />
            <span
              className="image-text__image"
              style={{
                backgroundImage: 'url(' + this.props.image + ')',
                backgroundColor: pagePalette.highlightColor
              }}
            />
            <div className="image-text__text">
              <div className="image-text__title">
                <div>
                  {/*@ts-ignore*/}
                  <h3 style={{ color: theme!.headingsColor }}>{this.props.title}</h3>
                  <Button
                    href={this.props.button.uri}
                    options={this.props.button.options}
                    primaryColor={
                      buttonStyle === CMS_BUTTON_TYPE.drakkar //@ts-ignore
                        ? theme.headingsColor
                        : pagePalette.highlightColor
                    }
                    secondaryColor={pagePalette.highlightColor}
                    granularStyles={{
                      [CMS_BUTTON_TYPE.drakkarDigital]: {
                        hoverColor: pagePalette.activeColor
                      }
                    }}
                    className="hide--mobile"
                  >
                    {this.props.button.title}
                  </Button>
                </div>
              </div>
              <p className="image-text__subtitle" style={{ color: pagePalette.paragraphColor }}>
                {this.props.subtitle}
              </p>
              <Button
                href={this.props.button.uri}
                className="show--mobile"
                primaryColor={
                  buttonStyle === CMS_BUTTON_TYPE.drakkar //@ts-ignore
                    ? theme.headingsColor
                    : pagePalette.highlightColor
                }
                granularStyles={{
                  [CMS_BUTTON_TYPE.drakkarDigital]: {
                    hoverColor: pagePalette.activeColor
                  }
                }}
                secondaryColor={pagePalette.highlightColor}
              >
                {this.props.button.title}
              </Button>
            </div>
          </div>
        )}
      </ThemeConsumer>
    )
  }
}

// Horizontal.propTypes = {
//   image: PropTypes.string.isRequired,
//   title: PropTypes.string.isRequired,
//   subtitle: PropTypes.string.isRequired
// }

export default Horizontal
