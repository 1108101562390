import React from 'react'

const Vimeo = ({ videoId }) => (
  <iframe
    title="vimeo-player"
    src={`https://player.vimeo.com/video/${videoId}`}
    frameBorder="0"
    allowFullScreen
  />
)

export default Vimeo
