import React from 'react'
import PropTypes from 'prop-types'
import t from 'typy'

import LargeButton from 'components/_paragraphs/Hero/LargeButton'
import Large from 'components/_paragraphs/Hero/Large'
import LargeColor from 'components/_paragraphs/Hero/LargeColor'
import Medium from 'components/_paragraphs/Hero/Medium'
import Small from 'components/_paragraphs/Hero/Small'

import 'components/_paragraphs/Hero/hero.css'

const Hero = (props) => {
  const { data, theme, pagePalette } = props
  const slides = data

  if (theme === 'theme_6') {
    return <LargeButton data={slides} buttonStyle={props.buttonStyle} pagePalette={pagePalette} />
  }

  if (theme === 'theme_5') {
    return <Large data={slides} pagePalette={pagePalette} />
  }

  if (theme === 'theme_4') {
    return <LargeColor data={slides} pagePalette={pagePalette} />
  }

  if (theme === 'theme_3') {
    return <Medium data={slides} buttonStyle={props.buttonStyle} pagePalette={pagePalette} />
  }

  return (
    <Small
      logo={props.logo}
      title={t(props.data[0].slide, 'title').safeObject}
      image={t(props.data[0].slide, 'imageSingle.url').safeObject}
      style={t(props.data[0], 'colorPalette.styleColors').safeObject}
      alignment={t(props.data[0].style, 'alignment').safeObject}
      theme={theme}
      data={slides}
      pagePalette={pagePalette}
    />
  )
}

Hero.propTypes = {
  theme: PropTypes.string,
  data: PropTypes.array.isRequired
}

Hero.defaultProps = {
  theme: 'theme_5'
}

export default Hero
