import { Base64 } from 'js-base64'
import { Gradient } from '../ts/DTO/content.colorpalette'
export type FixMeLater = any

/**
 *
 * @param string
 */
export const removeStringNewline = (string: string) => string.replace(/(\r\n\t|\n|\r\t)/gm, '')

/**
 *
 * @param array
 */
export const shuffleArray = (array: Array<any>) => {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

/**
 *
 * @param location
 */
export const routeCleaner = (location: string) => {
  const lang = getLang()
  const unsafePathArray = location.split('/').filter((item: string) => item.length && item)
  let safePath

  // If this is the root, render front page
  if (lang && unsafePathArray.length === 1) {
    return Base64.encode('<front>') + '?time=' + Date.now()
  }

  switch (unsafePathArray[1]) {
    case 'node':
      safePath = Base64.encode(unsafePathArray[2])
      break
    case 'search':
    case 'recherche':
      safePath = Base64.encode(decodeURIComponent(unsafePathArray.slice(2).join('/')))
      break
    default:
      safePath = Base64.encode(unsafePathArray.slice(1).join('/'))
      break
  }
  return safePath + '?time=' + Date.now()
}

// Get language from the pathname
// check for presence of ISO 639-1 two letter language code
// if no language is present, fall back to default language
// added via multisite.json
enum LangType {
  EN = 'en',
  FR = 'fr'
}
/**
 *
 */
export const getLang = (): LangType => {
  const splitPathname = window.location.pathname.split('/').filter((item) => {
    return item.length === 2 ? item : null
  })

  return splitPathname[0] as LangType
}

// Get user's preferred browser language
export const getBrowserLang = () => {
  const lang = (navigator.languages && navigator.languages[0]) || navigator.language

  // Two letter language code
  return lang.substr(0, 2)
}

/**
 *
 * @param color
 * @param opacity
 */
export const hexToRGBA = (color: string | null, opacity = 1): string => {
  //Check if null is passed
  if (!color) {
    return ''
  }

  // Check for # in front of the value
  if (color.substring(0, 1) === '#') {
    color = color.substring(1)
  }

  // If color is HEX at full opacity - don't convert
  if (opacity === 1) {
    return `#${color}`
  }

  // Grab each pair (channel) of hex values and parse
  const r = parseInt(color.substring(0, 2), 16)
  const g = parseInt(color.substring(2, 4), 16)
  const b = parseInt(color.substring(4), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

// https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#--version-2-rgb--
// USAGE:
// var color1 = "rbg(63,131,163)";
// var lighterColor = shadeRGBColor(color1, 0.5);  //  rgb(159,193,209)
// var darkerColor = shadeRGBColor(color1, -0.25); //  rgb(47,98,122)

/**
 *
 * @param color
 * @param percent
 */
export const shadeHexColor = (color: string | null, percent: number) => {
  if (!color) {
    return ''
  }

  var f = parseInt(color.slice(1), 16)
  var t = percent < 0 ? 0 : 255
  var p = percent < 0 ? percent * -1 : percent
  var R = f >> 16
  var G = (f >> 8) & 0x00ff
  var B = f & 0x0000ff
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  )
}

/**
 *
 * @param text
 */
export const removeParagraphFromString = (text: string) => {
  // replaceAll is new javascript ES2020 feature
  // so it isn't working on old browser
  // return text.replaceAll('<p>', '').replaceAll('</p>', '')

  // instead of above, use regex
  return text.replace(/<p>/g, '').replace(/<\/p>/g, '')
  // or use
  // return text.split('<p>').join('').split('</p>').join('')
}

// generates gradient image that can be applied on an element background
export const generateGradient = (gradient?: Gradient): string => {
  if (!gradient || !gradient.gradientColors || !gradient.gradientAngle) {
    return ''
  }

  return `linear-gradient(${gradient.gradientAngle.value}deg, ${gradient.gradientColors.color1}, ${gradient.gradientColors.color2})`
}

/**
 * Converts an inline style string into an object of React style properties
 *
 * @param {String} inlineStyle='' The inline style to convert
 * @returns {Object} The converted style
 */
export function inlineStyleToObject(inlineStyle = '') {
  // just return empty object if the inlineStyle is empty
  if (inlineStyle === '') {
    return {}
  }

  return inlineStyle.split(';').reduce((styleObject: FixMeLater, stylePropertyValue: string) => {
    // extract the style property name and value
    let [property, value] = stylePropertyValue
      .split(/^([^:]+):/)
      .filter((val, i) => i > 0)
      .map((item) => item.trim().toLowerCase())

    // if there is no value (i.e. no : in the style) then ignore it
    if (value === undefined) {
      return styleObject
    }

    // convert the property name into the correct React format
    // remove all hyphens and convert the letter immediately after each hyphen to upper case
    // additionally don't uppercase any -ms- prefix
    // e.g. -ms-style-property = msStyleProperty
    //      -webkit-style-property = WebkitStyleProperty
    property = property
      .replace(/^-ms-/, 'ms-')
      .replace(/-(.)/g, (_, character) => character.toUpperCase())

    // add the new style property and value to the style object
    styleObject[property] = value

    return styleObject
  }, {})
}
