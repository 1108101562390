import React, { useContext } from 'react'
import styled from 'styled-components'
import Button, { CMS_BUTTON_TYPE } from 'components/_ui/Button'
import LinkRouter from 'components/_utility/LinkRouter/index'
import { HeaderContext } from 'components/_global/Header/context'
import { MainMenuButtonColors } from 'components/_global/Header/MainMenu'

type ColorsType = {
  loadBackground?: string
  loadFontColorDefault?: string
  stickyBackground?: string
  stickyFontColorDefault?: string
}

type SLinkProps = {
  align: 'left' | 'right' | 'center'
  sticky?: boolean
  useLoadBackground: boolean
}

const SLink = styled.span<ColorsType & SLinkProps>`
  ${(props) =>
    props.useLoadBackground &&
    props.loadBackground &&
    `
    background-color: ${props.loadBackground};
  `}

  ${(props) =>
    props.loadFontColorDefault &&
    `
    color: ${props.loadFontColorDefault};
  `}

  ${(props) =>
    props.sticky &&
    props.stickyBackground &&
    `
    background-color: ${props.stickyBackground};
  `}

  ${(props) =>
    props.sticky &&
    props.stickyFontColorDefault &&
    `
    color: ${props.stickyFontColorDefault};
  `}

  ${(props) =>
    props.align === 'left' &&
    `
    justify-content: flex-start;
  `}

  ${(props) =>
    props.align === 'right' &&
    `
    justify-content: flex-end;
  `}
`

const SButton = styled(Button)`
  align-self: center;
  font-size: 1em;

  &.btn--rounded {
    padding: revert !important;
    letter-spacing: 0;
    line-height: 1.4;
  }
`

// When API returns 'null' for Contact menu, we're rendering nada
export interface ContactProps {
  type: 'link' | 'button'
  name: string
  url_str: string
  target: string
  hasTranslation: boolean
  sticky?: boolean
  buttonColors: MainMenuButtonColors
}

const ContactUs = (props: ColorsType & ContactProps) => {
  const header = useContext(HeaderContext)

  if (!props.url_str) {
    return null
  }

  if (props.type === 'link') {
    return (
      <SLink
        className="header__contact"
        loadBackground={props.loadBackground}
        loadFontColorDefault={props.loadFontColorDefault}
        stickyBackground={props.stickyBackground}
        stickyFontColorDefault={props.stickyFontColorDefault}
        align={header.mainMenuAlign}
        sticky={props.sticky}
        useLoadBackground={header.loadMenuStyle === 'opaque'}
      >
        <LinkRouter to={props.url_str} class="header__contact-label">
          {props.name}
        </LinkRouter>
      </SLink>
    )
  } else {
    return (
      <SButton
        href={props.url_str}
        buttonStyle={header.buttonStyle}
        primaryColor={props.buttonColors.highlightColor}
        granularStyles={{
          [CMS_BUTTON_TYPE.drakkarDigital]: {
            hoverColor: props.buttonColors.activeColor
          }
        }}
      >
        {props.name}
      </SButton>
    )
  }
}

export default ContactUs
