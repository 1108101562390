// @react
import React from 'react'
// @components
import Hero from 'components/_paragraphs/Hero'
import ArticleTeaser from 'components/_sections/Article/ArticleTeaser'
import ArticleIntro from 'components/_sections/Article/ArticleIntro'
import ArticleRelated from 'components/_sections/Article/ArticleRelated'
import ArticleShareIcons from 'components/_sections/Article/ArticleShareIcons'
import { themeSanitizer } from 'components/_sections/Page/Section/ThemeContext'
import PageSection from 'components/_sections/Page/Section'
import { PageContextConsumer } from 'components/_sections/Page/Wrapper/context'
// @common
import { getLang } from 'utils'
import { ApiResponse } from 'ts/DTO'

/*******************************************************
 * TYPES
 *******************************************************/
type PropsType = {
  data: ApiResponse
}

const PageBuilder = ({ data }: PropsType) => (
  <PageContextConsumer>
    {({ pagePalette, pageButtonStyle }) => {
      const heroData = data.content.hero
      const { fields } = data.content
      const { meta } = data
      const { type, date } = meta
      const displayDate = meta.display_date
      const displayShare = meta.display_share
      const relatedArticles = data.content.relatedArticles

      const shareIconLocation = {
        top: true,
        bottom: true
      }

      const articleColors = themeSanitizer(data.content.colorPalette.articleColors) || {}

      const hero = !heroData ? (
        // NOTE: When there's no Hero data, show Blog header
        // DRAK-603 | Remove hero/image for 'article' meta type
        <ArticleIntro
          date={displayDate && date}
          type={type}
          title={fields.title.content}
          image={fields?.field_image?.content.url}
          // @ts-ignore
          highlightColor={articleColors.highlightColor || pagePalette.highlightColor}
          // @ts-ignore
          buttonStyle={pageButtonStyle}
        />
      ) : (
        <Hero
          data={heroData.slides.content}
          theme={heroData.theme}
          // @ts-ignore
          buttonStyle={pageButtonStyle}
          pagePalette={pagePalette}
        />
      )

      // Article page intro fields
      const isArticlePage = type === 'article'
      const articleImage = fields.field_image?.content.url
      const articleAuthor = fields.field_author?.content
      const articleSubtitle = fields.field_subtitle?.content
      const articleTeaser = fields.field_text?.content
      const displayArticleTeaser = fields.field_display_teaser?.content === '1'
      const displayArticleImage = fields.field_display_image?.content === '1'

      // Loop through page content and display sections
      const sectionData = fields.field_para_reference.content
      // @ts-ignore
      const sections = sectionData.map((section, idx: number) => {
        return (
          <PageSection
            key={idx}
            sectionNumber={idx}
            data={section}
            meta={data.meta}
            anchor={section.content.anchor}
            pagePalette={pagePalette}
            buttonStyle={pageButtonStyle}
          />
        )
      })
      // If there is no content(images/text-blocks or sectionData itself) in article (hide social icons on bottom)
      // @ts-ignore
      const articleInnerContent = !!sectionData[0]?.content?.paragraphs
      // Check the paragraphs to find the padding of the elements on page (normal/large)
      // Fix padding for social icons based on text block elements
      let textBlockSections
      // @ts-ignore
      if (sectionData.length) {
        // @ts-ignore
        textBlockSections = sectionData[0]?.content?.paragraphs?.filter(
          // @ts-ignore
          (element) => element.type === 'text_block'
        )
      }
      //Set default to normal if no padding style found
      let padding = 'normal'
      if (textBlockSections) {
        padding = textBlockSections[0]?.style?.padding
          ? textBlockSections[0]?.style?.padding
          : 'normal'
      }
      const normalPadding = padding === 'normal'
      const lang = getLang()
      const email =
        lang === 'en'
          ? {
              subject: `This article may interest you!`,
              body: `I just came across this article and thought it might interest you. Take a look! %0D%0A %0D%0A ${window.location.href}`
            }
          : {
              subject: `Cet article pourrait t'intéresser!`,
              body: `Je viens de voir cet article et j'ai pensé qu'il pourrait t'intéresser. À y jeter un coup d'œil! %0D%0A %0D%0A ${window.location.href}`
            }

      //  const articleTeaserBackground = themeSanitizer(fields?.field_para_reference?.content[0].colorPalette.themeColors)

      return (
        <div className={`page-builder page-builder--${type}`}>
          {hero}
          {isArticlePage && (
            <ArticleTeaser
              displayArticleTeaser={displayArticleTeaser}
              articleSubtitle={articleSubtitle}
              articleAuthor={articleAuthor}
              articleTeaser={articleTeaser}
              displayArticleImage={displayArticleImage}
              articleImage={articleImage}
              articleImageAlt={fields.title.content}
              style={{
                // @todo fix this
                backgroundColor: ''
              }}
              theme={pagePalette}
              displayShare={displayShare}
              title={fields.title.content}
              shareIconsTop={shareIconLocation.top}
              normalPadding={normalPadding}
              email={email}
            />
          )}
          {sections}
          {/* show share icons at bottom, if related articles are present less padding, else take above text-blocks padding */}
          {isArticlePage && displayShare && shareIconLocation.bottom && articleInnerContent && (
            <div className={`${relatedArticles ? '' : normalPadding ? '' : 'text-block--padded'}`}>
              <ArticleShareIcons title={fields.title.content} email={email} positionBottom={true} />
            </div>
          )}
          <ArticleRelated articles={relatedArticles} paragraphColor={pagePalette.paragraphColor} />
        </div>
      )
    }}
  </PageContextConsumer>
)

export default PageBuilder
