import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import Button, { CMS_BUTTON_TYPE } from 'components/_ui/Button'
import InlineSVG, { IconNameType } from 'components/_ui/InlineSVG'
import SearchForm from 'components/_sections/Search/Form/index'
import { Strings } from 'components/_utility/Strings'
import { ColorNamesOf, PreFooterColors } from 'ts/DTO/content.colorpalette'
import { StyleColorsType, PreFooterColorsType } from 'components/_global/Footer'
import { SocialMedia } from '../../../../ts/DTO/content.config'
import { FixMeLater } from 'ts/types'

const Wrap = styled.div<ColorNamesOf<PreFooterColors>>`
  ${(props) =>
    props.backgroundColor &&
    `
    background-color: ${props.backgroundColor};
  `}

  ${(props) =>
    props.fontColor &&
    `
    color: ${props.fontColor};
  `}

  ${(props) =>
    props.fontColorActive &&
    `
    .btn:hover,
    a:hover,
    .footer__search-toggle:hover {
      color: ${props.fontColorActive};
    }
  `}
`

const Search = styled.div<ColorNamesOf<PreFooterColors>>`
  ${(props) =>
    props.searchFont &&
    `
    color: ${props.searchFont};

    input {
      ::placeholder {
        opacity: 1;
      }
    }
  `}

  ${(props) =>
    props.searchBackgroundColor &&
    `
    background-color: ${props.searchBackgroundColor};
  `}
`

// NOTE: The order of social icons comes from this array
// since the API response is an object with keyed entries
// if any new icons are added in the CMS, then also add it to the array below

export interface ButtonOptionsType {
  buttonCompanionText: string
  buttonLabel: string
  buttonLink?: string
}

export interface PropsType {
  buttonOptions: ButtonOptionsType
  socialCompanionText: string | null
  socialIcons: SocialMedia
  lang: string
  search: boolean
  toggleSearch: React.MouseEventHandler<HTMLDivElement>
  styleColors: StyleColorsType
  colors: PreFooterColorsType
  showSearch: boolean
  buttonStyle?: FixMeLater
  highlightColor?: string
}
type SocialMediaKeys = keyof SocialMedia
const socialIconsOrder: SocialMediaKeys[] = [
  'facebook',
  'twitter',
  'instagram',
  'linkedin',
  'youtube',
  'vimeo'
]

/**
 *
 * @param buttonOptions
 * @param socialCompanionText
 * @param socialIcons
 * @param lang
 * @param search
 * @param toggleSearch
 * @param styleColors
 * @param colors
 * @param showSearch
 * @param buttonStyle
 * @constructor
 */
const Top = ({
  buttonOptions,
  socialCompanionText,
  socialIcons,
  lang,
  search,
  toggleSearch,
  styleColors,
  colors,
  showSearch,
  buttonStyle
}: PropsType) => {
  const icons = socialIconsOrder.map((platform, idx) => {
    if (socialIcons.hasOwnProperty(platform) && socialIcons[platform]) {
      return (
        <li key={platform}>
          <a target="_blank" rel="noopener noreferrer" href={socialIcons[platform]}>
            <InlineSVG icon={platform} />
          </a>
        </li>
      )
    }
    return null
  })

  return (
    <Wrap className={`footer__top ${icons.length > 4 ? 'social-crowded' : ''}`} {...colors}>
      <div className="wrapper">
        <div className="footer__row">
          <div className="footer__row">
            <div className="footer__col">
              {buttonOptions.buttonCompanionText && (
                <span className="footer__title">{buttonOptions.buttonCompanionText}</span>
              )}
              {buttonOptions.buttonLink && buttonOptions.buttonLink && (
                <Button
                  target="_blank"
                  options={{ gtm_id: 'UCS012' }}
                  href={buttonOptions.buttonLink}
                  buttonStyle={buttonStyle}
                  granularStyles={{
                    [CMS_BUTTON_TYPE.rounded]: {
                      highlightColor: styleColors.highlightColor
                    }
                  }}
                >
                  {buttonOptions.buttonLabel}
                </Button>
              )}
            </div>
            <div className="footer__col footer__col--social">
              <span className="footer__title">
                {socialCompanionText ? socialCompanionText : Strings.footer.followUs[lang]}
              </span>
              <ul className="footer__social">{icons}</ul>
              {showSearch && (
                <div className="footer__search-toggle hide--mobile" onClick={toggleSearch}>
                  <InlineSVG icon="search" />
                </div>
              )}
            </div>
          </div>
          <Search
            className={classnames({
              footer__search: true,
              'footer__search--show': search,
              'hide--mobile': true
            })}
            {...colors}
          >
            <div className="wrapper">
              <SearchForm navigate lang={lang} />
              <span className="toggler" onClick={toggleSearch}>
                <InlineSVG icon="close" />
              </span>
            </div>
          </Search>
        </div>
      </div>
    </Wrap>
  )
}
export default Top
