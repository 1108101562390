import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import YouTube from 'react-youtube'
import Vimeo from 'components/_ui/Video/Vimeo'

import 'components/_ui/Video/video.css'

// TODO: Find a way to remove scroll hijacking
const Video = ({ id, padding }) => {
  // TODO: For best results, maybe refactor this to be determined on the URL
  // or some other sort of API flag instead of regex on id

  // Vimeo id is only digits, usually 8 or 9 length
  // Youtube is digits, _ and characters, varaible length, usually 11 chars
  const isVimeoId = new RegExp(/^[0-9]+$/g).test(id)

  return (
    <div
      className={classnames({
        wrapper: padding !== 'none',
        'video-wrapper': true,
        'video-wrapper--padded': padding === 'large'
      })}
    >
      <div className="video">
        {isVimeoId && <Vimeo videoId={id} />}
        {!isVimeoId && <YouTube videoId={id} />}
      </div>
    </div>
  )
}

Video.propTypes = {
  id: PropTypes.string.isRequired,
  padding: PropTypes.string
}

Video.defaultProps = {
  padding: 'normal'
}

export default Video
