import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import InlineSVG from 'components/_ui/InlineSVG'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'

import 'sass/vendor/slick.css'
import 'sass/vendor/slick-overrides.css'
import 'components/_paragraphs/Testimonials/testimonials.css'

const SliderPrevArrow = (props) => {
  const { className, style, onClick, color } = props
  return (
    <div className={className} style={{ ...style, color: color }} onClick={onClick}>
      <InlineSVG icon="caret-left" />
    </div>
  )
}

SliderPrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
}

const SliderNextArrow = (props) => {
  const { className, style, onClick, color } = props
  return (
    <div className={className} style={{ ...style, color: color }} onClick={onClick}>
      <InlineSVG icon="caret-right" />
    </div>
  )
}

SliderNextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
}

const Testimonials = (props) => (
  <ThemeConsumer>
    {(theme) => {
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: <SliderPrevArrow color={theme.highlightColor} />,
        nextArrow: <SliderNextArrow color={theme.highlightColor} />,
        responsive: [
          {
            breakpoint: 980,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }

      const slides = props.slides.map((slide, idx) => (
        <div className="testimonials__slide" key={idx}>
          <h3 className="testimonials__slide-title" style={{ color: theme.headingsColor }}>
            {slide.name} <br /> {slide.position}
          </h3>
          <span className="testimonials__slide-company" style={{ color: theme.paragraphColor }}>
            {slide.company}
          </span>
          <p className="testimonials__slide-quote" style={{ color: theme.paragraphColor }}>
            "{slide.quote}"
          </p>
        </div>
      ))

      return (
        <div className="testimonials">
          <div className="wrapper" style={{ color: theme.highlightColor }}>
            <Slider className="testimonials__slides slick-has-dots" {...settings}>
              {slides}
            </Slider>
          </div>
        </div>
      )
    }}
  </ThemeConsumer>
)

Testimonials.propTypes = {
  title: PropTypes.string,
  quote: PropTypes.string,
  theme: PropTypes.string,
  company: PropTypes.string,
  position: PropTypes.string,
  slides: PropTypes.array.isRequired
}

export default Testimonials
