import React from 'react'
import classnames from 'classnames'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import { removeStringNewline } from 'utils'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'

import 'components/_paragraphs/Wysiwyg/TextBlock/text-block.css'

type PropsType = {
  text: string
  padding: string
}

const TextBlock = ({ text, padding = 'normal' }: PropsType) => {
  const cls = classnames({
    'text-block': true,
    'text-block--padded': padding !== 'normal'
  })

  return (
    <ThemeConsumer>
      {(theme) => (
        <div className={cls}>
          <div className="wrapper wysiwyg" style={{ color: theme.paragraphColor }}>
            <HtmlParser string={removeStringNewline(text)} />
          </div>
        </div>
      )}
    </ThemeConsumer>
  )
}

export default TextBlock
