import React from 'react'
import Helmet from 'react-helmet'

type SelfProps = {
  wisepopsId: string
}
/**
 *
 * @returns {*}
 * @constructor
 */
const WisePops = ({ wisepopsId }: SelfProps) => {
  return (
    <Helmet
      script={[
        {
          type: 'text/javascript',
          innerHTML:
            `window['WisePopsObject'] = wisepops;` +
            `window[wisepops] = window[wisepops] || function() {(window[wisepops].q = window[wisepops].q || []).push(arguments);}, window[wisepops].l = 1 * new Date(); ` +
            `o = document.createElement('script'), p = document.getElementsByTagName('script')[0];` +
            `o.defer = 1;` +
            `o.src = e;` +
            `p.parentNode.insertBefore(o, p);`
        },
        {
          src: `//loader.wisepops.com/get-loader.js?v=1&site=${wisepopsId}`,
          type: 'text/javascript',
          'data-cfasync': 'false'
        }
      ]}
    />
  )
}

export default WisePops
