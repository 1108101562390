import React from 'react'
import classnames from 'classnames'
import 'components/_ui/InlineSVG/inlineSVG.css'
import { icons } from './icons'

/*******************************************************
 * TYPES
 *******************************************************/
export type IconNameType = keyof typeof icons

type PropsType = {
  icon: IconNameType
  className?: string
  onClick?: () => void
  style?: any
}

/**
 *
 * @param icon
 * @param onClick
 * @param style
 * @param className
 * @constructor
 */
const InlineSVG = ({ icon, onClick, style, className }: PropsType) => (
  <span
    className={classnames({
      [`icon icon--${icon}`]: true,
      [`${className}`]: className
    })}
    onClick={onClick}
    style={{ ...style }}
  >
    {icons[icon]}
  </span>
)

export default InlineSVG
