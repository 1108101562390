import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import LinkRouter from 'components/_utility/LinkRouter'
import InlineSVG from 'components/_ui/InlineSVG'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import { removeParagraphFromString, generateGradient } from 'utils'
import t from 'typy'
import { themeSanitizer } from 'components/_sections/Page/Section/ThemeContext'
import { SlidesContent } from 'ts/DTO/content.hero'
import mq from 'constants/mediaQueries'
import useConfig from 'components/hooks/useConfig'

import 'components/_paragraphs/Hero/LargeColor/large-color.css'

const Wrap = styled.div<{ bgColor?: string; textColor?: string }>`
  ${(props) =>
    props.bgColor &&
    `
    .slider__nav-wrapper {
      background-color: transparent;

      ${mq.to(mq.breakpoint.tablet)} {
        background-color: ${props.bgColor};
      }
    }

    .slider__nav {
      background-color: inherit;
    }
  `}

  ${(props) =>
    props.textColor &&
    `
    .slider__nav-item {
      ${mq.to(mq.breakpoint.tablet)} {
        color: ${props.textColor};
      }
    }
  `}
`

const SlideImage = styled.picture<{ desktop: string | null; mobile: string | null }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: no-repeat 50% / cover transparent;

  ${(props) =>
    props.desktop &&
    `
    background-image: url(${props.desktop});
  `}

  ${(props) =>
    props.mobile &&
    `
    @media (max-width: 640px) {
      background-image: url(${props.mobile});
    }
  `}
`

type PropsType = {
  data: SlidesContent[]
  pagePalette: any
}

const Large = (props: PropsType) => {
  const { getConfig } = useConfig()
  const config = getConfig()
  let slides = []
  let nav = []

  let navBgColor = ''
  let navTextColor = ''

  // Only DRAKKAR and DRAKKAR Digital portals use the striped Drakkar logo
  let showDrakkarLogo = config.title === 'Drakkar' || config.title === 'Drakkar Digital'

  for (let i = 0; i < props.data.length; i++) {
    const item = props.data[i]
    const { alignment } = item.style
    const idx = i

    const styles = themeSanitizer(t(item, 'colorPalette.styleColors').safeObject) as any
    const gradient = item.colorPalette.styleColors.gradient
    const pageGradient = props.pagePalette.gradient
    const pageHighlightColor = props.pagePalette.highlightColor

    // Make highlightColor actually be backgroundColor
    if (styles && styles.highlightColor) {
      styles.backgroundColor =
        generateGradient(gradient) ||
        styles.highlightColor ||
        generateGradient(pageGradient) ||
        pageHighlightColor
    }

    // Show all slides for Large, slow only first slide for theme_4
    if (i < 1) {
      // set nav bg/text colors by using first slide styles
      navBgColor = styles.highlightColor
      navTextColor = styles.activeColor

      const desktopImage = item.slide.imageSingle ? item.slide.imageSingle.url : null
      const mobileImage = item.slide.imageMobile ? item.slide.imageMobile.url : null

      const slideImage =
        desktopImage || mobileImage ? (
          <SlideImage desktop={desktopImage} mobile={mobileImage} />
        ) : null

      // disable the logo if there's a background image
      showDrakkarLogo = showDrakkarLogo && !slideImage

      slides.push(
        <div
          key={idx}
          className={classnames({
            slider__slide: true,
            [`slider__slide--${alignment}`]: alignment
          })}
          style={{ background: styles.backgroundColor }}
        >
          {slideImage}
          {showDrakkarLogo && <InlineSVG icon="logo-stripes" />}
          <div className="wrapper">
            <h1 className="slider__title">
              <HtmlParser string={removeParagraphFromString(item.slide.title)} />
            </h1>
          </div>
        </div>
      )
    }

    const isExternal = item.slide.cta.options.isExternal === 'true'
    const { uri } = item.slide.cta
    nav.push(
      <LinkRouter
        key={idx}
        to={uri}
        target={isExternal ? '_blank' : null}
        // @ts-ignore
        rel={isExternal ? 'noreferrer noopener' : null}
        class="slider__nav-item"
      >
        <span>
          <HtmlParser string={item.slide.cta.title} />
        </span>
      </LinkRouter>
    )
  }

  // Don't show the slider nav if there's one slide only
  const sliderNav = props.data.length <= 1 ? false : nav

  return (
    <Wrap
      bgColor={navBgColor}
      textColor={navTextColor}
      className="slide slider--large slider--large-4"
    >
      <div className="slider__slides">{slides}</div>
      <div className="slider__nav-wrapper wrapper">
        <div>
          <div className="slider__nav">{sliderNav}</div>
        </div>
      </div>
    </Wrap>
  )
}

export default Large
