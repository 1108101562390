import React from 'react'
import { generateGradient, hexToRGBA } from 'utils'

/*******************************************************
 * TYPE
 *******************************************************/

export type SanitizedTheme<T> = {
  [index in keyof T]: string
}
type InitialTheme = {
  activeColor: string
  highlightColor: string
  logoColor: string
  gradient: string
  backgroundColor: string
  headingsColor: string
  paragraphColor: string
  sponsoredContentColor: string
}

/*******************************************************
 * CONST
 *******************************************************/
const initialTheme: SanitizedTheme<InitialTheme> = {
  activeColor: '',
  highlightColor: '',
  logoColor: '',
  gradient: '',
  backgroundColor: '',
  headingsColor: '',
  paragraphColor: '',
  sponsoredContentColor: ''
}

/*******************************************************
 * THEME CONTEXT SETUP
 *******************************************************/
const Context = React.createContext<SanitizedTheme<InitialTheme>>(initialTheme)
export const ThemeProvider = Context.Provider
export const ThemeConsumer = Context.Consumer

/**
 * Sanitize colors - convert to RGBA based on hex/opacity from the CMS
 * @param theme
 */
export const themeSanitizer = <T>(
  theme?: T
): SanitizedTheme<T> | SanitizedTheme<InitialTheme> | any => {
  if (!theme) {
    return {}
  }

  /***
   * This is a formatting function to make sure that
   * gradient and opacity etc are in a more theme friendly format
   * @todo figure out the reducer key types
   */
  // @ts-ignore
  const sanitized: SanitizedTheme<T> = Object.keys(theme).reduce((acc, key) => {
    // Special parsing for 'gradient' key
    // @ts-ignore
    if (key === 'gradient' && !!theme['gradient']) {
      // @ts-ignore
      const gradient = generateGradient(theme['gradient'])
      if (gradient) {
        // @ts-ignore
        acc[key] = gradient
      }
      return acc
    }
    const newKey: keyof Omit<T, 'gradient'> = key as keyof Omit<T, 'gradient'>
    // all other cases

    // @ts-ignore
    const color = theme[newKey]?.color

    // @ts-ignore
    const opacity = (theme[newKey]?.opacity && parseFloat(theme[newKey].opacity)) || 1

    if (color) {
      // @ts-ignore
      acc[newKey] = hexToRGBA(color, opacity)
    }
    return acc
  }, {})

  return sanitized
}
