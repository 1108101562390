import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'

import 'components/_ui/Accordion/accordion.css'

class Accordion extends Component {
  render() {
    const panels = this.props.panels.map((panel, idx) => (
      <Panel
        key={idx}
        id={idx}
        text={panel.numberingText}
        title={panel.title}
        wysiwyg={panel.content}
      />
    ))

    return (
      <div className="accordion">
        <div className="wrapper">{panels}</div>
      </div>
    )
  }
}

class Panel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false
    }

    this.bodyRef = React.createRef()
    this.onHeaderClick = this.onHeaderClick.bind(this)
  }

  componentDidMount() {
    this.body = this.bodyRef.current
  }

  onHeaderClick() {
    const panelBody = this.body
    const active = !this.state.active
    this.setState({ active })

    if (panelBody.style.maxHeight) {
      panelBody.style.maxHeight = null
    } else {
      panelBody.style.maxHeight = panelBody.scrollHeight + 'px'
    }
  }

  render() {
    const classes = classnames({
      accordion__panel: true,
      'accordion__panel--active': this.state.active
    })

    return (
      <ThemeConsumer>
        {(theme) => {
          const style = this.state.active
            ? {
                color: theme.activeColor,
                backgroundColor: theme.highlightColor,
                borderColor: theme.highlightColor
              }
            : { color: theme.headingsColor, borderColor: theme.highlightColor }

          return (
            <div key={this.props.id} className={classes}>
              <div className="accordion__header" onClick={this.onHeaderClick} style={style}>
                <span className="accordion__counter">{this.props.text}</span>
                <h4>{this.props.title}</h4>
                <span className="accordion__toggle" />
              </div>
              <div className="accordion__body wysiwyg" ref={this.bodyRef}>
                <div style={{ color: theme.paragraphColor }}>
                  <HtmlParser key={this.props.id} string={this.props.wysiwyg} />
                </div>
              </div>
            </div>
          )
        }}
      </ThemeConsumer>
    )
  }
}

Panel.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  wysiwyg: PropTypes.string.isRequired
}

Accordion.propTypes = {
  panels: PropTypes.array.isRequired
}

export default Accordion
