// @react
import React, { useRef, useState } from 'react'
// @common
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import Slider from 'react-slick'
import { removeParagraphFromString, generateGradient } from 'utils'
// @components
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import { themeSanitizer } from 'components/_sections/Page/Section/ThemeContext'
import Video from 'components/_paragraphs/Hero/Video'
// @styles
import * as SC from './style'
import 'sass/vendor/slick.css'
import 'sass/vendor/slick-overrides.css'
import 'components/_paragraphs/Hero/Large/large.css'

/**
 *
 * @param data
 * @param pagePalette
 * @constructor
 */
const Large = ({ data, pagePalette }: { data: any; pagePalette: any }) => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const [active, setActive] = useState<number>()
  const sliderTop = useRef<Slider>(null)
  const sliderNavRef = useRef<Slider>(null)

  const styles = data.map((item: any) => themeSanitizer(item.colorPalette.styleColors))

  /*******************************************************
   * CONST
   *******************************************************/

  const settingSlider = {
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    focusOnSelect: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false
  }

  const settingsNav = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: data.length,
    focusOnSelect: false
  }

  // Don't show the slider nav if there's one slide only
  const showSliderNav = data.length > 1

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  const onSliderSlideChange = (oldIdx: number, newIdx: number) => {
    sliderNavRef.current?.slickGoTo(newIdx)
    setActive(newIdx)
  }

  const onSliderNavMouseover = (idx: any) => {
    sliderTop.current?.slickPause()
    sliderTop.current?.slickGoTo(idx)
    sliderTop.current?.slickPlay()
  }

  // Use first slides colors for the navigation background/text
  let navBgColor = styles[0].highlightColor
  let navTextColor = styles[0].activeColor

  /**
   *
   */
  const slides = data.map((item: any, idx: number) => {
    const { alignment } = item.style
    const { videos } = item.slide
    const style = styles[idx]
    const highlightColor = style?.highlightColor
    const gradient = item?.colorPalette.styleColors.gradient
    const pageGradient = pagePalette.gradient
    const pageHighlightColor = pagePalette.highlightColor

    return (
      <div key={idx} style={{ background: gradient ? generateGradient(gradient) : '' }}>
        <SC.StyledSlide
          className={classnames({
            slider__slide: true,
            [`slider__slide--${alignment}`]: alignment,
            'slider__slide--active': active === idx
          })}
          imageSingle={item?.slide?.imageSingle?.url}
          imageMobile={item?.slide?.imageMobile?.url}
        >
          <Video data={videos} />
          <span
            className="bg-accent"
            style={{
              background:
                generateGradient(gradient) ||
                style.highlightColor ||
                generateGradient(pageGradient) ||
                pageHighlightColor
            }}
          />
          <div className="wrapper">
            {idx === 0 && (
              <h1 className="slider__title">
                <HtmlParser
                  string={removeParagraphFromString(item.slide.title)}
                  highlightColor={highlightColor}
                />
              </h1>
            )}
            {idx !== 0 && (
              <div className="slider__title">
                <HtmlParser string={item.slide.title} highlightColor={highlightColor} />
              </div>
            )}
          </div>
        </SC.StyledSlide>
      </div>
    )
  })

  const nav = data.map((item: any, idx: number) => {
    const { cta } = item.slide
    const style = styles[idx]
    const highlightColor = style?.highlightColor

    const isExternal = cta && cta.options.isExternal === 'true'

    const options = {
      key: idx,
      to: !isExternal ? cta && cta.uri : null,
      href: isExternal ? cta && cta.uri : null,
      target: isExternal ? '_blank' : null,
      rel: isExternal ? 'noreferrer noopener' : null,
      className: classnames({
        'slider__nav-item': true,
        'slider__nav-item--active': active === idx
      }),
      style: {
        active: active === idx,
        hoverColor: highlightColor
      }
    }

    if (cta) {
      return (
        <div key={options.key}>
          <div
            onMouseEnter={() => {
              onSliderNavMouseover(idx)
            }}
          >
            <SC.StyledNavLink
              {...options}
              // @ts-ignore
              as={!isExternal && NavLink}
            >
              <span>
                <HtmlParser string={cta.title} />
              </span>
            </SC.StyledNavLink>
          </div>
        </div>
      )
    }
    return null
  })

  /**
   *
   */
  return (
    <SC.Wrap bgColor={navBgColor} textColor={navTextColor} className="slider slider--large">
      <Slider
        className="slider__slides"
        ref={sliderTop}
        {...settingSlider}
        beforeChange={onSliderSlideChange}
      >
        {slides}
      </Slider>

      {showSliderNav && (
        <div className="slider__nav-wrapper wrapper">
          <div>
            <Slider
              className={classnames({
                slider__nav: true,
                'slider__nav--stacked': data.length >= 4
              })}
              ref={sliderNavRef}
              {...settingsNav}
            >
              {nav}
            </Slider>
          </div>
        </div>
      )}
    </SC.Wrap>
  )
}

export default Large
