import React from 'react'
import classnames from 'classnames'
import Button from 'components/_ui/Button'

import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'
import { ButtonStyle as ButtonStyleType } from 'ts/DTO/content.config'
import './section-title.css'

export interface buttonType {
  uri?: string
  options?: string
  title: string
}

export interface MyPropTypes {
  title: string
  theme: string
  button: buttonType | null
  className?: string
  separator: boolean
  buttonStyle: ButtonStyleType
}

/**
 *
 * @param props
 * @constructor
 */
const SectionTitle = (props: MyPropTypes) => {
  const cls = classnames({
    'section-title': true,
    'section-title--bullets': props.theme === 'Theme 1',
    'section-title--left': props.theme === 'Theme 2',
    'section-title--5': props.theme === 'Theme 5',
    'section-title--border': props.separator,
    [`${props.className}`]: props.className
  })

  const button = !props.button ? (
    <></>
  ) : (
    <Button href={props.button.uri} options={props.button.options} buttonStyle={props.buttonStyle}>
      {props.button.title}
    </Button>
  )

  return (
    <ThemeConsumer>
      {(theme) => (
        <header
          className={cls}
          // @ts-ignore
          style={{ color: theme.headingsColor }}
        >
          <div className="wrapper">
            <div>
              <h2>
                <span>{props.title}</span>
              </h2>
              {button}
            </div>
          </div>
        </header>
      )}
    </ThemeConsumer>
  )
}
//@todo check about the default props removal
SectionTitle.defaultProps = {
  theme: 'theme_1'
}

export default SectionTitle
