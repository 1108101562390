import React from 'react'
import { PageContextConsumer } from 'components/_sections/Page/Wrapper/context'

import 'components/_ui/Button/button.css'

// Custom portal buttons
// Import after button.css to override those styles
import DrakkarButton from './Drakkar'
import DrakkarDigitalButton from './DrakkarDigital'
import RoundedCornersButton from './RoundedCorners'

// Portal button style is set through CMS
export const CMS_BUTTON_TYPE = {
  drakkar: 'DRAKKAR',
  drakkarDigital: 'DRAKKAR Digital',
  rounded: 'Rounded Corners'
}

const Button = (props) => (
  <PageContextConsumer>
    {({ pageButtonStyle }) => {
      // Use pageContext button style as the default
      // but allow button style overrides via props
      const switchStyle = props.buttonStyle || pageButtonStyle
      switch (switchStyle) {
        case CMS_BUTTON_TYPE.drakkar:
          return <DrakkarButton {...props} />
        case CMS_BUTTON_TYPE.drakkarDigital:
          return <DrakkarDigitalButton {...props} />
        default:
          return <RoundedCornersButton {...props} />
      }
    }}
  </PageContextConsumer>
)

export default Button
