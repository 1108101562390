import React from 'react'
import styled from 'styled-components'
import colors from 'constants/colors'
import mq from 'constants/mediaQueries'
import { ArticleGridContextConsumer } from 'components/_sections/Article/ArticleGrid/context'

const StyledBanner = styled.div`
  display: ${(props) => (props.hideTitle ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  min-height: 170px;
  margin-bottom: 1em;
  padding: 1em;
  background: no-repeat 50% / cover ${colors.darkBlue};
  color: ${colors.white};

  ${(props) =>
    props.highlightColor &&
    `
    background-color: ${props.highlightColor};
  `}

  ${(props) =>
    props.gradient &&
    `
    background-image: ${props.gradient};
  `}

  ${(props) =>
    props.activeColor &&
    `
    color: ${props.activeColor};
  `}

  ${(props) =>
    props.border &&
    `
    border: 1px solid #d1d5da;
  `}

  ${mq.from(mq.breakpoint.ipad)} {
    width: 285px;
    min-height: 415px;
    margin-bottom: 0;
    margin-right: 2em;
  }

  ${(props) =>
    props.spacer &&
    `
    min-height: auto !important;
    background-color: transparent;

    ${mq.to(mq.breakpoint.ipad)} {
      display: none;
    }
  `}

  ${(props) =>
    props.image &&
    `
    && {
      background-image: url(${props.image});
    }
  `}

  ${(props) =>
    props.imageMobile &&
    `
    && {
      ${mq.to(mq.breakpoint.ipad)} {
        background-image: url(${props.imageMobile});
      }
    }
  `}
`

const Text = styled.span`
  font-weight: 600;
  font-size: 2em;
  text-transform: uppercase;
  color: currentColor;
`

const Logo = styled.span`
  ${(props) =>
    props.svg &&
    `
    display: block;

    svg {
      width: 100%;
      max-height: 5em;
      color: ${colors.white};
    }
  `};
`

const Banner = ({ spacer, title, images, hideTitle }) => (
  <ArticleGridContextConsumer>
    {({ style, border, leftBlockPalette, tileBackgroundColor, toggleShareModal }) => {
      if (spacer) {
        return <StyledBanner spacer />
      }

      const { image, mobileImage, logoIcon } = images
      let logo = <Text>{title}</Text>

      if (logoIcon.url) {
        logo = (
          <Logo>
            <img src={logoIcon.url} alt={title} />
          </Logo>
        )
      }

      if (logoIcon.markup) {
        logo = <Logo svg dangerouslySetInnerHTML={{ __html: logoIcon.markup }} />
      }

      return (
        <StyledBanner
          image={image.url}
          imageMobile={mobileImage.url}
          highlightColor={leftBlockPalette.highlightColor || style.highlightColor}
          gradient={style.gradient}
          activeColor={style.activeColor}
          border={border}
          hideTitle={hideTitle}
        >
          {logo}
        </StyledBanner>
      )
    }}
  </ArticleGridContextConsumer>
)

export default Banner
