import React from 'react'
import { ButtonStyle, LoadMenuStyle, SocialMedia } from 'ts/DTO/content.config'

type ContextType = {
  mainMenuAlign: 'center' | 'left' | 'right'
  loadMenuStyle?: LoadMenuStyle
  buttonStyle?: ButtonStyle
  // Top Bar Social Media
  showTopBarSocial: boolean
  socialMedia?: SocialMedia
  // Force MEGA menu layout through the CMS
  forceMegaMenu?: boolean
  // Top Bar separator
  showTopSeparator: boolean
  // Top Bar visible on scroll?
  showTopOnScroll: boolean
}

const initialValues = {
  mainMenuAlign: 'center' as const,
  showTopBarSocial: false,
  showTopSeparator: false,
  showTopOnScroll: true
}

export const HeaderContext = React.createContext<ContextType>(initialValues)
export const HeaderContextProvider = HeaderContext.Provider

export default HeaderContextProvider
