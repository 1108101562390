import React, { Component } from 'react'
import ShareModal from 'components/_paragraphs/ShareModal'
import { ArticleGridContextProvider } from 'components/_sections/Article/ArticleGrid/context'

import LoadMore from 'components/_sections/Article/ArticleGrid/LoadMore'

import Theme1 from 'components/_sections/Article/ArticleGrid/Theme1'
import Theme2 from 'components/_sections/Article/ArticleGrid/Theme2'
import Theme3 from 'components/_sections/Article/ArticleGrid/Theme3'
import Theme4 from 'components/_sections/Article/ArticleGrid/Theme4'
import Theme5 from 'components/_sections/Article/ArticleGrid/Theme5'
import Theme6 from 'components/_sections/Article/ArticleGrid/Theme6'
import Slider from 'components/_sections/Article/ArticleGrid/Slider'

import 'components/_sections/Article/ArticleGrid/article-grid.css'

class ArticleGrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showShare: false
    }

    this.toggleShare = this.toggleShare.bind(this)
  }

  toggleShare(data) {
    this.setState((state) => {
      document.querySelector('body').style.overflow = !state.showShare ? 'hidden' : null

      return {
        showShare: !state.showShare,
        shareData: !state.showShare ? data : null
      }
    })
  }

  render() {
    const {
      articles,
      border,
      alignment,
      layout,
      style,
      tileBackgroundColor,
      loadMoreText,
      sponsoredLabel,
      // Slider props
      title,
      images,
      hideTitle,
      leftBlockPalette
    } = this.props
    const { showShare, shareData } = this.state

    if (!articles) {
      return null
    }

    let layoutGrid = null
    let layoutArticles = 0
    let sliderMode = false

    switch (layout) {
      case 'theme_1':
        layoutArticles = 2
        layoutGrid = <Theme1 articles={articles.slice(0, layoutArticles)} />
        break
      case 'theme_2':
        layoutArticles = 3
        layoutGrid = (
          <Theme2 layoutArticles={layoutArticles} articles={articles.slice(0, layoutArticles)} />
        )
        break
      case 'theme_3':
        layoutArticles = 5
        layoutGrid = (
          <Theme3 layoutArticles={layoutArticles} articles={articles.slice(0, layoutArticles)} />
        )
        break
      case 'theme_4':
        layoutArticles = 4
        layoutGrid = (
          <Theme4 layoutArticles={layoutArticles} articles={articles.slice(0, layoutArticles)} />
        )
        break
      case 'theme_5':
        layoutArticles = 6
        layoutGrid = (
          <Theme5 layoutArticles={layoutArticles} articles={articles.slice(0, layoutArticles)} />
        )
        break
      case 'theme_6':
        layoutArticles = 8
        layoutGrid = <Theme6 articles={articles.slice(0, layoutArticles)} />
        break
      case 'theme_7':
        layoutArticles = 11
        layoutGrid = (
          <React.Fragment>
            <Theme2 layoutArticles={layoutArticles} articles={articles.slice(0, 3)} />
            <Theme6 articles={articles.slice(3, layoutArticles)} />
          </React.Fragment>
        )
        break
      case 'theme_8':
        layoutArticles = 7
        layoutGrid = (
          <React.Fragment>
            <Theme2 layoutArticles={layoutArticles} articles={articles.slice(0, 3)} />
            <Theme4 layoutArticles={layoutArticles} articles={articles.slice(3, layoutArticles)} />
          </React.Fragment>
        )
        break
      case 'theme_9':
        layoutArticles = 8
        layoutGrid = (
          <React.Fragment>
            <Theme1 layoutArticles={layoutArticles} articles={articles.slice(0, 2)} />
            <Theme5 layoutArticles={layoutArticles} articles={articles.slice(2, layoutArticles)} />
          </React.Fragment>
        )
        break
      case 'theme_10':
        layoutArticles = 6
        layoutGrid = (
          <React.Fragment>
            <Theme1 layoutArticles={layoutArticles} articles={articles.slice(0, 2)} />
            <Theme4 layoutArticles={layoutArticles} articles={articles.slice(2, layoutArticles)} />
          </React.Fragment>
        )
        break
      case 'theme_11':
        layoutArticles = 12
        layoutGrid = (
          <React.Fragment>
            <Theme4 layoutArticles={layoutArticles} articles={articles.slice(0, 4)} />
            <Theme6 articles={articles.slice(4, layoutArticles)} />
          </React.Fragment>
        )
        break
      case 'theme_12':
      case 'theme_13':
      case '': // when layout hasn't been selected do the slider thing
      case null:
        sliderMode = true
        break
      default:
        break
    }

    return (
      <ArticleGridContextProvider
        value={{
          toggleShareModal: this.toggleShare,
          border: border,
          wrapAlignment: alignment,
          style: style,
          tileBackgroundColor: tileBackgroundColor.backgroundColor,
          loadMoreText: loadMoreText,
          sponsoredLabel: sponsoredLabel,
          leftBlockPalette: leftBlockPalette
        }}
      >
        <div className="article-grid-wrap">
          {!sliderMode && (
            <div className="wrapper">
              {layoutGrid}
              <LoadMore articles={articles.slice(layoutArticles)} />
            </div>
          )}

          {sliderMode && (
            <Slider
              layout={layout}
              title={title}
              images={images}
              articles={articles}
              hideTitle={hideTitle}
            />
          )}

          <ShareModal show={showShare} toggleShare={this.toggleShare} {...shareData} />
        </div>
      </ArticleGridContextProvider>
    )
  }
}

export default ArticleGrid
