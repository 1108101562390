import React from 'react'
import { hydrate, render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import TagManager from 'react-gtm-module'
import { QueryClient, QueryClientProvider } from 'react-query'
const queryClient = new QueryClient()
declare global {
  interface Window {
    thisConfig: any
  }
}

// @todo how to get the config HOC to cleanly find the GTM ID?
if (process.env.NODE_ENV === 'production' || String(process.env.NODE_ENV) === 'staging') {
  const thisConfig = window.thisConfig
  const tagManagerArgs = {
    gtmId: thisConfig.gtm_id,
    dataLayerName: 'dataLayer'
  }
  TagManager.initialize(tagManagerArgs)
}

const rootElement = document.getElementById('root')!
if (rootElement.hasChildNodes()) {
  hydrate(
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App config={window.thisConfig} />
      </BrowserRouter>
    </QueryClientProvider>,
    rootElement
  )
} else {
  render(
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App config={window.thisConfig} />
      </BrowserRouter>
    </QueryClientProvider>,
    rootElement
  )
}
