import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Hero from 'components/_paragraphs/Hero/Small'
import { themeSanitizer } from 'components/_sections/Page/Section/ThemeContext'
import axios from 'axios'

import { Strings } from 'components/_utility/Strings'

import 'components/_sections/Sitemap/sitemap.css'

class Sitemap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sitemap: false
    }
  }

  componentDidMount() {
    this.fetchEndpoint(this.props.api)
  }

  fetchEndpoint(endpoint) {
    axios
      .get(endpoint)
      .then((response) => {
        this.setState({
          loaded: true,
          sitemap: response.data,
          response: 200
        })
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            loaded: true,
            data: error.response.data,
            response: error.response.status
          })
          console.warn(
            "Couldn't fetch from",
            this.props.data.api,
            'due to',
            error,
            'node not found'
          )
        } else {
          console.warn(
            "Couldn't fetch from",
            this.props.data.api,
            'due to',
            error,
            'is the API down?'
          )
          this.setState({
            loaded: true,
            data: null,
            response: 500
          })
        }
      })
  }

  render() {
    const { data } = this.props
    // Get Page colorPalette and pass it down to PageSection
    const { colorPalette } = data.content
    const { themeColors, styleColors } = colorPalette || {}
    const pageColorPalette = { ...themeColors, ...styleColors }
    const sanitizedPageColorPalette = themeSanitizer(pageColorPalette)

    const title = Strings.sitemap.title[this.props.lang]
    const sitemap = !this.state.sitemap ? false : <List menu={this.state.sitemap} />

    return (
      <div className="page--sitemap">
        <Hero
          theme={'theme_1'}
          title={title}
          style={{
            backgroundColor: {
              color: sanitizedPageColorPalette.highlightColor
            }
          }}
        />
        <div className="wrapper">
          <div className="wysiwyg" style={{ color: sanitizedPageColorPalette.paragraphColor }}>
            {sitemap}
          </div>
        </div>
      </div>
    )
  }
}

const List = (props) => (
  <ul>
    {props.menu.map((item, idx) => (
      <li key={idx}>
        <a href={item.url_str}>{item.name}</a>
        {item.children && <List menu={item.children} />}
      </li>
    ))}
  </ul>
)

Sitemap.propTypes = {
  api: PropTypes.string.isRequired
}

export default Sitemap
