// This component is used to redirect to a different page by checking out
// API repsonse and redirecting to whatever path was supplied by the CMS
import React from 'react'
import { Redirect } from 'react-router-dom'
import { getLang } from 'utils'

const CMSRedirect = ({ isRedirected, redirectPath, location }) => {
  const lang = getLang()

  // External redirects are being handled in PageWrapper init/update callbacks
  // so that there's no content unnecessarily rendering
  // and no layout/painting for no reason

  // If on the same page or missing redirect params
  if (
    `/${lang}/${redirectPath}` === location.pathname ||
    !isRedirected ||
    !redirectPath ||
    redirectPath.startsWith('http')
  ) {
    return null
  }

  return <Redirect to={redirectPath} />
}

export default CMSRedirect
