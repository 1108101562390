import styled from 'styled-components'
import Button from 'components/_ui/Button'

/*******************************************************
 * STYLE
 *******************************************************/
export const StyledButton = styled(Button)`
  ${(props) =>
    props.style &&
    `
    &&:hover {
      color: ${props.style.hoverColor};
      ${
        props.arrowLeft &&
        `
        .btn__label {
          transition: all 0.4s ease;
          transform: translateX(0.7em);
        }
        .icon {
          transform: translate(1.2em, -50%) rotateZ(-180deg);
          opacity: 1;
          visibility: visible;
        }
      `
      }
    }
  `}

  ${(props) =>
    props.style &&
    props.style.backgroundColor &&
    `
    && {
      color: #fff;
      border: 0 !important;
      background-color: ${props.style.backgroundColor};
    }

    &&:hover {
      background-color: ${props.style.hoverBackgroundColor};
    }
  `}

  ${(props) =>
    props.style &&
    props.style.paddingBottom &&
    `
    margin-bottom: ${props.style.paddingBottom};
  `}

  ${(props) =>
    props.style &&
    props.style.paddingTop &&
    `
    margin-top: ${props.style.paddingTop};
  `}
`

type StyledTextLinkProps = {
  activeColor?: string
}

export const StyledTextLink = styled.span<StyledTextLinkProps>`
  ${(props) =>
    props.activeColor &&
    `
      &&:hover a{
      color: ${props.activeColor} !important;
    }`};
`
