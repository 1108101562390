// @react
import React from 'react'
// @common
import styled from 'styled-components'
import mq from 'constants/mediaQueries'
// @components
import Button from 'components/_ui/Button'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import { Teaser } from 'components/_sections/Article/ArticleGrid/Article'

const Category = styled.span`
  display: inline-block;
  margin-bottom: 26px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9em;

  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `};
`

const HoverOverlay = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s;
`

const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  z-index: 1;

  &:hover ${HoverOverlay} {
    opacity: 0.1;
  }

  &&& picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.625em;
    font-weight: 700;
    line-height: 1.25;
    font-weight: bold;
  }

  .btn {
    margin-top: 35px;
  }

  div {
    margin: 0;
  }

  ${(props) =>
    props.style.size === 'big' &&
    `
    .banner-articles--has-1 &,
    .article-grid--theme_1 & {
      min-height: 300px;

      ${mq.from(mq.breakpoint.tablet)} {
        min-height: 400px;
      }

      ${mq.from(mq.breakpoint.desktop)} {
        min-height: 550px;
      }
    }

    ${Category} {
      margin-bottom: 35px;
    }

    ${Teaser} {
      margin-bottom: 0;
    }

    h3 {
      margin: 0.5rem;

      + ${Teaser} {
        margin-top: 0.5rem;
      }
    }

    .btn {
      margin-top: 35px;
    }
  `}

  ${(props) =>
    props.style.verticalAlign === 'center' &&
    `
    justify-content: center;
  `}

  ${(props) =>
    props.style.verticalAlign === 'top' &&
    `
    justify-content: flex-start;
  `}

  ${(props) =>
    props.style.verticalAlign === 'bottom' &&
    `
    justify-content: flex-end;
  `}

  ${(props) =>
    props.style.textAlign === 'left' &&
    `
    align-items: flex-start;
  `}

  ${(props) =>
    props.style.textAlign === 'right' &&
    `
    align-items: flex-end;
  `}

  ${(props) =>
    props.style.textAlign === 'center' &&
    `
    align-items: center;
  `}

  .article-grid__article--small & {
    ${mq.from(550)} {
      flex-direction: row;
      align-items: center;
      padding-left: 0;
    }

    ${Category} {
      margin-bottom: 35px;
    }

    > div {
      margin: 0 auto;
      flex-grow: 1;

      ${mq.from(550)} {
        padding-left: 1.5em;
        padding-right: 1.5em;

        ${(props) =>
          props.style.textAlign === 'left' &&
          `
          margin-left: 0;
        `}

        ${(props) =>
          props.style.textAlign === 'right' &&
          `
          margin-right: 0;
        `}
      }

      div {
        margin: 0;
      }
    }

    button {
      ${mq.from(550)} {
        margin: 0;
        flex-shrink: 0;
      }
    }
  }
`

// something here
const ImageCard = ({
  title,
  displayTitle,
  imageDesktop,
  imageMobile,
  teaserText,
  cta,
  categoryText,
  alignment,
  wrapAlignment,
  tileBackground,
  style,
  size
}) => {
  let images = []
  if (imageDesktop) {
    if (imageMobile) {
      images.push(<source key={'dimg'} srcSet={imageDesktop} media="(min-width: 769px)" />)
    } else {
      images.push(<img key={'dimg'} src={imageDesktop} alt={title} />)
    }
  }
  if (imageMobile) {
    images.push(<img key={'mimg'} src={imageMobile} alt={title} />)
  }

  const hasHoverOverlay = !cta.title && cta.uri

  return (
    <Wrap
      className="article-grid__image-card"
      style={{
        size: size,
        textAlign: alignment.horizontal || wrapAlignment,
        verticalAlign: alignment.vertical,
        color: style.headingsColor,
        backgroundColor: !tileBackground && style.highlightColor
      }}
    >
      {images.length >= 1 && <picture>{images}</picture>}
      <div>
        {categoryText && (
          <Category style={{ color: style.highlightColor }}>{categoryText}</Category>
        )}
        {displayTitle && <h3>{title}</h3>}
        {teaserText && (
          <Teaser style={{ color: style.paragraphColor }}>
            <HtmlParser string={teaserText} />
          </Teaser>
        )}
      </div>
      {cta && cta.title && <Button>{cta.title}</Button>}
      {hasHoverOverlay && <HoverOverlay style={{ backgroundColor: style.highlightColor }} />}
    </Wrap>
  )
}

export default ImageCard
