import React from 'react'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'
import LinkRouter from 'components/_utility/LinkRouter'
import t from 'typy'
import { v4 as uuidv4 } from 'uuid'
import { inlineStyleToObject } from 'utils'
import useScript from 'components/hooks/useScript'

const ASSETS_PATH = process.env.ASSETS_PATH
const CDN = process.env.REACT_APP_CDN

const Wrap = (props) => (
  <ThemeConsumer>{(theme) => <HtmlParser {...props} theme={theme || props.theme} />}</ThemeConsumer>
)

const HtmlParser = (props) => {
  const Transform = (node) => {
    // Check if type is script and add it to document head
    const src = node.attribs ? node.attribs.src : undefined
    useScript(src)
    // Apply correct theme color to the heading elements
    const headingsColor = t(props.theme, 'headingsColor').safeObject
    if (
      (node.type === 'tag' && node.name === 'h1') ||
      (node.type === 'tag' && node.name === 'h2') ||
      (node.type === 'tag' && node.name === 'h3') ||
      (node.type === 'tag' && node.name === 'h4') ||
      (node.type === 'tag' && node.name === 'h5') ||
      (node.type === 'tag' && node.name === 'h6')
    ) {
      const Tag = node.name

      const children = node.children.map((childNode, idx) => convertNodeToElement(childNode, idx))

      var propStyle
      if (typeof node.attribs.style === 'string' || node.attribs.style instanceof String) {
        propStyle = inlineStyleToObject(node.attribs.style)
      } else {
        propStyle = node.attribs.style || {}
      }

      return (
        <Tag className={node.attribs.class} style={propStyle} key={uuidv4()}>
          <span style={{ color: propStyle.color ? null : headingsColor }}>{children}</span>
        </Tag>
      )
    }

    // DRAK-485 | LargeHero word highlight theming comes from props
    if (node.type === 'tag' && node.name === 'span' && node.attribs.class === 'highlight') {
      const children = node.children.map((childNode, idx) => convertNodeToElement(childNode, idx))
      return (
        <span className="highlight" key={uuidv4()}>
          {children}
          <span style={{ backgroundColor: props.highlightColor }} />
        </span>
      )
    }

    // DRAK-558 | Add correct headingColor to 'titleCustom' elements
    // for job single description (CMS wysiwyg)
    if (node.type === 'tag' && node.name === 'span' && node.attribs.class === 'titleCustom') {
      const children = node.children.map((childNode, idx) => convertNodeToElement(childNode, idx))
      return (
        <span key={uuidv4()} className="titleCustom" style={{ color: headingsColor }}>
          {children}
        </span>
      )
    }

    // HR elements should use section's highlightColor
    if (node.type === 'tag' && node.name === 'hr') {
      return <hr key={uuidv4()} style={{ color: t(props.theme, 'highlightColor').safeObject }} />
    }

    if (
      node.type === 'tag' &&
      node.name === 'a' &&
      node.attribs.class &&
      node.attribs.class.indexOf('download') !== -1
    ) {
      let href = CDN ? node.attribs.href : ASSETS_PATH + node.attribs.href
      let classes = []
      if (node.attribs.class.indexOf('button') !== -1) {
        classes.push('btn')
      }
      if (node.attribs.class.indexOf('download') !== -1) {
        classes.push('download')
      }

      return (
        <LinkRouter key={uuidv4()} to={href} class={classes.join(' ')} target="external">
          {node.children[0].data}
        </LinkRouter>
      )
    }

    // if (node.type === 'tag' && node.name === 'p') {
    //   const children = node.children.map((childNode, idx) => convertNodeToElement(childNode, idx))
    //   return (
    //     <p key={uuidv4()} style={{ color: paragraphColor }}>
    //       { children }
    //     </p>
    //   )
    // }
  }

  return ReactHtmlParser(props.string, {
    transform: Transform
  })
}

export default Wrap
