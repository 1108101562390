import React from 'react'
import { Redirect } from 'react-router-dom'
import { getLang, getBrowserLang } from 'utils'

// https://littlebox.atlassian.net/browse/DRAK-776
// This component checks if the user is a first time visitor
// if so, it fetches user's browser preferred language, checks for
// hasTranslation API response meta value and then attempts to redirect
// the user to the meta.locale.translations.<browser language> if it exists
// we then set a cookie to indicate that this is not a first time visiting user
// so that if they choose to change the language manually, we don't automatically
// redirect them to their brower's language on another visit
const BrowserLanguageRedirect = ({ hasTranslation, translations }) => {
  return null

  // const lang = getLang()
  // const browserLang = getBrowserLang()
  //
  // // if preferred language cookie is set
  // const cookieLang = document.cookie.split(';').filter(item => item.includes('drakkar_language_pref='))
  //
  // // if the cookie language is missing, the user is first time visitor
  // if (!cookieLang.length) {
  //   const redirectTo =
  //     lang !== browserLang &&
  //     hasTranslation &&
  //     translations[browserLang]
  //
  //   if (redirectTo) {
  //     document.cookie = `drakkar_language_pref=${browserLang}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`
  //     return <Redirect to={redirectTo} />
  //   } else {
  //     return null
  //   }
  // }
  //
  // // get language cookie value
  // const cookieLangVal = cookieLang[0].split('=')[1]
  //
  // const redirectTo =
  //   lang !== cookieLangVal &&
  //   hasTranslation &&
  //   translations[cookieLangVal]
  //
  // if (redirectTo) {
  //   return <Redirect to={redirectTo} />
  // } else {
  //   return null
  // }
}

export default BrowserLanguageRedirect
