import React from 'react'
import styled from 'styled-components'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import InlineSVG from 'components/_ui/InlineSVG'
import { PageContextConsumer } from 'components/_sections/Page/Wrapper/context'

const Wrap = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`
export type FixMeLater = any

export interface ContentPropType {
  style: {
    iconColor: string
    iconHoverColor: string
    padding_top?: boolean
  }
}

const Content = styled.div`
  position: relative;
  max-width: fit-content;
  width: 100%;
  flex-shrink: 0;
  padding-bottom: 1em;
  display: flex;
  align-items: flex-end;
  margin-left: 0;
  margin-right: auto;
  ${(props: ContentPropType) =>
    props.style.padding_top &&
    `
    padding-top: 1em;
  `};
  .social-media {
    display: flex;
    justify-content: center;
    button:focus {
      ${(props: ContentPropType) =>
        props.style.iconHoverColor &&
        `
        outline: 2px solid ${props.style.iconHoverColor};
      `};
      &:active {
        outline: none;
      }
    }
  }

  .icon {
    display: flex;
    width: 2em;
    height: 2em;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    ${(props: ContentPropType) =>
      props.style.iconColor &&
      `
      color: ${props.style.iconColor};
    `};
    &:hover {
      opacity: 0.9;

      ${(props: ContentPropType) =>
        props.style.iconHoverColor &&
        `
        color: ${props.style.iconHoverColor} !important;
      `};
    }
    &--email path {
      stroke-width: 15px;
      stroke: currentColor;
    }
    &--comments {
      padding-top: 4px;
    }
    &--comments path {
      stroke-width: 1px;
      stroke: currentColor;
    }

    svg {
      width: 1.2em;
      height: 1.2em;
    }
  }

  .SocialMediaShareButton {
    cursor: pointer;
    transition: color 0.3s ease;
    &:focus {
      ${(props: ContentPropType) =>
        props.style.iconHoverColor &&
        `
        outline: 2px solid ${props.style.iconHoverColor};
      `};
    }
    ${(props: ContentPropType) =>
      props.style.iconColor &&
      `
      color: ${props.style.iconHoverColor} !important;
    `}
  }
  a {
    color: currentColor;
  }
`
type PropsType = {
  title: string
  email: {
    subject: string
    body: string
  }
  noWrapper?: boolean
  positionBottom?: boolean
}

const ArticleShareIcons = (props: PropsType) => (
  <PageContextConsumer>
    {({ pagePalette }: FixMeLater) => {
      const { title, email, noWrapper, positionBottom } = props
      const share = {
        url: window.location.href,
        title: title
      }

      return (
        <Wrap className={`${noWrapper ? '' : 'wrapper'}`}>
          <Content
            style={{
              iconColor: pagePalette.paragraphColor,
              iconHoverColor: pagePalette.highlightColor,
              padding_top: positionBottom
            }}
          >
            <div className="social-media">
              <TwitterShareButton {...share}>
                <InlineSVG icon="twitter" />
              </TwitterShareButton>
              <LinkedinShareButton {...share}>
                <InlineSVG icon="linkedin" />
              </LinkedinShareButton>
              <FacebookShareButton url={share.url}>
                <InlineSVG icon="facebook" />
              </FacebookShareButton>
              <a
                className="SocialMediaShareButton"
                href={`mailto:?subject=${email.subject}&body=${email.body}`}
              >
                <InlineSVG icon="email" />
              </a>
            </div>
          </Content>
        </Wrap>
      )
    }}
  </PageContextConsumer>
)

export default ArticleShareIcons
