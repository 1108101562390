import React, { Component } from 'react'
import classnames from 'classnames'
import { getLang } from 'utils'

import 'components/_ui/MailchimpPopup/popup.css'
import MailchimpForm from 'components/_integrations/MailchimpForm'
import InlineSVG from 'components/_ui/InlineSVG'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'

class MailchimpPopup extends Component {
  constructor(props) {
    super(props)
    this.lang = getLang()
    this.state = {
      show: true
    }
    this.mailchimpActionUrl = props.action

    this.closePopup = this.closePopup.bind(this)

    this.cookieIsSet = document.cookie
      .split(';')
      .filter((item) => item.includes('mailchimp_popup=')).length

    this.alreadySubscribed = document.cookie
      .split(';')
      .filter((item) => item.includes('mailchimp_subscribed=')).length
  }

  closePopup() {
    this.setState((state) => {
      return {
        show: !state.show
      }
    })

    // Set a cookie so the popup doesn't show again until next session
    if (!this.cookieIsSet) {
      document.cookie = 'mailchimp_popup=true'
    }
  }

  render() {
    //Render the popup only if the user is not subscribed after the session got expired
    if (this.cookieIsSet || this.alreadySubscribed) {
      return false
    }

    return (
      <ThemeConsumer>
        {(theme) => (
          <div
            className={classnames({
              popup: true,
              'popup--show': this.state.show
            })}
          >
            <div className="popup__overlay" onClick={this.closePopup} />
            <div className="popup__inner">
              <div className="popup__title" style={{ color: theme.paragraphColor }}>
                <InlineSVG icon="close" onClick={this.closePopup} />{' '}
                {/* Popup Title is added for Boomingencore, make this dynamic if we get mailchimp pop-up requests from other clients */}
                <span className="popup__header">
                  <strong>Let's Be Sure to Stay in Touch!</strong>
                </span>
                <p>
                  Signup for our newsletter which is jam packed with valuable information and
                  inspiration to help you live your very best encore!
                </p>
              </div>
              <MailchimpForm
                className="bc-mc-form"
                action={this.mailchimpActionUrl}
                isPopup={true}
                closePopup={this.closePopup}
                fields={[
                  {
                    name: 'EMAIL',
                    type: 'email',
                    required: true,
                    label: 'Email Address *'
                  },
                  {
                    name: 'FNAME',
                    type: 'string',
                    required: false,
                    label: 'First Name'
                  }
                ]}
              />
            </div>
          </div>
        )}
      </ThemeConsumer>
    )
  }
}

export default MailchimpPopup
