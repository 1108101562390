import styled from 'styled-components'
import mq from '../../../constants/mediaQueries'

type ColorsType = any

export const HeaderWrapper = styled.div<{ offsetHeight: number; isOpaque?: boolean }>`
  ${(props) =>
    props.offsetHeight &&
    !props.isOpaque &&
    `
    & ~ .page-builder .slider--medium .slider__slide {
      padding-top: ${props.offsetHeight}px;
    }

    & ~ .page-builder--article .hero {
      height: auto;
      padding-top: ${props.offsetHeight + 30}px;
    }
  `}

  ${(props) =>
    props.offsetHeight &&
    props.isOpaque &&
    `
    & ~ .page-builder .slider--large .slider__slides {
      height: ${400 - props.offsetHeight}px;

      ${mq.from(mq.breakpoint.tablet)} {
        height: ${600 - props.offsetHeight}px;
      }

      ${mq.from(mq.breakpoint.desktop)} {
        height: calc(100vh - ${props.offsetHeight}px);
      }
    }

    & ~ .page-builder .slider--medium.slider--large-button .slider__slides .slick-list {
      height: ${400 - props.offsetHeight}px;

      ${mq.from(mq.breakpoint.tablet)} {
        height: ${600 - props.offsetHeight}px;
      }

      ${mq.from(mq.breakpoint.desktop)} {
        height: calc(100vh - ${props.offsetHeight}px);
      }
    }

    & ~ .page-builder .slider--large-4 .slider__slides {
      height: ${370 - props.offsetHeight}px;

      ${mq.from(mq.breakpoint.desktop)} {
        height: ${620 - props.offsetHeight}px;
      }
    }

    & ~ .page-builder .slider--medium .slider__slides .slick-list {
      height: ${620 - props.offsetHeight}px;
    }

    & ~ .page-builder .hero {
      height: ${280 - props.offsetHeight}px;

      ${mq.from(mq.breakpoint.desktop)} {
        height: ${310 - props.offsetHeight}px;
      }

      ${mq.from(mq.breakpoint.desktopLg)} {
        height: ${374 - props.offsetHeight}px;
      }
    }

    & ~ .page-builder--article .hero {
      height: auto;
      padding-top: 2em;

      ${mq.from(mq.breakpoint.desktop)} {
        padding-top: 4em;
      }
    }
  `}
`

export const StyledHeader = styled.header<
  ColorsType & {
    hideMenu?: boolean
    sticky: boolean
    isMobile: boolean
    showTopOnScroll?: boolean
    hasTopMenu?: boolean
    useLoadBackground: boolean
    isOpaque: boolean
    displayBlackGradient: boolean
  }
>`
  transition: all 0.3s ease;

  ${(props) =>
    props.useLoadBackground &&
    props.loadBackground &&
    `
    background-color: ${props.loadBackground};
  `}

  ${(props) =>
    props.hideMenu &&
    `
    background-color: transparent;
  `}

  ${(props) =>
    props.fontColor &&
    `
    .header__dropdown-toggle > a:not(.active) {
      color: ${props.fontColor};
    }

    .offcanvas-toggle {
      color: ${props.fontColor};
    }
  `}

  ${(props) =>
    props.fontHoverColor &&
    `
    .active {
      color: ${props.fontHoverColor};
    }

    .header__dropdown {

      &-toggle:hover > a {
        color: ${props.fontHoverColor};
      }

      a:hover,
      ul:hover .header__dropdown-title {
        color: ${props.fontHoverColor};
      }
    }
  `}

  ${(props) =>
    props.dropdownFontColor &&
    `
    .header__dropdown {
      color: ${props.dropdownFontColor};
    }
  `}

  ${(props) =>
    props.dropdownBackgroundColor &&
    `
    .header__dropdown {
      background-color: ${props.dropdownBackgroundColor};

      &-toggle:hover > a::before {
        border-bottom-color: ${props.dropdownBackgroundColor};
      }
    }
  `}

  ${(props) =>
    props.sticky &&
    props.stickyFontColorDefault &&
    `
    .header__dropdown-toggle > a:not(.active),
    .offcanvas-toggle {
      color: ${props.stickyFontColorDefault};
    }
  `}

  ${(props) =>
    props.sticky &&
    props.stickyFontColorActive &&
    `
    .active,
    .header__dropdown-toggle > a:not(.active):hover {
      color: ${props.stickyFontColorActive};
    }
  `}

  ${(props) =>
    props.sticky &&
    !props.isMobile &&
    !props.showTopOnScroll &&
    props.hasTopMenu &&
    `
    transform: translateY(-49px);
  `}

  ${(props) =>
    props.sticky &&
    props.stickyBackgroundColor &&
    `
    background-color: ${props.stickyBackgroundColor};
  `}

  &::before {
    ${(props) =>
      !props.displayBlackGradient &&
      `
      background-image: none !important;
    `};
  }
`

export const OpaqueHeaderSpacer = styled.div<ColorsType>`
  ${(props) =>
    props.loadBackground &&
    `
    background-color: ${props.loadBackground};
  `}
`
