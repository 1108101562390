import React from 'react'
import ReactLoading from 'react-loading'
import classnames from 'classnames'
import InlineSVG, { IconNameType } from 'components/_ui/InlineSVG'
import useConfig from 'components/hooks/useConfig'
import './loader.css'

/*******************************************************
 * TYPE
 *******************************************************/
type PropsType = {
  mini?: boolean
}

/**
 *
 * @param mini
 * @constructor
 */
const Loader = ({ mini }: PropsType) => {
  // NOTE: Load correct icon based on multisite config title field
  // replace spaces with dashes and lowercase
  // if the icon isn't found in InlineSVG component, then only dots appear

  /*******************************************************
   * HOOKS
   *******************************************************/
  const { getConfig } = useConfig()
  const config = getConfig()
  const { title } = config

  const brandName = title ? `${title.replace(/ /g, '-').toLowerCase()}` : ''

  // options: https://www.npmjs.com/package/react-loading
  const type = 'bubbles'
  const color = 'currentcolor'

  const logo = <InlineSVG icon={`logo-${brandName}` as IconNameType} />

  return (
    <div
      className={classnames({
        loader: true,
        [`loader--${brandName}`]: title
      })}
    >
      {!mini && logo}
      <div className="loader__wrapper">
        <ReactLoading type={type} color={color} height={100} width={100} />
      </div>
    </div>
  )
}
const defaultProps = {
  mini: false
}
Loader.defaultProps = defaultProps
export default Loader
