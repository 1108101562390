import React, { Component } from 'react'
import t from 'typy'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import classnames from 'classnames'
import Button, { CMS_BUTTON_TYPE } from 'components/_ui/Button'
import { shuffleArray, removeParagraphFromString, generateGradient } from 'utils'
import { themeSanitizer } from 'components/_sections/Page/Section/ThemeContext'
import colors from 'constants/colors'
import mq from 'constants/mediaQueries'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import Video from 'components/_paragraphs/Hero/Video'

import 'sass/vendor/slick.css'
import 'sass/vendor/slick-overrides.css'
import 'components/_paragraphs/Hero/Medium/medium.css'

const StyledSlide = styled.div`
  ${(props) =>
    props.imageSingle &&
    `
    && {
      background-image: url(${props.imageSingle});
    }
  `} ${(props) =>
    props.imageMobile &&
    `
    && {
      @media (max-width: 640px) {
        background-image: url(${props.imageMobile});
      }
    }
  `};
`

const Subtitle = styled.div`
  position: relative;
  z-index: 3;
  display: inline-block;
  margin: -0.5em auto 2em;
  max-width: 80%;
  font-size: 18px;
  line-height: 1.22;
  color: ${colors.white};

  ${mq.from(mq.breakpoint.tablet)} {
    font-size: 22px;
  }

  ${mq.from(mq.breakpoint.desktop)} {
    font-size: 27px;
  }

  * {
    margin: 0;
  }
`

const Slide = (props) => (
  <StyledSlide
    className={props.cls}
    imageSingle={props.imageSingle}
    imageMobile={props.imageMobile}
  >
    {props.children}
  </StyledSlide>
)

Slide.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  imageSingle: PropTypes.string.isRequired,
  imageMobile: PropTypes.string,
  cls: PropTypes.string.isRequired
}

class Medium extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: 0
    }

    this.onSliderNavClick = this.onSliderNavClick.bind(this)
  }

  onSliderNavClick(idx) {
    this.setState({
      active: idx
    })
  }

  render() {
    let slides = []

    const { pagePalette } = this.props
    const pageGradient = pagePalette.gradient

    for (let i = 0; i < this.props.data.length; i++) {
      const item = this.props.data[i]
      const { slide } = item
      const { videos } = slide
      const idx = i
      const style = slide.cta ? themeSanitizer(t(item, 'colorPalette.styleColors').safeObject) : {}
      const { alignment } = item.style
      const gradient = item.colorPalette.styleColors.gradient

      let roundedGradientButton
      if (this.props.buttonStyle === CMS_BUTTON_TYPE.rounded && gradient) {
        roundedGradientButton = generateGradient(gradient)
      } else {
        roundedGradientButton = ''
      }

      slides.push(
        <Slide
          key={idx}
          cls={classnames({
            slider__slide: true,
            [`slider__slide--${alignment}`]: alignment,
            'slider__slide--active': this.state.active === idx
          })}
          style={{ background: gradient ? generateGradient(gradient) : '' }}
          imageSingle={t(slide, 'imageSingle.url').safeObject}
          imageMobile={t(slide, 'imageMobile.url').safeObject}
        >
          <Video data={videos} />
          <span
            className="bg-accent"
            style={{
              background:
                generateGradient(gradient) ||
                style.highlightColor ||
                generateGradient(pageGradient) ||
                pagePalette.highlightColor
            }}
          />
          <div className="wrapper">
            {idx === 0 && (
              <h1 className="slider__title">
                <HtmlParser string={removeParagraphFromString(slide.title)} />
              </h1>
            )}
            {idx !== 0 && (
              <div className="slider__title">
                <HtmlParser string={slide.title} />
              </div>
            )}
            {slide.subtitle && (
              <Subtitle>
                <HtmlParser string={slide.subtitle} />
              </Subtitle>
            )}
            {slide.cta && (
              <Button
                href={slide.cta.uri}
                options={slide.cta.options}
                primaryColor={style.highlightColor || pagePalette.highlightColor}
                buttonStyle={this.props.buttonStyle}
                granularStyles={{
                  [CMS_BUTTON_TYPE.drakkarDigital]: {
                    hoverColor: style.activeColor || pagePalette.activeColor
                  }
                }}
                style={{
                  background:
                    roundedGradientButton ||
                    style.highlightColor ||
                    generateGradient(pageGradient) ||
                    pagePalette.highlightColor
                }}
              >
                {slide.cta.title}
              </Button>
            )}
          </div>
        </Slide>
      )
    }

    const settings = {
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      infinite: true,
      arrows: false,
      speed: 500,
      focusOnSelect: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: this.onSliderSlideChange
    }

    return (
      <div
        className={classnames({
          slider: true,
          'slider--medium': true
        })}
      >
        <Slider className="slider__slides" {...settings}>
          {slides}
        </Slider>
      </div>
    )
  }
}

Medium.propTypes = {
  data: PropTypes.array.isRequired
}

export default Medium
