import React from 'react'
import InlineSVG from '../../../../../_ui/InlineSVG'
import { JobsContext } from '../../context'
import classnames from 'classnames'
import qs from 'qs'

import './header.css'

const Header = () => (
  <JobsContext.Consumer>
    {({ name, jobs, style, query, sortBy }) => {
      const { labels, results } = jobs

      if (!results) {
        return null
      }

      const currQ = qs.parse(query, { ignoreQueryPrefix: true })
      const column = currQ.sort_field
      const direction = currQ.sort_direction
      return (
        <div
          className="jobs__header-wrap"
          style={{
            backgroundColor: style.highlightColor,
            color: style.activeColor
          }}
        >
          <header className="jobs__header">
            <button
              className={classnames({
                'col col--title': true,
                [`col--${direction}`]: direction,
                'col--active': column === 'title'
              })}
              onClick={() => sortBy('title')}
            >
              {labels.title}
              <InlineSVG icon="sort-solid" />
            </button>
            <button
              className={classnames({
                'col col--cat': true,
                [`col--${direction}`]: direction,
                'col--active': column === 'category'
              })}
              onClick={() => sortBy('category')}
            >
              {labels.category}
              <InlineSVG icon="sort-solid" />
            </button>
            <button
              className={classnames({
                'col col--type': true,
                [`col--${direction}`]: direction,
                'col--active': column === 'job_type'
              })}
              onClick={() => sortBy('job_type')}
            >
              {labels.job_type}
              <InlineSVG icon="sort-solid" />
            </button>
            <button
              className={classnames({
                'col col--loc': true,
                [`col--${direction}`]: direction,
                'col--active': column === 'location'
              })}
              onClick={() => sortBy('location')}
            >
              {labels.location}
              <InlineSVG icon="sort-solid" />
            </button>
          </header>
        </div>
      )
    }}
  </JobsContext.Consumer>
)

export default Header
