// @react
import React from 'react'
import { Route, Switch, withRouter, Redirect, RouteComponentProps } from 'react-router-dom'
// @common
import { routeCleaner, getLang } from 'utils'
import qs from 'qs'
// @components
import OAuth from 'components/HOC/OAuth'
import PageWrapper from 'components/_sections/Page/Wrapper'
import Confirmation from 'components/_integrations/Clickdimensions/Confirmation'
import Sitemap from 'components/_sections/Sitemap'
import Search from 'components/_sections/Search'
import SignIn from 'components/_sections/Intranet/SignIn'
import Job from 'components/_sections/Jobs/Job'
import withConfig, { WithConfigProps } from 'components/HOC/config/HOC'
import portalRedirects from 'components/_utility/redirects'
import { Meta } from 'components/_utility/Strings'

export enum RouteType {
  STATIC_ROUTE = 'STATIC_ROUTE',
  DYNAMIC_ROUTE = 'DYNAMIC_ROUTE'
}
interface IProps extends WithConfigProps, RouteComponentProps {}

/**
 *
 * @param props
 * @constructor
 */
const App = (props: IProps) => {
  const safePath = routeCleaner(window.location.pathname)
  const lang = getLang()
  const { config } = props

  // if language parameter is missing, redirect the user
  // to the previously set browser language (from a cookie)
  // or fall back to defaultLanguage (from multisite.json)
  if (!lang) {
    const languagePrefCookie = document.cookie
      .split(';')
      .filter((item) => item.includes('drakkar_language_pref='))
    const redirectLang = languagePrefCookie.length
      ? languagePrefCookie[0].split('=')[1] // get the value
      : config.defaultLanguage // fallback

    return <Redirect to={`/${redirectLang}`} />
  }

  const routes = [
    [...portalRedirects],
    <Route
      path="/en/confirmation/message/:id"
      key={2}
      exact
      render={(loc) => (
        <PageWrapper
          // @ts-ignore
          data={{
            meta: Meta.confirmation[lang],
            type: RouteType.STATIC_ROUTE,
            api: config.api_url + '/get/menus/' + lang
          }}
        >
          <Confirmation loc={loc} />
        </PageWrapper>
      )}
    />,
    <Route
      path="/fr/confirmation/message/:id"
      key={2}
      exact
      render={(loc) => (
        <PageWrapper
          data={{
            meta: Meta.confirmation[lang],
            type: RouteType.STATIC_ROUTE,
            api: config.api_url + '/get/menus/' + lang
          }}
        >
          <Confirmation loc={loc} />
        </PageWrapper>
      )}
    />,
    <Route
      key={3}
      path="/en/search"
      render={({ match: { url } }) => (
        <React.Fragment>
          <Route
            exact
            path={`${url}/`}
            render={() => (
              <PageWrapper
                data={{
                  meta: Meta.search[lang],
                  type: RouteType.STATIC_ROUTE,
                  api: config.api_url + '/get/search/' + lang + '/' + routeCleaner('/en/<front>')
                }}
              >
                <Search />
              </PageWrapper>
            )}
          />
          <Route
            exact
            path={`${url}/:id`}
            render={() => (
              <PageWrapper
                // @ts-ignore
                data={{
                  meta: Meta.search[lang],
                  type: RouteType.STATIC_ROUTE,
                  api:
                    config.api_url +
                    '/get/search/' +
                    lang +
                    '/' +
                    safePath +
                    (window.location.search ? window.location.search : '?page=0&pager=10')
                }}
              >
                <Search />
              </PageWrapper>
            )}
          />
        </React.Fragment>
      )}
    />,
    <Route
      key={3}
      path="/fr/recherche"
      render={({ match: { url } }) => (
        <React.Fragment>
          <Route
            exact
            path={`${url}/`}
            render={() => (
              <PageWrapper
                // @ts-ignore
                data={{
                  meta: Meta.search[lang],
                  type: RouteType.STATIC_ROUTE,
                  api: config.api_url + '/get/search/' + lang + '/' + routeCleaner('/<front>')
                }}
              >
                <Search />
              </PageWrapper>
            )}
          />
          <Route
            exact
            path={`${url}/:id`}
            render={() => (
              <PageWrapper
                data={{
                  meta: Meta.search[lang],
                  type: 'staticRoute',
                  api:
                    config.api_url +
                    '/get/search/' +
                    lang +
                    '/' +
                    safePath +
                    (window.location.search ? window.location.search : '?page=0&pager=10')
                }}
              >
                <Search />
              </PageWrapper>
            )}
          />
        </React.Fragment>
      )}
    />,
    <Route path="/en/intranet-sign-in" key={8} exact render={() => <SignIn lang="en" />} />, // @ts-ignore
    <Route path="/fr/intranet-sign-in" key={8} exact render={() => <SignIn lang="fr" />} />, // @ts-ignore
    <Route
      path="/en/intranet-sign-in/reset-password"
      key={8}
      exact
      render={() => <SignIn lang="en" reset />}
    />,
    <Route
      path="/fr/intranet-sign-in/reset-password"
      key={8}
      exact
      render={() => <SignIn lang="fr" reset />}
    />,
    <Route
      path="/fr/plan-du-site"
      key={3}
      exact
      render={() => (
        <PageWrapper
          data={{
            type: RouteType.STATIC_ROUTE,
            meta: Meta.sitemap[lang],
            api: config.api_url + '/get/menus/' + lang
          }}
        >
          <Sitemap lang={lang} api={`${config.api_url}/get/menus/fr/plan-du-site?_format=json`} />
        </PageWrapper>
      )}
    />,
    <Route
      path="/en/sitemap"
      key={5}
      exact
      render={() => (
        <PageWrapper
          data={{
            type: RouteType.STATIC_ROUTE,
            meta: Meta.sitemap[lang],
            api: config.api_url + '/get/menus/' + lang
          }}
        >
          <Sitemap lang={lang} api={`${config.api_url}/get/menus/en/plan-du-site?_format=json`} />
        </PageWrapper>
      )}
    />,
    <Route
      path="/en/JobListing/Details/c661517b-0cf1-4c65-9f24-ee42b08eb98d/:id"
      key="jobenRedirect"
      exact
      render={({ match }) => {
        // Pass on GET params
        const parsedQ = qs.parse(window.location.search, { ignoreQueryPrefix: true })
        return (
          <Redirect to={`/en/JobListing/Details/${match.params.id}?${qs.stringify(parsedQ)}`} />
        )
      }}
    />,
    <Route
      path="/fr/JobListing/Details/c0f5c902-7098-4906-bdc7-b6447b237672/:id"
      key="jobfrRedirect"
      exact
      render={({ match }) => {
        // Pass on GET params
        const parsedQ = qs.parse(window.location.search, { ignoreQueryPrefix: true })
        return (
          <Redirect to={`/fr/JobListing/Details/${match.params.id}?${qs.stringify(parsedQ)}`} />
        )
      }}
    />,

    <Route
      path="/en/JobListing/Details/:id"
      key="joben"
      exact
      render={({ match }) => (
        <PageWrapper
          data={{
            type: RouteType.STATIC_ROUTE,
            api: `${config.api_url}/get/jobs/${match.params.id}/en?time=${Date.now()}`
          }}
        >
          {/* @ts-ignore */}
          <Job />
        </PageWrapper>
      )}
    />,
    <Route
      path="/fr/JobListing/Details/:id"
      key="jobfr"
      exact
      render={({ match }) => (
        <PageWrapper
          data={{
            type: RouteType.STATIC_ROUTE,
            api: `${config.api_url}/get/jobs/${match.params.id}/fr?time=${Date.now()}`
          }}
        >
          {/* @ts-ignore */}
          <Job />
        </PageWrapper>
      )}
    />,
    <Route
      path="*"
      key={4}
      exact
      render={() => (
        <PageWrapper
          data={{
            type: RouteType.DYNAMIC_ROUTE,
            api: config.api_url + '/get/node/' + lang + '/' + safePath
          }}
        />
      )}
    />
  ]

  return (
    <OAuth>
      <Switch>{routes}</Switch>
    </OAuth>
  )
}

export default withConfig(withRouter(App))
