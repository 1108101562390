import React from 'react'
import { Route } from 'react-router-dom'

// This "component" is used to add portal specific redirects
// by comparing location hostname and compiling an array of Routes
// which are then expanded/spread in App.js
let redirects = []
switch (window.location.hostname) {
  case 'drakkar.local':
  case 'staging.drakkar.com':
  case 'drakkar.com':
    redirects = [
      {
        from: '/fr/impartition/numerique',
        to: 'https://drakkardigital.com'
      },
      {
        from: '/fr/impartition/marketing',
        to: 'https://drakkardigital.com'
      },
      {
        from: '/en/outsourcing/marketing',
        to: 'https://drakkardigital.com/en/'
      },
      {
        from: '/en/outsourcing/digital',
        to: 'https://drakkardigital.com/en/'
      }
    ]
    break
  default:
    break
}

// Loop through the redirects array and generate all the Routes
const routes = redirects.map((item, idx) => (
  <Route
    exact
    key={`redirect_${idx}`}
    path={item.from}
    render={() => {
      window.location.replace(item.to)
      return null
    }}
  />
))

export default routes
