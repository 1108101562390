import React, { useContext } from 'react'
import styled from 'styled-components'
import { HeaderContext } from 'components/_global/Header/context'
import { SocialMedia } from 'ts/DTO/content.config'
import InlineSVG from 'components/_ui/InlineSVG'

const Wrap = styled.div`
  display: inline-flex;
  align-items: center;
  vertical-align: center;

  a {
    display: inline-flex;
    align-items: center;
    padding: 0;
    font-size: 16px;

    &:not(:first-child) {
      margin-left: 9px;
    }

    &:not(:last-child) {
      margin-right: 9px;
    }

    span {
      font-size: 1em;
      vertical-align: middle;
    }
  }
`

type SocialMediaType = keyof SocialMedia

// Manually doing the ordering here because API returns an object
const socialOrder: SocialMediaType[] = [
  'twitter',
  'facebook',
  'instagram',
  'linkedin',
  'youtube',
  'vimeo'
]

type PropsType = {
  className?: string
}

/**
 *
 * @param className
 * @constructor
 */
const SocialIcons = ({ className }: PropsType) => {
  /*******************************************************
   * CONTEXT
   *******************************************************/
  const header = useContext(HeaderContext)

  if (!header.showTopBarSocial) {
    return null
  }

  if (!header.socialMedia) {
    return null
  }

  const icons = socialOrder.map((icon, idx) => {
    // @todo does this need fixing?
    if (header.socialMedia && header.socialMedia[icon]) {
      return (
        <a key={idx} target="_blank" rel="noopener noreferrer" href={header.socialMedia[icon]}>
          <InlineSVG icon={icon} />
        </a>
      )
    }
    return null
  })

  return <Wrap className={className}>{icons}</Wrap>
}

export default SocialIcons
