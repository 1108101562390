import React from 'react'
import classnames from 'classnames'
import Helmet from 'react-helmet'
import t from 'typy'
import { generateGradient } from 'utils'
// @components
import Accordion from 'components/_ui/Accordion'
import ArticleGrid from 'components/_sections/Article/ArticleGrid'
import Button, { CMS_BUTTON_TYPE } from 'components/_ui/Button'
import Widget from 'components/_integrations/Clickdimensions/Widget'
import ATSForm from 'components/_integrations/ATS/Form'
import CTAImageTextDrawer from 'components/_paragraphs/CTAImageTextDrawer'
import CTAImageText from 'components/_paragraphs/CTAImageText'
import Gallery from 'components/_paragraphs/Gallery'
import IconTextGrid from 'components/_paragraphs/IconTextGrid'
import Inspiration from 'components/_paragraphs/Inspiration'
import Map from 'components/_paragraphs/Map'
import RegionGrid from 'components/_paragraphs/RegionGrid'
import Testimonials from 'components/_paragraphs/Testimonials'
import LogoGroup from 'components/_paragraphs/LogoGroup'
import Video from 'components/_ui/Video'
import TextBlock from 'components/_paragraphs/Wysiwyg/TextBlock'
import RawHTML from 'components/_paragraphs/Wysiwyg/RawHTML'
import Section from 'components/_paragraphs/Wysiwyg/Section'
import SectionTitle from 'components/_paragraphs/SectionTitle'
import Image from 'components/_paragraphs/Wysiwyg/Image'
import JobBoard from 'components/_sections/Jobs/JobBoard'
import ImageWithTitle from 'components/_paragraphs/ImageWithTitle'
import { ThemeProvider, themeSanitizer } from 'components/_sections/Page/Section/ThemeContext'
import { PageContextConsumer } from 'components/_sections/Page/Wrapper/context'
import { ButtonStyle as ButtonStyleType } from 'ts/DTO/content.config'
// Add anchor functionality via Higher Order Component
import anchorWrapper from 'components/HOC/anchorWrapper'

import 'components/_sections/Page/Section/page-section.css'
import {
  isAccordionGroupPara,
  isArticleTaxonomyReferencePara,
  isButtonPara,
  isFormPara,
  isGalleryPara,
  isHtmlPara,
  isIconAndTextGroupPara,
  isImageAndTextCtaDrawerPara,
  isImageAndTextCtaPara,
  isImagePara,
  isImageWithTitlePara,
  isJobListingPara,
  isLogoGroupPara,
  isMapPara,
  isOfType,
  isTextBlockPara,
  isTwoColumnsImageAndTextPara,
  isVectorItemPara
} from 'ts/typeguards'
import {
  HtmlPara,
  InspirationBannerPara,
  RegionGridPara,
  TestimonialsGroupPara,
  VideoPara
} from 'ts/DTO/paragraphs'

// GTM ID context to be consumed by children
export const GTMContext = React.createContext('yo')
export const GTMProvider = GTMContext.Provider
export const GTMConsumer = GTMContext.Consumer

type PropsType = {
  sectionNumber: number
  data: any
  style: any
  content: any
  colorPalette: any
  anchor?: string
  setAnchorRef?: (el: HTMLElement | null) => void
}

export const PageSection = (props: PropsType) => (
  <PageContextConsumer>
    {({ pagePalette, pageButtonStyle }) => {
      const { data } = props

      const { style, colorPalette, content } = data
      const { themeColors, styleColors } = colorPalette || {}

      // Combine PageSection colors with the Page colors
      const pageSectionColors = {
        ...pagePalette,
        ...themeSanitizer(themeColors),
        ...themeSanitizer(styleColors)
      }

      let title = content.title
      const separator =
        style && style.separators ? style.separators.indexOf('below_title') > -1 : false

      const themeGradient = colorPalette.themeColors.gradient
      const pageGradient = pagePalette.gradient

      // GTM ID for this section, to be used with GTMContext
      const gtmId = t(data, 'content.cta.options.gtm_id').safeObject

      if (title) {
        title = (
          <SectionTitle
            theme={style.sectionTitleStyle}
            title={content.title}
            separator={separator}
            button={content.cta && content.cta.uri ? content.cta : null}
            buttonStyle={pageButtonStyle}
          />
        )
      }

      const button =
        !content.cta || !content.cta.title ? (
          <></>
        ) : (
          <div className="wrapper page-section__btn button-wrapper button-wrapper--center">
            <Button
              href={content.cta.uri}
              options={content.cta.options}
              buttonStyle={pageButtonStyle}
            >
              {content.cta.title}
            </Button>
          </div>
        )

      const sectionClasses = classnames({
        'page-section': true,
        'page-section--border-bottom':
          style && style.separators ? style.separators.indexOf('bottom_section') > -1 : false
      })

      const bottomPadding =
        !style || style.bottomPadding === '0' ? false : <span className="page-section__padder" />

      // @todo this is a hack/workaround for https://github.com/JedWatson/react-select/issues/1076#issuecomment-253629943
      let elevate = ''
      if (content.paragraphs) {
        for (const element of content.paragraphs) {
          if (element.type === 'form' && element.content.form === 'ATS') {
            elevate = 'lift-up'
          }
        }
      }

      return (
        <GTMProvider value={gtmId}>
          <ThemeProvider value={pageSectionColors}>
            <section
              id={props.anchor}
              className={sectionClasses}
              ref={(node) => {
                props.setAnchorRef && props.setAnchorRef(node)
              }}
              style={{
                background:
                  generateGradient(themeGradient) ||
                  pageSectionColors.backgroundColor ||
                  generateGradient(pageGradient),
                borderColor: pageSectionColors.headingsColor
              }}
            >
              {t(style, 'backgroundImage.markup').safeObject && (
                <span
                  className="page-section__icon"
                  dangerouslySetInnerHTML={{ __html: style.backgroundImage.markup }}
                  style={{ color: pageSectionColors.headingsColor }}
                />
              )}
              <div className={'page-section__inner ' + elevate}>
                {title}
                {PageSectionComponent({
                  sectionPalette: pageSectionColors,
                  data,
                  pageButtonStyle
                })}
                {button}
                {bottomPadding}
              </div>
            </section>
          </ThemeProvider>
        </GTMProvider>
      )
    }}
  </PageContextConsumer>
)

/**
 *
 * @param colorPalette
 * @param data
 * @returns {Uint8Array | BigInt64Array | any[] | Float64Array | Int8Array | Float32Array | Int32Array | Uint32Array | Uint8ClampedArray | BigUint64Array | Int16Array | Uint16Array|boolean}
 * @constructor
 */

type PageSectionComponentPropsType = {
  pageButtonStyle: ButtonStyleType
  data: any
  sectionPalette: any
}

const PageSectionComponent = ({
  sectionPalette,
  data,
  pageButtonStyle
}: PageSectionComponentPropsType) => {
  const paragraphs = data.content.paragraphs

  // Early exit if paragraphs field is empty
  if (!paragraphs) {
    return false
  }

  return paragraphs.map((element: any, idx: number) => {
    const type = element.type
    const elementColorPalette = element.content.colorPalette
    const { themeColors, styleColors } = elementColorPalette || {}

    // Combine Element colors with Section and Page colors palettes
    // Spread page palette as a fallback
    // then spread section palette on top of it
    // in case of missing page section values, page palette will be used

    // console.log('sectionPalette')
    // console.log(sectionPalette)
    // console.log('themeColors')
    // console.log(themeColors)
    // console.log('styleColors')
    // console.log(styleColors)

    const style = {
      ...sectionPalette,
      ...themeSanitizer(themeColors),
      ...themeSanitizer(styleColors)
    }

    const themeGradient = data.colorPalette.themeColors.gradient

    /**
     * rules for colors:
     *  -- Check if block (child paragraph like CTAImageTextDrawer) has highlight colour.
     *  -- If block has no highlight colour, check if page section has highlight colour.
     *  -- If block and page section have no highlight colours, use heading colour of theme (i.e. paragraph: page section theme field).
     */

    const { paragraphColor, headingsColor, highlightColor, activeColor } = style

    /****************************************
     *
     *****************************************/
    if (isAccordionGroupPara(element)) {
      return <Accordion key={idx} panels={element.content} />
    }

    /****************************************
     *
     *****************************************/
    if (isTwoColumnsImageAndTextPara(element)) {
      return (
        <Section
          key={idx}
          title={data.content.title}
          subtitle={element.content.title}
          padding={element.style}
          alignment={element.alignment}
          columns={[element.content.columnOne, element.content.columnTwo]}
          buttonStyle={pageButtonStyle}
          highlightColor={highlightColor}
          invertMobileColumns={element.invertColumns}
        />
      )
    }

    /****************************************
     *
     *****************************************/
    if (isArticleTaxonomyReferencePara(element)) {
      const { layout, articles, numArticles } = element.content
      const { generalStyle, leftBlockSettings } = element

      // Allows CMS to set the upper limit on the number
      // of articles that are being displayed
      const limitArticlesTo = numArticles ? parseInt(numArticles, 10) : false

      return (
        <ArticleGrid
          key={idx}
          layout={layout}
          articles={limitArticlesTo ? articles.slice(0, limitArticlesTo) : articles}
          style={style}
          border={generalStyle.border}
          alignment={generalStyle.alignment}
          tileBackgroundColor={themeSanitizer({
            backgroundColor: generalStyle.tileBackgroundColor
          })}
          loadMoreText={generalStyle.loadMoreText}
          sponsoredLabel={generalStyle.sponsoredLabel}
          // Slider props
          title={leftBlockSettings.title}
          images={leftBlockSettings.images}
          leftBlockPalette={themeSanitizer(leftBlockSettings.colorPalette.styleColors) || {}}
          hideTitle={leftBlockSettings.hideTitle}
        />
      )
    }

    /****************************************
     *
     *****************************************/
    if (isMapPara(element)) {
      return (
        <Map
          key={idx}
          lat={element.content.map.lat}
          lng={element.content.map.lng}
          label={element.content.label}
          city={element.content.city}
          address={element.content.address}
          phone={element.content.phone}
          tollFreeNumber={element.content.tollFreeNumber}
          fax={element.content.fax}
        />
      )
    }

    /****************************************
     *
     *****************************************/
    if (isHtmlPara(element)) {
      return <RawHTML key={idx} text={element.content.text} />
    }

    /****************************************
     *
     *****************************************/
    if (isButtonPara(element)) {
      const cls = 'wrapper button-wrapper button-wrapper--' + element.content.position

      return (
        <div key={idx} className={cls}>
          <Button
            href={element.content.cta.uri}
            options={element.content.cta.options}
            primaryColor={
              pageButtonStyle === CMS_BUTTON_TYPE.drakkar ? headingsColor : highlightColor
            }
            secondaryColor={highlightColor}
            buttonStyle={pageButtonStyle}
          >
            {element.content.cta.title}
          </Button>
        </div>
      )
    }

    /****************************************
     *
     *****************************************/
    if (isFormPara(element)) {
      if (element.content.form === 'ATS') {
        return (
          <div key={idx} className="wrapper wrapper--flex-center wrapper--form lift-up">
            <ATSForm buttonStyle={pageButtonStyle} />
          </div>
        )
      } else {
        return (
          <div key={idx} className="wrapper wrapper--flex-center wrapper--form">
            <Helmet>
              <script src="https://az124611.vo.msecnd.net/web/v10/CDWidget.js" />
              <script>
                var loc = "https://analytics.clickdimensions.com/drakkarca-avhl7/pages/";
              </script>
            </Helmet>
            <Widget id={element.content.form} />
          </div>
        )
      }
    }

    /****************************************
     *
     *****************************************/
    if (isGalleryPara(element)) {
      return <Gallery key={idx} images={element.content.images} />
    }

    /****************************************
     *
     *****************************************/
    if (isIconAndTextGroupPara(element)) {
      return (
        <IconTextGrid
          key={idx}
          columns={element.content}
          alignment={element.style && element.style.alignment}
          buttonStyle={pageButtonStyle}
          highlightColor={highlightColor}
        />
      )
    }

    /****************************************
     *
     *****************************************/
    if (isImagePara(element)) {
      return <Image key={idx} width={element.content.imageStyle} src={element.content.image.url} />
    }

    /****************************************
     *
     *****************************************/
    if (isImageAndTextCtaPara(element)) {
      return (
        <CTAImageText
          key={idx}
          title={element.content.title}
          subtitle={element.content.subtitle}
          image={element.content.image.url}
          imageMobile={''} // @todo this is never returned from API
          // imageMobile={element.content.imageMobile.url}
          button={element.content.cta}
          theme={element.style.theme}
          paragraphColor={paragraphColor}
          highlightColor={
            pageButtonStyle === CMS_BUTTON_TYPE.drakkar ? highlightColor : style.highlightColor
          }
          activeColor={activeColor}
          buttonStyle={pageButtonStyle}
          pagePalette={style}
        />
      )
    }

    /****************************************
     *
     *****************************************/
    if (isImageAndTextCtaDrawerPara(element)) {
      return (
        <CTAImageTextDrawer
          key={idx}
          theme={element.style.theme}
          anchor={element.content.anchor}
          title={element.content.title}
          titleSubtitle={element.content.titleSubtitle}
          titleCentered={element.content.centeredTitle}
          subtitle={element.content.subtitle}
          links={element.content.links}
          image={element.content.image.url}
          imagePosition={element.content.image.position}
          button={element.content.cta}
          highlightColor={
            pageButtonStyle === CMS_BUTTON_TYPE.drakkar ? highlightColor : style.highlightColor
          }
          activeColor={activeColor}
          buttonStyle={pageButtonStyle}
          pagePalette={style}
        />
      )
    }

    /****************************************
     * INSPIRATION BANNER
     *****************************************/
    if (isOfType<InspirationBannerPara>(element, 'type', 'inspiration_banner')) {
      return (
        <Inspiration
          key={idx}
          title={element.content.title}
          subtitle={element.content.subtitle}
          cta={element.content.cta}
          buttonStyle={pageButtonStyle}
        />
      )
    }

    /****************************************
     *
     *****************************************/
    if (isLogoGroupPara(element)) {
      return <LogoGroup key={idx} content={element.content} />
    }

    /****************************************
     *
     *****************************************/
    if (isOfType<TestimonialsGroupPara>(element, 'type', 'testimonials_group')) {
      return <Testimonials key={idx} title={data.content.title} slides={element.content} />
    }

    /****************************************
     *
     *****************************************/
    if (isTextBlockPara(element)) {
      return <TextBlock key={idx} text={element.content.text} padding={element.style.padding} />
    }

    /****************************************
     *
     *****************************************/
    if (isVectorItemPara(element)) {
    }

    /****************************************
     * REGION GRID
     *****************************************/
    if (isOfType<RegionGridPara>(element, 'type', 'region_grid')) {
      return <RegionGrid key={idx} images={element.content} highlightColor={highlightColor} />
    }

    /****************************************
     * VIDEO
     *****************************************/
    if (isOfType<VideoPara>(element, 'type', 'video')) {
      return <Video key={idx} id={element.content.id} padding={element.content.padding} />
    }

    /****************************************
     *
     *****************************************/
    if (isJobListingPara(element)) {
      return (
        <JobBoard
          key={idx}
          boardName={element.content.name}
          boardId={element.content.id}
          style={style}
          buttonStyle={pageButtonStyle}
        />
      )
    }

    /****************************************
     *
     *****************************************/
    if (isImageWithTitlePara(element)) {
      return <ImageWithTitle key={idx} content={element.content} buttonStyle={pageButtonStyle} />
    }

    console.error(`Unrecognized page section component type: ${type}`)
    return <></>
  })
}

export default anchorWrapper(PageSection)
