// @react
import React from 'react'
// @common
import { getLang } from 'utils'
import { Strings } from 'components/_utility/Strings'
import { Base64 } from 'js-base64'
// @vendor
import axios from 'axios'
// @components
import withConfig from 'components/HOC/config/HOC'
import Results from 'components/_sections/Search/Results'
import LoadMore from 'components/_sections/Search/LoadMore'
import Hero from 'components/_paragraphs/Hero/Small'
import Form from 'components/_sections/Search/Form'
import Loader from 'components/_ui/Loader'
import {
  ThemeProvider,
  ThemeConsumer,
  themeSanitizer
} from 'components/_sections/Page/Section/ThemeContext'

import 'components/_sections/Search/search.css'

class Search extends React.Component {
  constructor(props) {
    super(props)

    this.submitHandler = this.submitHandler.bind(this)
    this.loadHandler = this.loadHandler.bind(this)
    this.state = {
      loaded: true,
      page: 0,
      pager: 10,
      search: this.props.data.content.search,
      terms: this.props.data.content.search.meta.terms,
      results: this.props.data.content.search.results
    }
    this.lang = getLang()
  }

  /**
   *
   * @param e
   */
  loadHandler(e) {
    e && e.preventDefault()
    const page = this.state.page + 1
    this.setState({
      loaded: false,
      page: page
    })

    const terms = this.state.terms
    const endpoint = `${this.props.config.api_url}/get/search/${this.lang}/${Base64.encode(
      terms
    )}?pager=${this.state.pager}&page=${page}`
    this.fetchEndpoint(endpoint)
  }

  /**
   *
   * @param terms
   */
  submitHandler(terms) {
    const page = 0

    this.setState({
      loaded: false,
      terms: terms,
      results: [],
      page: page
    })

    const endpoint = `${this.props.config.api_url}/get/search/${this.lang}/${Base64.encode(
      terms
    )}?pager=${this.state.pager}&page=${page}`
    this.fetchEndpoint(endpoint)
  }

  /**
   *
   * @param endpoint
   */
  fetchEndpoint(endpoint) {
    axios
      .get(endpoint)
      .then((response) => {
        const results = this.state.results.concat(response.data.content.search.results)
        this.setState({
          loaded: true,
          search: response.data.content.search,
          response: 200,
          results: results
        })
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            loaded: true,
            data: error.response.data,
            response: error.response.status
          })
          console.warn(
            "Couldn't fetch search results from",
            endpoint,
            'due to',
            error,
            'node not found'
          )
        } else {
          console.warn(
            "Couldn't fetch search results from",
            endpoint,
            'due to',
            error,
            'is the API down?'
          )
          this.setState({
            loaded: true,
            data: null,
            response: 500
          })
        }
      })
  }

  render() {
    const lang = this.lang
    const search = this.state.search
    const results = this.state.results

    const { colorPalette } = this.props.data.content
    const themeColors = themeSanitizer({ ...colorPalette.themeColors, ...colorPalette.styleColors })

    let title

    if (search.results && search.results.length > 0) {
      title = [
        <h4 key={1}>
          {search.meta.total} {Strings.search.results[lang]}
        </h4>,
        <h4 key={2}>
          page {search.meta.page + 1} {Strings.utility.of[lang]} {search.meta.pages}
        </h4>
      ]
    } else if (search.meta.front) {
      title = ''
    } else {
      title = <h4>{Strings.search.noResults[lang]}</h4>
    }

    const loadMore =
      search.meta.page + 1 >= search.meta.pages ? false : <LoadMore handler={this.loadHandler} />
    let loadSection
    if (!this.state.loaded) {
      loadSection = (
        <div className="loading header__logo mini">
          <Loader />
        </div>
      )
    }
    let resultsSection
    if (this.state.loaded || this.state.page > 0) {
      resultsSection = <Results results={results} />
    }

    return (
      <ThemeProvider value={themeColors}>
        <ThemeConsumer>
          {(theme) => (
            <React.Fragment>
              <Hero
                style={colorPalette.styleColors}
                title={Strings.search.title[lang]}
                theme={'theme_1'}
              />
              <div className="bg--silver">
                <section className="section wrapper">
                  <div className="wrapper">
                    <Form handler={this.submitHandler} placeholder={search.meta.terms} />
                  </div>
                  <div className="wrapper">
                    <div className="search-header" style={{ color: theme.headingsColor }}>
                      {title}
                    </div>
                    {resultsSection}
                    {loadSection}
                    {loadMore}
                  </div>
                </section>
              </div>
            </React.Fragment>
          )}
        </ThemeConsumer>
      </ThemeProvider>
    )
  }
}

export default withConfig(Search)
