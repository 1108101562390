import React from 'react'
import PropTypes from 'prop-types'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import { removeStringNewline } from 'utils'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-top: 1.5em;
  padding-bottom: 1.5em;

  .section-title + & {
    padding-top: 0;
  }
`

const RawHTML = (props) => (
  <Wrapper className="raw-html">
    <div className="wrapper">
      <HtmlParser string={removeStringNewline(props.text)} />
    </div>
  </Wrapper>
)

RawHTML.propTypes = {
  text: PropTypes.string.isRequired
}

export default RawHTML
