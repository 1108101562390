import React from 'react'
// import PropTypes from 'prop-types'
import { Strings } from 'components/_utility/Strings'
import { getLang } from 'utils'
import Tracker, { getTrackingFormName } from 'utils/tracking'
import queryString from 'qs'
import SimpleMessage from 'components/_global/Errors/SimpleMessage'

const lang = getLang()

const Confirmation = (props) => {
  const msgID = props.match.params.id
  const msgBody = Strings.form[msgID][lang]
  const msgTitle = Strings.utility.thankyou[lang]

  const params = queryString.parse(props.loc.location.search, { ignoreQueryPrefix: true })
  TrackForm(params)

  return (
    <div>
      <SimpleMessage pageTitle={msgTitle} title={msgTitle} body={msgBody} />
    </div>
  )
}

const TrackForm = (params) => {
  const name = getTrackingFormName(params.formId)

  switch (name) {
    case 'recrutement':
      Tracker('UCS003')(params.leadId)
      break
    case 'impartition':
      Tracker('UCS006')(params.leadId)
      break
    case 'contact':
      Tracker('UCS016')()
      break
    default:
      break
  }
}

export default Confirmation
