import React, { useRef, useState } from 'react'
import LinkRouter from 'components/_utility/LinkRouter'
import classnames from 'classnames'
import InlineSVG from 'components/_ui/InlineSVG'
import { MenuSectionType } from 'ts/DTO/menu'
import { CustomLabel } from 'components/_global/Header/MainMenu/style'

const noLinkStyle = { style: { pointerEvents: 'none' } }

/*******************************************************
 * TYPE
 *******************************************************/
type GroupPropsType = {
  tagColor?: string
  children?: MenuSectionType[]
  toggleOffcanvas: () => void
  url_str?: string
  name?: string
}

/**
 *
 * @param props
 * @constructor
 */
const Group = (props: GroupPropsType) => {
  const [active, setActive] = useState(false)
  const linksRef = useRef<HTMLDivElement>(null!)

  const onHeaderClick = () => {
    const linksNode = linksRef.current
    setActive(!active)
    if (linksNode.style.maxHeight) {
      linksNode.style.maxHeight = ''
    } else {
      linksNode.style.maxHeight = linksNode.scrollHeight + 'px'
    }
  }

  const groupLinks = !props.children ? (
    false
  ) : (
    <OffcanvasGroupLinks
      tagColor={props.tagColor}
      linksRef={linksRef}
      children={props.children}
      toggleOffcanvas={props.toggleOffcanvas}
    />
  )

  const title =
    !props.url_str || props.url_str === 'nolink' ? (
      <span className="offcanvas__group-title">{props.name}</span>
    ) : (
      <LinkRouter class="offcanvas__group-title" onClick={props.toggleOffcanvas} to={props.url_str}>
        {props.name}
      </LinkRouter>
    )

  const toggle = !groupLinks ? (
    false
  ) : (
    <span className="offcanvas__arrow" onClick={onHeaderClick}>
      <InlineSVG icon="caret-right" />
    </span>
  )

  return (
    <div
      className={classnames({
        offcanvas__group: true,
        'offcanvas__group--active': active
      })}
    >
      <h4>
        {title} {toggle}
      </h4>
      {groupLinks}
    </div>
  )
}

type OffcanvasGroupLinksPropsType = {
  tagColor?: GroupPropsType['tagColor']
  linksRef: React.RefObject<HTMLDivElement>
  children: MenuSectionType[]
  toggleOffcanvas: () => void
}

const OffcanvasGroupLinks = (props: OffcanvasGroupLinksPropsType) => {
  const links = !props.children
    ? false
    : props.children.map((link, idx) => {
        if (!link.url_str) {
          return null
        }

        const siblings = !link.children
          ? false
          : link.children.map((link, idx) => (
              <OffcanvasGroupLinksItem
                key={idx}
                toggleOffcanvas={props.toggleOffcanvas}
                {...link}
              />
            ))

        return (
          <ul key={idx}>
            <OffcanvasGroupLinksTitle
              toggleOffcanvas={props.toggleOffcanvas}
              tagColor={props.tagColor}
              {...link}
            />
            {siblings}
          </ul>
        )
      })

  return (
    <div className="offcanvas__group-links" ref={props.linksRef}>
      {links}
    </div>
  )
}

type OffcanvasGroupLinksTitlePropsType = {
  url_str: string
  name: string
  tagColor?: GroupPropsType['tagColor']
  custom_label?: string
  toggleOffcanvas: () => void
}

const OffcanvasGroupLinksTitle = (props: OffcanvasGroupLinksTitlePropsType) => {
  const style = props.url_str === 'nolink' ? noLinkStyle : null
  return (
    <li className="offcanvas__group-sub" {...(style as object)}>
      {props.custom_label && <CustomLabel color={props.tagColor}>{props.custom_label}</CustomLabel>}
      <LinkRouter to={props.url_str} onClick={props.toggleOffcanvas}>
        {props.name}
      </LinkRouter>
    </li>
  )
}

const OffcanvasGroupLinksItem = (props: OffcanvasGroupLinksTitlePropsType) => {
  const style = props.url_str === 'nolink' ? noLinkStyle : null

  return (
    <li className="offcanvas__group-link" {...(style as object)}>
      <LinkRouter to={props.url_str} onClick={props.toggleOffcanvas}>
        {props.name}
      </LinkRouter>
    </li>
  )
}

export default Group
