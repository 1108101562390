import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'typy'
import classnames from 'classnames'
import styled from 'styled-components'
import Slider from 'react-slick'
import { shadeHexColor } from 'utils'
import Tracker from 'utils/tracking'
import { Link } from 'react-router-dom'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'

import 'sass/vendor/slick.css'
import 'sass/vendor/slick-overrides.css'
import 'components/_paragraphs/RegionGrid/region-grid.css'

const StyledLink = styled(Link)`
  ${(props) =>
    props.styles.hoverColor &&
    `
    transition: color 0.3s ease;

    &:hover {
      color: ${props.styles.hoverColor};
    }
  `};
`

class RegionGrid extends Component {
  constructor(props) {
    super(props)

    this.switchToSlick = window.matchMedia('(max-width: 1179px)')

    this.state = {
      renderCarousel: this.switchToSlick.matches
    }

    this.onMediaQueryChange = this.onMediaQueryChange.bind(this)

    // Normal, healthy kids
    if (this.switchToSlick.addEventListener) {
      this.switchToSlick.addEventListener('change', this.onMediaQueryChange)
    }

    // Safari
    if (this.switchToSlick.addListener) {
      this.switchToSlick.addListener(this.onMediaQueryChange)
    }
  }

  onMediaQueryChange(e) {
    let renderCarousel = false

    if (e.matches) {
      renderCarousel = true
    }

    this.setState({
      renderCarousel: renderCarousel
    })
  }

  render() {
    const { props } = this
    // Render Slick or regular markup
    const { renderCarousel } = this.state

    let options = {
      rows: 1,
      slidesToShow: props.images.length,
      slidesToScroll: props.images.length
    }

    if (props.images.length > 4) {
      options = {
        rows: 2,
        slidesToShow: 4,
        slidesToScroll: 4
      }
    }

    const settings = {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 500,
      ...options,
      responsive: [
        {
          breakpoint: 1180,
          settings: {
            rows: 1,
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true
          }
        },
        {
          breakpoint: 900,
          settings: {
            rows: 1,
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            rows: 1,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '40px',
            variableWidth: true,
            dots: true
          }
        }
      ]
    }

    return (
      <ThemeConsumer>
        {(theme) => {
          const blocks = props.images.map((block, idx) => {
            if (t(block, 'content.cta.uri').safeObject) {
              // Add tracking if gtm_id is present
              const gtmId = block.content.cta.options.gtm_id

              const external = block.content.cta.uri.startsWith('http')
              const options = {
                key: idx,
                to: (!external && block.content.cta.uri) || null,
                href: (external && block.content.cta.uri) || null,
                target: external ? '_blank' : null,
                rel: external ? 'noreferrer noopener' : null,
                className: 'region-grid__col',
                onClick: () => Tracker(gtmId)(block.content.cta.title),
                title: block.content.cta.title,
                styles: {
                  hoverColor:
                    shadeHexColor(props.highlightColor, -0.2) ||
                    shadeHexColor(theme.highlightColor, -0.2)
                },
                dangerouslySetInnerHTML: { __html: t(block, 'content.image.markup').safeObject }
              }

              return external ? <StyledLink {...options} as="a" /> : <StyledLink {...options} />
            }

            return (
              <div
                key={idx}
                className="region-grid__col"
                dangerouslySetInnerHTML={{ __html: t(block, 'content.image.markup').safeObject }}
              />
            )
          })

          return (
            <div
              className={classnames({
                'region-grid': true,
                [`region-grid--${props.images.length}`]: true
              })}
              style={{ color: props.highlightColor || theme.highlightColor }}
            >
              <div className="wrapper">
                {renderCarousel && (
                  <Slider className="region-grid__grid" {...settings}>
                    {blocks}
                  </Slider>
                )}

                {!renderCarousel && (
                  <div className="region-grid__grid region-grid__grid--no-carousel">{blocks}</div>
                )}
              </div>
            </div>
          )
        }}
      </ThemeConsumer>
    )
  }
}

RegionGrid.propTypes = {
  images: PropTypes.array.isRequired
}

export default RegionGrid
