import React from 'react'
import { Strings } from 'components/_utility/Strings'

const date = new Date()
export type FixMeLater = any
export interface PropsType {
  lang: string
  logo: React.ReactFragment
}

const Bottom = (props: PropsType) => (
  <div className="footer__bot footer__row wrapper">
    <div>
      {props.logo}
      <span className="hide--mobile">
        © {date.getFullYear()} {Strings.footer.copyright[props.lang]}
      </span>
    </div>
    <div>
      {/* NOTE: Temporarily hide until links are extracted from footer */}
      {/* <a href='#'>Politiques de confidentialité</a>
      <a href='#'>Conditions d'utilisation</a> */}
      <span className="show--mobile">
        © {date.getFullYear()} {Strings.footer.copyright[props.lang]}
      </span>
    </div>
  </div>
)

export default Bottom
