import React from 'react'
import Helmet from 'react-helmet'

/*******************************************************
 * TYPE
 *******************************************************/
type PropsType = {
  family: string
}

/**
 * Global Site Font config is delivered by API so leave as is
 * @param family
 * @constructor
 */
const SiteFont = ({ family }: PropsType) => {
  if (!family) {
    return null
  }

  // Fallback font families to be used if font-family doesn't exist or doesn't load correctly
  const fallbacks = 'Helvetica, Arial, sans-serif'

  return (
    <Helmet>
      <link rel="stylesheet" href={`/fonts/${family}/font.css`} />
      <style type="text/css">{`
        body {
          font-family: '${family}', ${fallbacks};
        }
      `}</style>
    </Helmet>
  )
}

export default SiteFont
