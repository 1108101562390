import React from 'react'

// import Button from 'components/_ui/Button'
import Header from './Header'
import Jobs from './Jobs'
import Pagination from './Pagination'

const JobListing = () => (
  <section className="jobs__main">
    <Header />
    <Jobs />
    <Pagination />
  </section>
)

export default JobListing
