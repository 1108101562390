// @components
import React, { createRef, useState, useRef, useEffect, useContext } from 'react'
// @design
import classnames from 'classnames'
// @components
import LinkRouter from 'components/_utility/LinkRouter'
import { HeaderContext } from 'components/_global/Header/context'
import ContactUs, { ContactProps } from '../ContactUs'
import InlineSVG from 'components/_ui/InlineSVG'
import * as SC from './style'
// @types
import { ColorNamesOf, MenuColors, ActiveButtonColors } from 'ts/DTO/content.colorpalette'
import { MenuSectionType } from 'ts/DTO/menu'

const noLinkStyle = { style: { pointerEvents: 'none' } }

/*******************************************************
 * TYPES
 *******************************************************/
export type MainMenuColors = ColorNamesOf<MenuColors>
export type MainMenuButtonColors = ColorNamesOf<ActiveButtonColors>
export interface IProps {
  buttonColors: MainMenuButtonColors
  colors: MainMenuColors
  contact: ContactProps
  menu: MenuSectionType[]
}

type SubNavListType = {
  menu: React.ReactNode | null
  menuColumns: number
  menuSimple: boolean
}

/**
 *
 * @param data
 * @param childLessLink
 * @param forceMegaMenu
 * @param props
 * @constructor
 */
const SubNavList = (
  childLessLink: boolean,
  forceMegaMenu: boolean | undefined,
  data?: MenuSectionType[],
  props?: IProps
): SubNavListType => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [customLabelHeight, setCustomLabelHeight] = useState(0)
  // Refs for all the CustomLabel elements
  // so we can iterate over the elements and apply styles later on
  // TODO: Potentially improve this?
  const labelRefs: any = useRef(data && data.map(() => createRef()))

  /*******************************************************
   * LIFE CYCLE HOOKS
   *******************************************************/
  useEffect(() => {
    let tallest = customLabelHeight

    // Iterate over label refs to be able to calculate tallest element
    // and match all label heights
    const refs = labelRefs && labelRefs.current
    if (refs) {
      for (let i = 0; i < refs.length; i++) {
        const ref = refs[i]
        if (ref.current) {
          tallest = Math.max(ref.current.getBoundingClientRect().height, tallest)
        }
      }
    }

    if (tallest !== customLabelHeight) {
      setCustomLabelHeight(tallest)
    }
  }, [])

  if (data) {
    // Multiple columns but without nested children should "technically"
    // be a single column (simple) dropdown
    let isSingleColumn = true
    let columnCount = 0

    const menu = data.map((item, idx) => {
      columnCount = idx + 1

      if (item.children) {
        isSingleColumn = false
      }

      if (item.children || childLessLink) {
        return (
          <ul key={idx}>
            <SC.StyledLi
              noLinkStyle={item.url_str === 'nolink'}
              className={classnames({
                'header__dropdown-title': true
              })}
            >
              {/* custom link */}
              <SC.CustomLabel
                matchHeight={customLabelHeight}
                ref={labelRefs.current[idx]}
                color={props?.colors.dropdownFontColorTag}
              >
                {item.custom_label}
              </SC.CustomLabel>
              <LinkRouter to={item.url_str} name={item.name} target={item.target} />
            </SC.StyledLi>
            {item.children && SubNavList(false, forceMegaMenu, item?.children).menu}
          </ul>
        )
      } else {
        return (
          <SC.StyledLi key={idx} noLinkStyle={item.url_str === 'nolink'}>
            <SC.CustomLabel
              matchHeight={customLabelHeight}
              ref={labelRefs.current[idx]}
              color={props?.colors.dropdownFontColorTag}
            >
              {item.custom_label}
            </SC.CustomLabel>
            <LinkRouter to={item.url_str} name={item.name} target={item.target}>
              <InlineSVG icon="arrow-right" />
            </LinkRouter>
          </SC.StyledLi>
        )
      }
    })

    return {
      menu,
      menuSimple: forceMegaMenu ? false : isSingleColumn,
      menuColumns: forceMegaMenu ? columnCount : isSingleColumn ? 1 : columnCount
    }
  }

  return {
    menu: null,
    menuSimple: false,
    menuColumns: 0
  }
}

/**
 *
 * @param props
 * @constructor
 */
const MainMenu = (props: IProps) => {
  const header = useContext(HeaderContext)

  const navList = props.menu.map((item, idx) => {
    const style = item.url_str === 'nolink' ? noLinkStyle : null

    // @TODO REFACTORING  CHILDREN MIGHT BE UNDEFINED, SPLIT APART
    const { menu, menuSimple, menuColumns } = SubNavList(
      true,
      header.forceMegaMenu,
      item.children,
      props
    )

    return (
      <li
        key={idx}
        className={classnames({
          'header__dropdown-toggle': true,
          'header__dropdown-toggle--no-menu': !item.children,
          'header__dropdown--simple': menuSimple
        })}
      >
        {item.custom_label && <SC.CustomLabel>{item.custom_label}</SC.CustomLabel>}
        <LinkRouter to={item.url_str} name={item.name} target={item.target} {...style} />
        {item.children && (
          <SC.HeaderDropdown
            className="header__dropdown"
            full={!menuSimple}
            columns={menuColumns}
            separator={header.showTopSeparator}
          >
            {menu}
          </SC.HeaderDropdown>
        )}
      </li>
    )
  })

  return (
    <SC.Wrap className="header__main" align={header.mainMenuAlign}>
      <SC.Menu>{navList}</SC.Menu>
      <ContactUs {...props.contact} type="button" buttonColors={props.buttonColors} />
    </SC.Wrap>
  )
}

export default MainMenu
