import React from 'react'
import styled from 'styled-components'
import { Strings } from 'components/_utility/Strings'
import { getLang } from 'utils'
import './article-teaser.css'
import ArticleShareIcons from 'components/_sections/Article/ArticleShareIcons'
export type FixMeLater = any

export interface IProps {
  displayArticleTeaser: boolean
  articleAuthor: FixMeLater
  articleSubtitle: FixMeLater
  articleTeaser: string
  displayArticleImage: boolean
  articleImage?: string
  articleImageAlt: string
  theme: FixMeLater
  style: FixMeLater
  title: string
  shareIconsTop: boolean
  normalPadding: boolean
  email: {
    subject: string
    body: string
  }
  displayShare?: boolean
}

const Author = styled.p``

const Subtitle = styled.p`
  & + ${Author} {
    margin-top: -1em;
  }
`

const ArticleTeaser: React.FunctionComponent<IProps> = ({
  displayArticleTeaser,
  articleAuthor,
  articleSubtitle,
  articleTeaser,
  displayArticleImage,
  articleImage,
  articleImageAlt,
  theme,
  style,
  displayShare,
  title,
  shareIconsTop,
  normalPadding,
  email
}) => {
  const lang = getLang()

  // To see if teaser or image is present
  const teaserPresent =
    (displayArticleTeaser && (articleAuthor || articleSubtitle || articleTeaser)) ||
    (displayArticleImage && articleImage)
  let socialIconsClass = teaserPresent
    ? 'text-block--padded'
    : normalPadding
    ? ''
    : 'text-block--padded'
  // When there is no teaser add padding-top for social icons
  if (!displayArticleTeaser) {
    socialIconsClass += ' padding--top'
  }
  return (
    <div className="article-teaser" style={{ backgroundColor: style.backgroundColor }}>
      {(displayArticleTeaser || articleSubtitle || articleAuthor) && (
        <div className="text-block text-block--padded">
          <div className="wrapper wysiwyg">
            {displayArticleTeaser && (
              <p style={{ color: theme.paragraphColor, opacity: 0.7, fontSize: '1.2em' }}>
                {articleTeaser}
              </p>
            )}
            {articleSubtitle && (
              <Subtitle style={{ color: theme.highlightColor }}>{articleSubtitle}</Subtitle>
            )}
            {articleAuthor && (
              <Author style={{ color: theme.highlightColor }}>
                {Strings.text.article_by[lang]} {articleAuthor}
              </Author>
            )}
          </div>
        </div>
      )}
      {/*To display share icons above teaser image*/}
      {/* If teaserPresent add large padding else align with text blocks below */}
      {displayShare && shareIconsTop && (
        <div className={socialIconsClass}>
          <ArticleShareIcons title={title} email={email} />
        </div>
      )}
      {displayArticleImage && articleImage && (
        <div className="text-block text-block--padded article-teaser-img">
          <div className="wrapper">
            <img src={articleImage} alt={articleImageAlt} />
          </div>
        </div>
      )}
    </div>
  )
}

export default ArticleTeaser
