import React from 'react'
import HtmlParser from 'components/_paragraphs/Wysiwyg/HtmlParser'
import Button from 'components/_ui/Button'
import { ThemeConsumer } from 'components/_sections/Page/Section/ThemeContext'

import 'components/_paragraphs/Inspiration/inspiration.css'

type PropsType = {
  title: string
  subtitle: string
  cta: any
  buttonStyle: any
}

const Inspiration = ({ buttonStyle, title, subtitle, cta }: PropsType) => (
  <ThemeConsumer>
    {(theme) => {
      const button = !cta ? (
        <></>
      ) : (
        <Button
          href={cta.uri}
          options={cta.options}
          primaryColor={theme.headingsColor}
          secondaryColor={theme.highlightColor}
          buttonStyle={buttonStyle}
        >
          {cta.title}
        </Button>
      )

      return (
        <div className="wrapper inspiration" style={{ color: theme.headingsColor }}>
          <h2>{title}</h2>
          <HtmlParser string={subtitle} />
          {button}
        </div>
      )
    }}
  </ThemeConsumer>
)

export default Inspiration
